import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import SearchFilter from 'components/SearchFilter';
import { updateSearch } from 'store/organisations/organisationsListingSlice';

const OrganisationHeaderToolbar = () => {
  const { search } = useAppSelector((state: RootState) => state.organisationsListing);

  const onGetData = (value?: string) => {
    // @ts-ignore
    return '';
  };

  return (
    <SearchFilter
      search={search}
      onUpdateSearch={updateSearch}
      onGetData={onGetData}
    />
  );
};

export default OrganisationHeaderToolbar;
