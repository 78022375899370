import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { onForecastValueChange } from 'store/dashboard/forecasts2Slice';
import AssumedForecasts from '../AssumedForecasts';
import { BuildingAssumptions } from '../BuildingAssumptions';

export const ForecastsEditor: React.FC = () => {
  const dispatch = useDispatch();

  const onValueChange = useCallback((key: string, value: string | string[]) => {
    dispatch(onForecastValueChange({ key, value }));
  }, []);

  return (
    <div>
      <BuildingAssumptions onValueChange={onValueChange} />
      <AssumedForecasts />
    </div>
  );
};
export default ForecastsEditor;
