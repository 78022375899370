import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

const LIST_DEVELOPMENTS = () => {
  return {
    query: gql`
      query {
        listDevelopments {
          id
          entityId
          developmentName
          area
        }
      }
    `,
  };
};

export const listDevelopments = createAsyncThunk(
  'propertiesV2/listDevelopments',
  async (args: { first: number; last: number; after: string; before: string }, { getState }) => {
    const response = await client.query(LIST_DEVELOPMENTS());
    return response;
  },
);
