import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import { cancelContractEventEdit } from 'store/contractsV2/contractDetailSliceV2';
import { createContract2, createContractAndEvent, getContract2 } from 'common/api/contracts2';
import { createContractEvent } from 'common/api/contracts2/createContractEvent';
import { MODE_OPTIONS, ViewProps } from 'common/api/miscellaneous';
import { moveTo } from 'utils/utils-actions';

interface ContractToolbarProps extends ViewProps {
  mode: MODE_OPTIONS;
  setMode: (value: MODE_OPTIONS) => void;
}

const ContractToolbar = ({ mode, setMode, isCreated }: ContractToolbarProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activeContract } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  /**
   * On Update
   */
  const onUpdate = () => {
    return dispatch(createContractEvent(activeContract?.id || '')).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled' && activeContract?.id) {
        dispatch(getContract2({ id: activeContract.id }));
        return true;
      }
      return false;
    });
  };

  const onCreate = () => {
    return dispatch(createContractAndEvent()).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        const id = response.payload.data.createContractAndEvent.id;
        navigate(`/tenancy/${id}/details`);

        return true;
      }

      return false;
    });
  };

  /**
   * On Save
   */
  const onSave = () => {
    if (isCreated) {
      return onUpdate();
    } else {
      return onCreate();
    }
  };

  /**
   * On Cancel button Click
   */
  const onCancel = () => {
    if (isCreated) {
      dispatch(cancelContractEventEdit());
    } else {
      navigate('/tenancy/dashboard');
    }
  };

  return (
    <DetailsControlButtons
      mode={mode}
      setMode={setMode}
      checkDisabled={false}
      onCancel={onCancel}
      onSave={onSave}
    />
  );
};

export default ContractToolbar;
