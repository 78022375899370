import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import { ViewProps } from 'common/api/miscellaneous';
import { listParameterGroups, listParameterSubGroups } from 'common/api/parameters';
import { getParameterTable } from 'common/api/parameters';
import TableOfParametersTabs from './TableOfParametersTabs';

interface CreatorViewerProps extends ViewProps {}

const TableOfParameterCreatorViewer = ({ isCreated }: CreatorViewerProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const { paramViewSelectedGroup, activeTableParameter, isLoading } = useAppSelector(
    (state: RootState) => state.parameterDetail,
  );

  useEffect(() => {
    if (isCreated) {
      // @ts-ignore
      dispatch(getParameterTable({ id }));
    }

    // @ts-ignore
    dispatch(listParameterGroups());
    // @ts-ignore
    dispatch(listParameterSubGroups());
  }, [id]);

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Table of Parameters',
        url: '/parameters/dashboard',
      },
    ];

    if (paramViewSelectedGroup !== null) {
      breadCrumb.push({
        title: paramViewSelectedGroup.name,
        url: `/parameters/dashboard?group=${paramViewSelectedGroup.id}`,
      });
    }

    breadCrumb.push({
      title: activeTableParameter.name,
    });
    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <TableOfParametersTabs />
    </PageLayout>
  );
};

export default TableOfParameterCreatorViewer;
