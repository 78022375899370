import { ForecastedTenancies } from 'common/_classes/forecastsV2/ForecastedTenancies';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import Floor from 'common/model/Floor';
import { generateAssumedTenanciesThunk } from 'common/api/dashboard/forecasting/generateAssumedTenancies';
import { listPropertyFloors } from 'common/api/floor';

const AssumedForecasts = () => {
  const { activeForecast, forecastedTenancies } = useAppSelector((state: RootState) => state.forecasts2);
  const { activeProperty } = useAppSelector((state: RootState) => state.stackingPlan);
  const { floorsList, isLoading } = useAppSelector((state: RootState) => state.propertyDetail) as {
    floorsList: Floor[];
    isLoading: boolean;
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listPropertyFloors({ first: 100000, propertyId: activeProperty?.id ?? '' }));
  }, []);

  useEffect(() => {
    dispatch(
      generateAssumedTenanciesThunk({
        buildingId: activeProperty?.id ?? '',
        forecast: activeForecast,
        floorsList: floorsList,
      }),
    );
  }, [floorsList]);

  console.log('activeForecast', activeForecast);
  return (
    <div>
      <h3>Assumed Forecasts</h3>
      {/* {forecastedTenancies.map((tenancy: ForecastedTenancies) => (
        <div key={tenancy.id}>
          {tenancy.id}-{tenancy.startDate}-{tenancy.endDate}
        </div>
        ))} */}
      <table>
        <thead>
          <tr>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Type</th>
            <th>Floor ID</th>
            <th>Unit ID</th>
            <th>Tenancy ID</th>
            <th>Tenant Name</th>
            <th>Transaction ID</th>
            <th>Tenant ID</th>
            <th>Unit Name</th>
            <th>Floor ID</th>
            <th>Forecast ID</th>
            <th>Whole Floor</th>
          </tr>
        </thead>
        <tbody>
          {forecastedTenancies.map((tenancy: ForecastedTenancies) => (
            <tr key={tenancy.id}>
              <td>{tenancy.startDate}</td>
              <td>{tenancy.endDate}</td>
              <td>{tenancy.type}</td>
              <td>{tenancy.floorId}</td>
              <td>{tenancy.unitId}</td>
              <td>{tenancy.tenancyId}</td>
              <td>{tenancy.tenantName}</td>
              <td>{tenancy.transactionId}</td>
              <td>{tenancy.tenantId}</td>
              <td>{tenancy.unitName}</td>
              <td>{tenancy.floorId}</td>
              <td>{tenancy.forecastId}</td>
              <td>{tenancy.wholeFloor ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AssumedForecasts;
