import { GuidelinesOnly, ParameterListingClone, ParameterTableColumnClone } from 'common/_classes';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import {
  StatusBadgeArgument,
  filterBooleanElementBody,
  filterElementNumberRange,
  filterElementStatus,
  filterElementStatusTemplate,
  filterElementType,
  filterElementTypeTemplate,
  filterElementYesOrNo,
} from 'atoms/TableReactPrime/columnTemplates';
import Tooltip from 'atoms/Tooltip';
import ParameterTypes from 'common/model/ParameterTypes';
import { checkNotEmpty } from 'utils/tsValidator';
import { convertAnswerType } from 'utils/utils-answer';
import { Icons } from 'utils/utils-icons';
import { ParametersRowActionButtons } from './ParametersRowActionButtons';

const statuses: StatusBadgeArgument[] = [{ value: 'DRAFT', color: BadgeColor.ORANGE }];

export class ParametersListRow {
  id: string;
  name: string | null;
  question: string;
  type: string;
  answerType?: string;
  parameterGroupCategoryName: string | undefined;
  parameterGroupName: string | undefined;
  index: number;
  internalOriginFlag: boolean;
  version: number;
  guideline: boolean;
  status: string;
  linkedToInventory: string;
  provisionExistence: boolean;
  mandatoryChoice: boolean;
  partOfTable: boolean;
  parameterTableColumns: ParameterTableColumnClone[];

  constructor(parameter: ParameterListingClone) {
    this.id = parameter.id;
    this.name = parameter.name;
    this.question = parameter.question;
    this.type = parameter.type.toString();
    this.mandatoryChoice = parameter.mandatoryChoice;
    this.answerType = convertAnswerType(parameter.answerType);
    this.parameterGroupName = parameter.parameterGroup?.name;
    this.parameterGroupCategoryName = parameter.parameterGroup?.category?.name;
    this.index = Number(parameter.index);
    this.internalOriginFlag = parameter.internalOriginFlag;
    // TODO: following are not yet implemented
    this.version = 1;
    this.guideline = GuidelinesOnly.checkFilled(parameter.guideline);
    this.status = statuses[0].value;
    this.linkedToInventory = getInventoryColor(parameter.hasInventory, parameter.inventoryFunction);
    this.provisionExistence = parameter.provisionExistence;
    this.partOfTable = parameter.parameterTableColumns.length !== 0;
    this.parameterTableColumns = parameter.parameterTableColumns;
  }
}

export enum EnumInventoryOptions {
  INVENTORY = 'INVENTORY',
  UNDEFINED_INVENTORY = 'UNDEFINED_INVENTORY',
  NO_INVENTORY = 'NO_INVENTORY',
}
const inventoryOptions = [
  EnumInventoryOptions.INVENTORY,
  EnumInventoryOptions.UNDEFINED_INVENTORY,
  EnumInventoryOptions.NO_INVENTORY,
];

const getInventoryColor = (hasInventory: boolean, inventoryFunction: string | null): EnumInventoryOptions => {
  if (hasInventory) {
    if (checkNotEmpty(inventoryFunction)) {
      return EnumInventoryOptions.INVENTORY;
    }
    return EnumInventoryOptions.UNDEFINED_INVENTORY;
  }
  return EnumInventoryOptions.NO_INVENTORY;
};

const filterElementTablePart = (row: ParametersListRow): JSX.Element => {
  const navigate = useNavigate();

  const parameterTableDetailsUrl = (id: string | null): string => {
    return `/parameters/table/${id}/details`;
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>, id: string | null): void => {
    e.preventDefault();
    navigate(parameterTableDetailsUrl(id));
  };

  let content: JSX.Element = (
    <div>
      <table>
        <thead>
          <tr>
            <th>List of tables using this parameter</th>
          </tr>
        </thead>
        <tbody>
          {row.parameterTableColumns.map(({ table }, index) => (
            <tr key={index}>
              <td>
                <a
                  href={parameterTableDetailsUrl(table.id)}
                  onClick={e => handleClick(e, table.id)}
                >
                  {table.name}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return row.partOfTable ? (
    <Tooltip
      trigger={
        <Icon
          className="param-icon color-green"
          icon={Icons.TickCircleSolid}
        />
      }
      HTMLContent={content}
    />
  ) : (
    <Icon
      className="param-icon color-orange"
      icon={Icons.MinusCircleFill}
    />
  );
};

const filterElementInventoryTemplate = (linkedToInventory: string) => {
  switch (linkedToInventory) {
    case EnumInventoryOptions.INVENTORY:
      return (
        <Icon
          className="param-icon color-green"
          icon={Icons.TickCircleSolid}
        />
      );
    case EnumInventoryOptions.UNDEFINED_INVENTORY:
      return (
        <Icon
          className="param-icon color-red-soft"
          icon={Icons.TickCircleSolid}
        />
      );
    case EnumInventoryOptions.NO_INVENTORY:
      return (
        <Icon
          className="param-icon color-gray"
          icon={Icons.MinusCircleFill}
        />
      );
  }
};

const filterElementInventory = (
  options: ColumnFilterElementTemplateOptions,
  inventoryOptions: EnumInventoryOptions[],
) => {
  return (
    <MultiSelect
      value={options.value}
      options={inventoryOptions}
      itemTemplate={filterElementInventoryTemplate}
      onChange={(e: MultiSelectChangeEvent) => options.filterCallback(e.value)}
      placeholder="Any"
      className="p-column-filter"
    />
  );
};

export const parametersColumnConfig = (onViewDetails: ViewDetailsProps): CustomColumnProps[] => {
  return [
    {
      field: 'name',
      header: 'Name',
      filter: true,
      sortable: true,
      filterPlaceholder: 'Search by Name',
      filterField: 'name',
      dataType: 'text',
    },
    {
      field: 'question',
      header: 'Question',
      filter: true,
      sortable: true,
      filterPlaceholder: 'Search by Questions',
      filterField: 'question',
    },
    {
      field: 'answerType',
      header: 'Answer type',
      filterPlaceholder: 'Search by Answer type',
      filter: true,
      sortable: true,
      filterField: 'answerType',
      body: (row: ParametersListRow) => {
        const { mandatoryChoice, answerType } = row;
        return (
          <>
            {mandatoryChoice ? (
              <Tooltip
                trigger={
                  <div>
                    {answerType} <span className="required-indicator-title">* </span>{' '}
                  </div>
                }
                HTMLContent={<span>Mandatory option is selected</span>}
              />
            ) : (
              answerType
            )}
          </>
        );
      },
    },
    {
      field: 'parameterGroupCategoryName',
      header: 'Category',
      filterPlaceholder: 'Search by Category',
      filter: true,
      filterField: 'parameterGroupCategoryName',
      sortable: true,
    },
    {
      field: 'parameterGroupName',
      header: 'Group',
      filter: true,
      filterPlaceholder: 'Search by Group',
      filterField: 'parameterGroupName',
      sortable: true,
    },
    {
      field: 'index',
      header: 'Group index',
      filterPlaceholder: 'Search by Group index',
      filter: true,
      sortable: true,
      filterField: 'index',
      dataType: 'numeric',
    },
    {
      field: 'type',
      header: 'Type',
      filter: true,
      sortable: true,
      body: (row: ParametersListRow) => filterElementTypeTemplate(row.type),
      filterElement: options =>
        filterElementType(options, [ParameterTypes.Commercial, ParameterTypes.DetailedAmendment]),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      field: 'partOfTable',
      header: 'Table flag',
      filterPlaceholder: 'Search by Table flag',
      filter: true,
      filterField: 'partOfTable',
      dataType: 'boolean',
      filterElement: (options: ColumnFilterElementTemplateOptions) => filterElementYesOrNo(options, 'Table flag'),
      filterMatchMode: 'equals',
      showFilterMatchModes: false,
      showFilterMenuOptions: false,
      body: (row: ParametersListRow) => filterElementTablePart(row),
    },
    {
      field: 'linkedToInventory',
      header: 'Linked to inventory',
      filterPlaceholder: 'Search by Linked to inventory',
      filter: true,
      filterField: 'linkedToInventory',
      sortable: true,
      body: (row: ParametersListRow) => filterElementInventoryTemplate(row.linkedToInventory),
      filterElement: options => filterElementInventory(options, inventoryOptions),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      field: 'provisionExistence',
      header: 'Connected to a provision',
      filterPlaceholder: 'Search by connected to a provision',
      filter: true,
      filterField: 'provisionExistence',
      sortable: true,
      dataType: 'boolean',
      showFilterMenuOptions: false,
      filterElement: options => filterElementYesOrNo(options, 'Connected to a provision'),
      filterMatchMode: 'equals',
      body: (row: ParametersListRow) => filterBooleanElementBody(row.provisionExistence),
    },
    {
      field: 'version',
      header: 'Version',
      filterPlaceholder: 'Search by Version',
      filter: true,
      sortable: true,
      showFilterMatchModes: false,
      filterField: 'version',
      dataType: 'numeric',
      filterMatchMode: 'between' as any,
      filterElement: filterElementNumberRange,
      body: (row: ParametersListRow) => row.version.toFixed(1),
    },
    {
      field: 'guideline',
      header: 'Guidance flag',
      filterPlaceholder: 'Search by Guidance flag',
      filter: true,
      filterField: 'guideline',
      dataType: 'boolean',
      filterElement: (options: ColumnFilterElementTemplateOptions) => filterElementYesOrNo(options, 'Guidance flag'),
      filterMatchMode: 'equals',
      showFilterMatchModes: false,
      showFilterMenuOptions: false,
      body: (row: ParametersListRow) => filterBooleanElementBody(row.guideline),
    },
    {
      field: 'status',
      header: 'Status',
      filter: true,
      sortable: true,
      body: (row: ParametersListRow) => filterElementStatusTemplate(row.status, statuses),
      filterElement: options => filterElementStatus(options, statuses),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
    {
      mandatory: true,
      field: 'options',
      header: 'Actions',
      body: (row: ParametersListRow) => (
        <ParametersRowActionButtons
          row={row}
          onViewDetails={onViewDetails}
        />
      ),
      frozen: true,
      alignFrozen: 'right',
    },
  ];
};
