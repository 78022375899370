import { Dispatch } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Popup } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { orderBy } from 'lodash';
import Badge, { BadgeColor } from 'atoms/Badge';
import { CustomColumnProps } from 'atoms/TableReactPrime';
import { StatusBadgeArgument, filterElementStatus } from 'atoms/TableReactPrime/columnTemplates';
import { PREVIEW_INFORMATION_TABS_OFFSET } from 'components/PreviewTab/PreviewEditorSideMenu';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import DiscussionGroup from 'common/model/DiscussionGroup';
import DiscussionGroupType from 'common/model/DiscussionGroupType';
import TransactionApprovalAnswer from 'common/model/TransactionApprovalAnswer';
import TransactionChannel from 'common/model/TransactionChannel';
import TransactionMessage from 'common/model/TransactionMessage';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { getActiveDocument } from 'utils/tsHelper';

// Definition of the badge's color for the discussionGroup status
export const statuses: StatusBadgeArgument[] = [{ value: 'OPEN', color: BadgeColor.ORANGE }];

export const CommentsPopUp = (messages: TransactionMessage[], channelId: string, index: number) => {
  if (messages && messages.length !== 0) {
    messages = orderBy(messages, ['updatedAt'], ['asc']);
    const messageTextFull = messages[messages.length - 1]?.message;
    const messageTextSmall =
      messageTextFull.length > 20
        ? messageTextFull.slice(0, messageTextFull.lastIndexOf(' ', 70)) + '...'
        : messageTextFull;
    return (
      <Popup
        hoverable={true}
        wide="very"
        key={`channel-${channelId}-${index}`}
        trigger={<div dangerouslySetInnerHTML={{ __html: messageTextSmall }} />}
        content={<div dangerouslySetInnerHTML={{ __html: messageTextFull }} />}
        className="custom-popup"
      />
    );
  } else {
    return '';
  }
};

const getDocumentName = (clauseNumber: string) => {
  const index = clauseNumber.indexOf('clause');
  return index !== -1 ? clauseNumber.substring(0, index).trimEnd() : clauseNumber;
};

const findAndMoveToClause = (match: RegExpMatchArray | null, activeDocument: HTMLElement | undefined) => {
  if (match) {
    let number = match[1].trim().replace(/\.$/, '');
    const matchChilds = number.match(/^(\d+\.\d+)\s+(\([a-zA-Z]\))(\s+(\([a-zA-Z]\)))?$/);
    if (matchChilds) {
      number = matchChilds[1];
    }
    let firstChild = null;
    let secondChild = null;
    if (matchChilds) {
      firstChild = matchChilds[2].replace(/^\s+|\s+$/g, '');
      if (matchChilds[3]) {
        secondChild = matchChilds[3].replace(/^\s+|\s+$/g, '');
      }
    }

    const clauseIndexes = activeDocument?.querySelectorAll(
      '[data-node-type="clause-index"], [data-node-type="clause-index-2"]',
    ) as NodeListOf<HTMLElement>;

    for (let i = 0; i < clauseIndexes?.length; i++) {
      if (clauseIndexes[i].innerText.replace(/\.$/, '') == number) {
        if (firstChild) {
          number = firstChild;
          firstChild = null;
        } else if (secondChild) {
          number = secondChild;
          secondChild = null;
        } else {
          clauseIndexes[i].scrollIntoView({ behavior: 'smooth', block: 'start' });
          break;
        }
      }
    }
  } else {
    // To add the toast container when we have time
    toast.warning('Clause not present inside the document');
  }
};

const goToClauseElement = (clauseNumber: string, notActiveDoc: boolean, dispatch: Dispatch<any>) => {
  const activeDocument: HTMLElement | undefined = getActiveDocument()?.documentElement;

  const match = clauseNumber.match(/clause\s+(.+)$/);

  if (notActiveDoc) {
    dispatch(updateSidebarTab(PREVIEW_INFORMATION_TABS_OFFSET.DISCUSSION));
    setTimeout(() => {
      findAndMoveToClause(match, activeDocument);
    }, 600);
  } else {
    findAndMoveToClause(match, activeDocument);
  }
};

const goToClause = (clauseNumber: string, dispatch: Dispatch<any>) => {
  const documentName = getDocumentName(clauseNumber);
  const element = document.getElementById(`${documentName}-doc-tab`);
  const notActiveDoc = !element?.classList.contains('is-active');
  if (element && notActiveDoc) {
    element.click();
  }

  if (notActiveDoc) {
    setTimeout(() => {
      goToClauseElement(clauseNumber, notActiveDoc, dispatch);
    }, 500);
  } else {
    goToClauseElement(clauseNumber, notActiveDoc, dispatch);
  }
};

export const ClausesPop = (clauseNumber: string, channelId: string, index: number) => {
  const dispatch = useAppDispatch();

  if (clauseNumber === null) {
    return '';
  }
  if (clauseNumber.length >= 35) {
    const messageTextFull = clauseNumber;
    const messageTextSmall =
      messageTextFull.length > 35
        ? messageTextFull.slice(0, messageTextFull.lastIndexOf(' ', 70)) + '...'
        : messageTextFull;
    return (
      <Popup
        hoverable={true}
        wide="very"
        key={`clause-number-${channelId}-${index}`}
        trigger={<p>{messageTextSmall}</p>}
        content={messageTextFull}
        className="custom-popup"
      />
    );
  } else {
    return <p onClick={() => goToClause(clauseNumber, dispatch)}>{clauseNumber}</p>;
  }
};

// export const parameterChanges = (messageAnswer: TransactionApprovalAnswer | undefined) => {
//   const messageTextFull = messageAnswer?.transactionMessage?.message || '';
//   const messageTextSmall =
//     messageTextFull.length > 35
//       ? messageTextFull.slice(0, messageTextFull.lastIndexOf(' ', 70)) + '...'
//       : messageTextFull;

//   return <div dangerouslySetInnerHTML={{ __html: messageTextSmall }} />;
// };

export const parameterChangesStatus = (messageAnswer: TransactionApprovalAnswer | undefined) => {
  if (messageAnswer?.approvalState === ValidApprovalStates.Pending) {
    return <Badge color={BadgeColor.ORANGE}>{ValidApprovalStates.Pending}</Badge>;
  } else if (messageAnswer?.approvalState === ValidApprovalStates.Approved) {
    return <Badge color={BadgeColor.GREEN}>{ValidApprovalStates.Approved}</Badge>;
  } else if (messageAnswer?.approvalState === ValidApprovalStates.Rejected) {
    return <Badge color={BadgeColor.RED}>{ValidApprovalStates.Rejected} </Badge>;
  }
};

export class DiscussionGroupClone {
  id: string;
  transactionId: string;
  documentTypeId: string;
  provisionName: string;
  type: string;
  index: number;
  channels: TransactionChannel[];

  constructor(discussionGroup: DiscussionGroup) {
    this.id = discussionGroup.id;
    this.transactionId = discussionGroup.transaction.id;
    this.documentTypeId = discussionGroup.documentType.id;
    this.provisionName = discussionGroup.provisionName;
    this.type = discussionGroup.type;
    this.index = discussionGroup.index;
    this.channels = discussionGroup.transactionChannels;
  }
}

/*
 * Definition of the column (names, format...)
 */
// TODO
// Filter function to be fixed
export const discussionColumnConfig = (
  transactionApprovalAnswers: TransactionApprovalAnswer[] | undefined,
  openParameterChanges: (messageAnswer: TransactionApprovalAnswer | undefined, channel: TransactionChannel) => void,
): CustomColumnProps[] => {
  return [
    {
      field: 'subjectMatter',
      header: 'Subject Matter',
      filterPlaceholder: 'Search by Subject Matter',
      filter: true,
      filterField: 'subjectMatter',
      sortable: true,
      body: (row: DiscussionGroupClone) => {
        return row.type === DiscussionGroupType.Provision ? row.provisionName : DiscussionGroupType.General;
      },
    },
    {
      field: 'discussions',
      header: 'Discussions',
      filterPlaceholder: 'Search by Discussions',
      filter: true,
      filterField: 'discussions',
      sortable: true,
      body: (row: DiscussionGroupClone) => {
        return row.channels.slice(0, 3).map((channel: TransactionChannel) => <p>{channel.title}</p>);
      },
    },
    {
      field: 'clauses',
      header: 'Clauses',
      filterPlaceholder: 'Search by Clauses',
      filter: true,
      filterField: 'clauses',
      sortable: true,
      body: (row: DiscussionGroupClone) => {
        return row.channels
          .slice(0, 3)
          .map((channel: TransactionChannel, index: number) => ClausesPop(channel.clauseNumber, channel.id, index));
      },
    },
    {
      field: 'tenantComments',
      header: 'Tenant Comments/Edits',
      filterPlaceholder: 'Search by Tenant Comments',
      filter: true,
      filterField: 'tenantComments',
      sortable: true,
      body: (row: DiscussionGroupClone) => {
        return row.channels
          .slice(0, 3)
          .map((channel: TransactionChannel, index: number) => CommentsPopUp(channel.messages, channel.id, index));
      },
    },
    // TO BE UPDATED
    {
      field: 'landlordResponse',
      header: 'Landlord Response',
      filterPlaceholder: 'Search by Landlord Response',
      filter: true,
      filterField: 'landlordResponse',
      sortable: true,
      body: (row: DiscussionGroupClone) => {
        return row.channels.map((channel: TransactionChannel) => {
          const messageAnswer = transactionApprovalAnswers?.find(
            answer => answer.transactionMessage?.transactionChannel?.id === channel.id,
          );
          return <p>{messageAnswer?.transactionDiscussionApproval?.draftMessage}</p>;
        });
      },
    },
    {
      field: 'detailsApprovals',
      header: 'Details / Approvals',
      filterPlaceholder: 'Search by Details / Approvals',
      filter: true,
      filterField: 'detailsApprovals',
      sortable: true,
      body: (row: DiscussionGroupClone) => {
        return row.channels.slice(0, 3).map((channel: TransactionChannel) => {
          const messageAnswer = transactionApprovalAnswers?.find(
            answer => answer.transactionMessage?.transactionChannel?.id === channel.id,
          );
          return (
            <div>
              {messageAnswer ? (
                <>
                  <a onClick={() => openParameterChanges(messageAnswer, channel)}>View Details</a>
                  {parameterChangesStatus(messageAnswer)}
                </>
              ) : (
                <a onClick={() => openParameterChanges(messageAnswer, channel)}>View Details</a>
              )}
            </div>
          );
        });
      },
    },
    {
      field: 'subjectStatus',
      header: 'Status',
      filterPlaceholder: 'Search by Status',
      filter: true,
      filterField: 'status',
      sortable: true,
      body: (row: DiscussionGroupClone) => '',
      filterElement: options => filterElementStatus(options, statuses),
      filterMatchMode: 'in',
      showFilterMenuOptions: false,
    },
  ];
};
