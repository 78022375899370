import moment from 'moment';
import React, { useCallback } from 'react';
import { Form } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import DateField from 'atoms/FormField/DateInput';
import InputField from 'atoms/FormField/Input';
import TextAreaField from 'atoms/FormField/TextArea';
import { FORECAST_MODE, IActiveForecast } from 'store/dashboard/forecasts2Slice';

interface IBuildingAssumptionsProps {
  onValueChange: (key: string, value: string | string[]) => void;
}

export const BuildingAssumptions: React.FC<IBuildingAssumptionsProps> = ({ onValueChange }) => {
  const { activeForecast, forecastMode } = useAppSelector((state: RootState) => state.forecasts2);
  // const onForecastLengthChange = useCallback(
  //   (value: string) => {
  //     const years = parseInt(value, 10);
  //     if (!isNaN(years) && activeForecast?.startDate) {
  //       const endDate = moment(activeForecast?.startDate).add(years, 'years').format('YYYY-MM-DD');
  //       onValueChange('endDate', endDate);
  //       onValueChange('duration', value);
  //     }
  //   },
  //   [activeForecast?.startDate, onValueChange],
  // );

  const onStartDateChange = useCallback(
    (key: string, value: string) => {
      const endDate = moment(value).add(10, 'years').format('YYYY-MM-DD');
      onValueChange('startDate', value);
      onValueChange('endDate', endDate);
    },
    [onValueChange],
  );

  const renderInputField = useCallback(
    (
      label: string,
      fieldKey: string,
      placeholder: string,
      unit?: string,
      dataTest?: string,
      disabled: boolean = forecastMode !== FORECAST_MODE.CREATE,
    ) => (
      <div className="col-4">
        <InputField
          required={true}
          label={label}
          placeholder={placeholder}
          dataTest={dataTest || fieldKey}
          unit={unit}
          value={String(activeForecast?.[fieldKey as keyof IActiveForecast] ?? '')}
          onChange={(key, value) => onValueChange(key, value)}
          fieldKey={fieldKey}
          disabled={disabled}
        />
      </div>
    ),
    [activeForecast, forecastMode, onValueChange],
  );

  return (
    <div>
      <Form>
        <div className="grid m-t-lg">
          <div className="col-6">
            <div className="grid">
              <div className="col-4">
                <DateField
                  label="Term start date"
                  fieldKey="startDate"
                  dataTest="term-start-date-input"
                  value={activeForecast?.startDate ?? ''}
                  disabled={forecastMode !== FORECAST_MODE.CREATE}
                  required={true}
                  onChange={onStartDateChange}
                />
              </div>
              {renderInputField('Forecast Length', 'duration', 'Type here', 'years', undefined, true)}
              {renderInputField('Forecast Reference', 'referenceId', 'Q1 2024/2025')}
              {renderInputField('Market Rental (psf pcm)', 'marketRent', 'Type here', '(HK$/sf/mo)')}
              {renderInputField('Forecated YoY Rent Growth', 'yoyRentGrowth', 'Type here', '(%)')}
              {renderInputField('Void Months', 'voidMonths', 'Type here', 'months')}
              {renderInputField('Renewal Rate', 'renewalRate', 'Type here', '(%)')}
              {renderInputField('Effective Downtime', 'effectiveDowntime', '', 'months', undefined, true)}
              {renderInputField('Term Length for new lease', 'newLeaseTerm', 'Type here', 'months')}
              {renderInputField('Rent Free for new lease', 'newLeaseRentFree', 'Type here')}
            </div>
          </div>
          <div className="col-6">
            <TextAreaField
              label="Comments"
              fieldKey="comments"
              dataTest="comments-input"
              value={activeForecast?.comments ?? ''}
              onChange={onValueChange}
              required={true}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default React.memo(BuildingAssumptions);
