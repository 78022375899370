import { useEventHandler } from '@ericlathrop/phoenix-js-react-hooks';
import { Dispatch } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import { BadgeColor, BadgeProps } from 'atoms/Badge';
import { onSaveAnswers } from 'components/CardParameters/CategoriesTabs';
import { TabType, scrollDownChat } from 'components/PreviewTab/Discussion';
import { setSelectedProvisionFilter } from 'store/miscellaneous/miscellaneousSlice';
import { updateProvisionModalState } from 'store/provisions/provisionsListingSlice';
import { updateTransactionViewDetailsTab } from 'store/transactions/transactionDetailSlice';
import ParameterTable from 'common/model/ParameterTable';
import ThreadType from 'common/model/ThreadType';
import TransactionHistoryGeneralStatus from 'common/model/TransactionHistoryGeneralStatus';
import { listDocumentTypes } from 'common/api/miscellaneous';
import { ViewProps } from 'common/api/miscellaneous';
import { listProvisionsAndCategories } from 'common/api/provisions';
import {
  getLandlordTransaction,
  listAmendments,
  listLandlordAndTenantEntities,
  listPolicies,
  listPremises,
  listTransactionChannels,
  listTransactionMessages,
} from 'common/api/transactions';
import { getTransactionHistoryDocuments } from 'common/api/transactions/getTranstionHistoryDocuments';
import { WebSocketNotification } from 'utils/types/websocket';
import { useChannel } from 'utils/useChannel';
import { checkIfAdminURL } from 'utils/utils-admin';
import { TRANSACTION_CONTRACT_VIEW_TABS_OFFSET } from './Tabs/ContractView';
import TransactionsTabs, { TRANSACTION_TABS_OFFSET } from './Tabs/TransactionsTabs';
import './CreatorViewer.scss';

export const runTransactionDetailsPageActions = (
  dispatch: Dispatch<any>,
  transactionId?: string,
  ddisCreated?: boolean,
  activeTabIndex?: number,
): void => {
  dispatch(updateProvisionModalState({ open: false }));

  if (ddisCreated) {
    // @ts-ignore
    dispatch(getLandlordTransaction({ id: transactionId })).then(response => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(listPremises({ ownerId: null }));
        dispatch(setSelectedProvisionFilter({ provisionId: null }));
        if (![TRANSACTION_TABS_OFFSET.PRE_COMPLETION, TRANSACTION_TABS_OFFSET.PROVISIONS].includes(activeTabIndex!)) {
          dispatch(updateTransactionViewDetailsTab({ tab: TRANSACTION_TABS_OFFSET.SETUP }));
        }
        dispatch(listAmendments());
      }
    });
  }

  dispatch(listDocumentTypes({ first: 100 }));
  dispatch(listProvisionsAndCategories({ first: 300 }));
  dispatch(listLandlordAndTenantEntities());
  dispatch(listPolicies({ first: 100 }));
};

export interface TransactionChannelWebsocket {
  amendment_id: string | null;
  document_type_id: string | null;
  id: string | null;
  inserted_at: Date;
  node_id: string | null;
  status: string;
  title: string | null;
  transaction_id: string | null;
  type: string;
  updated_at: Date;
}

interface TransactionMessageWebsocket {
  amendment_deleted_flag: boolean;
  amendment_flag: boolean;
  amendment_id: string | null;
  flag: boolean;
  id: string | null;
  inserted_at: Date;
  message: string | null;
  thread: string;
  transaction_channel_id: string | null;
  updated_at: Date;
  user_id: string | null;
}

const TransactionCreatorViewer = ({ isCreated }: ViewProps): JSX.Element => {
  const [channelId, setChannelId] = useState<string | null>(null);
  const { id: transactionId } = useParams();
  const dispatch = useAppDispatch();
  const {
    activeTransaction,
    isLoading: transactionsLoading,
    activeTabIndex,
    activeContractViewTabOffset,
    activeTransactionChannel,
    activeTransactionAnswers,
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const {
    isLoading: miscellaneousLoading,
    conditional,
    parameterMode,
  } = useAppSelector((state: RootState) => state.miscellaneous);
  const { isLoading: contractViewTabLoading } = useAppSelector((state: RootState) => state.contractDetailV2);
  const { id: userId } = useAppSelector((state: RootState) => state.auth);

  const { activeGroupId, parameterTablesCollection } = useAppSelector((state: RootState) => state.parametersTab);

  const tablesByGroup = parameterTablesCollection.filter(
    (obj: ParameterTable) => obj.parameterGroup?.id === activeGroupId,
  );

  const { activeTab: activePreviewTab } = useAppSelector((state: RootState) => state.hiddenMenu);

  useEffect(() => {
    runTransactionDetailsPageActions(dispatch, transactionId, isCreated, activeTabIndex);
  }, [transactionId]);

  const isAborted = (): boolean => {
    const historyGeneral = activeTransaction?.historyGeneral;

    if (historyGeneral && historyGeneral.length > 0) {
      return historyGeneral[historyGeneral?.length - 1].status === TransactionHistoryGeneralStatus.Aborted;
    }

    return false;
  };

  const breadCrumb: BreadCrumbItem[] = [
    {
      title: 'Transactions',
      url: '/transactions/dashboard',
    },
    {
      title: activeTransaction.identifier || '',
    },
  ];

  const badgeProps: BadgeProps = {
    badgeColor: BadgeColor.RED,
    children: TransactionHistoryGeneralStatus.Aborted,
  };

  const isPreviewTab: boolean = activeTabIndex === TRANSACTION_TABS_OFFSET.PREVIEW;

  const isComparablesTabInOverviewTab: boolean =
    activeTabIndex === TRANSACTION_TABS_OFFSET.OVERVIEW &&
    activeContractViewTabOffset === TRANSACTION_CONTRACT_VIEW_TABS_OFFSET.COMPARABLES;

  if (!checkIfAdminURL() && transactionId !== null) {
    const channelName = transactionId ? `transaction:${transactionId}` : null;

    // Connect to transaction channel return null if null
    const transactionChannel = useChannel(channelName, undefined, (channel: Object, messages: Object) => {});

    // Websocket: check if any new channel was created by other users
    //  => load new channel from the back-end
    //  => and update the list of discussion's channels
    useEventHandler(
      transactionChannel,
      WebSocketNotification.TRANSACTION_CHANNEL,
      (channel: TransactionChannelWebsocket) => {
        // Other users will be notified
        if (channel.id !== activeTransactionChannel.id && channel.title !== activeTransactionChannel.title) {
          if (channel.id !== channelId) {
            toast(`New channel named ${channel.title} was created.`);
            // To handle dual pop-up case
            setChannelId(channel.id);
            dispatch(listTransactionChannels({}));
          }
        }
      },
    );

    // Websocket: check if any new message was created by other users
    //  => load messages from the back-end
    //  => and update the list of messages in the different chats
    useEventHandler(
      transactionChannel,
      WebSocketNotification.TRANSACTION_MESSAGE,
      (message: TransactionMessageWebsocket) => {
        // Update chat if other users have posted a message
        if (message.user_id !== userId) {
          dispatch(
            listTransactionMessages({
              threads: message.thread === ThreadType.Common.toLowerCase() ? [ThreadType.Common] : [ThreadType.Landlord],
            }),
          ).then((response: any) => {
            if (response.meta.requestStatus === 'fulfilled') {
              setTimeout(() => {
                // Go to the latest message
                scrollDownChat({ tabType: TabType.Preview });
              }, 100);
            }
          });
        }
      },
    );

    // Websocket: check if any new channel was created by other users
    //  => load new channel from the back-end
    //  => check upload approved pdf job was completed
    //  => get the latest history documents
    useEventHandler(
      transactionChannel,
      WebSocketNotification.APPROVAL_PROCESS_SUCCESSFUL,
      (channel: { status: string }) => {
        if (channel.status === 'successful') {
          dispatch(getTransactionHistoryDocuments({ id: activeTransaction.id }));
        }
      },
    );
  }

  const getPageHeaderProps = (): PageHeaderProps => {
    return {
      showBreadCrumb: true,
      breadCrumb,
      showBadge: isAborted(),
      badgeProps: badgeProps,
    };
  };

  const isCreatePage = location.pathname.includes('create');

  // Move to negotiation view after clicking here
  const generateFirstDraft = (): void => {
    onSaveAnswers(
      true,
      activeTransactionAnswers,
      tablesByGroup,
      activePreviewTab,
      activeTransaction,
      () => {},
      parameterMode,
      conditional,
      dispatch,
    );
  };

  return (
    <PageLayout
      pageSpinner={transactionsLoading || miscellaneousLoading || contractViewTabLoading}
      pageHeader={getPageHeaderProps()}
      flexHeight={isPreviewTab || isComparablesTabInOverviewTab}
    >
      {!isCreatePage && (
        <Button
          className="generate-first-draft btn grey-bg"
          onClick={generateFirstDraft}
        >
          GENERATE FIRST DRAFT
        </Button>
      )}
      <TransactionsTabs />
    </PageLayout>
  );
};

export default TransactionCreatorViewer;
