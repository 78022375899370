import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import AnalysisContext from 'common/model/AnalysisContext';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';

interface GetRevenueRiskAnalysisQueryVariables {
  currentLandlordEntityId: string | null;
  context: AnalysisContext;
  contextId: string | null;
  date?: string;
}

const GET_REVENUE_RISK_ANALYSIS = (context: AnalysisContext, contextId: string | null, date: string) => {
  if (!context) {
    throw new Error('Get Revenue Risk Analysis: Context could not be determined. Query aborted.');
  }

  const variables: GetRevenueRiskAnalysisQueryVariables = {
    currentLandlordEntityId: getCurrentLandlordEntityId(),
    date,
    context,
    contextId,
  };

  return {
    query: gql`
      query ($currentLandlordEntityId: ID!, $date: Date!, $context: AnalysisContext!, $contextId: ID) {
        getRevenueRiskAnalysis2(
          currentLandlordEntityId: $currentLandlordEntityId
          context: $context
          contextId: $contextId
          date: $date
        ) {
          industries {
            reference
            referenceId
            revenue
            contribution
            accumulativeRevenue
            accumulativeContribution
            contractNb
          }
          origins {
            reference
            referenceId
            revenue
            contribution
            accumulativeRevenue
            accumulativeContribution
            contractNb
          }
          tenants {
            reference
            referenceId
            revenue
            contribution
            accumulativeRevenue
            accumulativeContribution
            contractNb
          }
          totalRevenue
        }
      }
    `,
    variables,
  };
};

export const getRevenueRiskAnalysis = createAsyncThunk(
  'dashboardTab/getRevenueRiskAnalysis',
  async (args, { getState }) => {
    const {
      dashboard: { context, activePortfolio },
      dashboardTab: { date },
      stackingPlan: { activeProperty },
    } = getState() as RootState;

    let contextId: string | null = null;

    if (context === AnalysisContext.Property && activeProperty) {
      contextId = activeProperty.id;
    } else if (context === AnalysisContext.Portfolio && activePortfolio) {
      contextId = activePortfolio.id;
    }

    const response = await client.query(GET_REVENUE_RISK_ANALYSIS(context, contextId, date));
    return response;
  },
);
