import { CGovernmentRent } from 'common/_classes/contractsV2';
import moment from 'moment';
import { useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import AddPlus from 'atoms/AddPlus';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateActiveContractInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps, ViewProps } from 'common/api/miscellaneous';
import TabLabel from '../../TabLabel';
import GovernmentRentForm from './GovernmentRentForm';

const GovernmentRent = ({ mode, isCreated }: ModeProps & ViewProps) => {
  const dispatch = useAppDispatch();

  const { activeContract } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const governmentRents = activeContract?.governmentRents || [];

  const [tabIndex, setTabIndex] = useState(0);

  const disabled = mode === MODE_OPTIONS.READ || isCreated;

  const key = 'governmentRents';

  const onAddRent = () => {
    const value = [...governmentRents, new CGovernmentRent(null)];
    dispatch(updateActiveContractInput({ key, value }));
    setTabIndex(value.length - 1);
  };

  const onDelete = (index: number) => {
    const value = governmentRents.filter((_item, idx) => index !== idx);
    dispatch(updateActiveContractInput({ key, value }));
  };

  const getTabsMenu = (): TabMenuProps[] => {
    return governmentRents.map(({ startDate, endDate }, index: number) => {
      const start = moment(startDate).isValid() ? moment(startDate).format('DD/MM/YYYY') : '';
      const end = moment(endDate).isValid() ? moment(endDate).format('DD/MM/YYYY') : '';

      const dateText = `${start}${start && end ? ' - ' : ''}${end}`;

      return {
        key: `government-rent-tab-${index}`,
        label: (
          <TabLabel
            title="Gov Rent"
            disabled={mode === MODE_OPTIONS.READ}
            deletable={governmentRents.length !== 1}
            index={index}
            onDelete={onDelete}
            activeIndex={tabIndex}
            tabsLength={governmentRents.length}
            dateText={dateText}
            setTabIndex={setTabIndex}
          />
        ),
        customTab: (
          <GovernmentRentForm
            key={index}
            mode={mode}
            index={index}
            isCreated={isCreated}
          />
        ),
      };
    });
  };

  const onTabChange = (index: number) => setTabIndex(index);

  return (
    <div className="border-bottom-sm-grayish-magenta-light p-b-s m-t-sm">
      {!disabled && (
        <AddPlus
          label="Add charge period"
          onClick={onAddRent}
          className="m-l-auto"
        />
      )}

      <TabReact
        dataTest=""
        activeTabIndex={tabIndex}
        tabMenu={getTabsMenu()}
        onTabChange={onTabChange}
      />
    </div>
  );
};

export default GovernmentRent;
