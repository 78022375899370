import Contract from 'common/model/Contract';
import DocumentWorkflowHistory from 'common/model/DocumentWorkflowHistory';
import Floor from 'common/model/Floor';
import HistoryDocuments from 'common/model/HistoryDocuments';
import HistoryGeneral from 'common/model/HistoryGeneral';
import Policy from 'common/model/Policy';
import Premise from 'common/model/Premise';
import Property from 'common/model/Property';
import Provision from 'common/model/Provision';
import Space from 'common/model/Space';
import Transaction from 'common/model/Transaction';
import TransactionProvision from 'common/model/TransactionProvision';
import TranslationInfo from 'common/model/TranslationInfo';
import { TEntityPickNameOnly } from './Entity';

type PolicyNameOnly = Pick<Policy, 'name'>;
type ProvisionPickName = Pick<Provision, 'id' | 'name'>;

export interface TransactionListingProvision extends Omit<Pick<TransactionProvision, 'provision'>, 'provision'> {
  provision: ProvisionPickName;
}

export interface SpacePickMainDate extends Omit<Pick<Space, 'id' | 'name' | 'grossArea'>, 'grossArea'> {
  grossArea: number;
}

export interface FloorPickMainData extends Omit<Pick<Floor, 'id' | 'name' | 'spaces'>, 'spaces'> {
  spaces: SpacePickMainDate[];
}

interface PropertyPickMainData extends Omit<Pick<Property, 'id' | 'buildingName' | 'floors'>, 'floors'> {
  floors: FloorPickMainData[];
}

export interface TransactionListingPremise extends Omit<Premise, 'property'> {
  property: PropertyPickMainData;
}

export type HistoryGeneralStatus = Pick<HistoryGeneral, 'status' | 'date'>;

export interface TranslationInfoBase extends Omit<Pick<TranslationInfo, 'contract'>, 'contract'> {
  contract: Pick<Contract, 'id' | 'identifier'>;
}

interface ITransactionListingClone
  extends Omit<
    Pick<
      Transaction,
      | 'id'
      | 'identifier'
      | 'owner'
      | 'tenant'
      | 'policy'
      | 'documentTypeIds'
      | 'provisions'
      | 'premises'
      | 'historyGeneral'
      | 'historyDocuments'
      | 'translationInfo'
      | 'insertedAt'
      | 'updatedAt'
    >,
    | 'owner'
    | 'tenant'
    | 'policy'
    | 'provisions'
    | 'premises'
    | 'historyGeneral'
    | 'historyDocuments'
    | 'translationInfo'
  > {
  owner: TEntityPickNameOnly;
  tenant: TEntityPickNameOnly;
  policy: PolicyNameOnly;
  provisions: TransactionListingProvision[];
  premises: TransactionListingPremise[];
  historyGeneral: HistoryGeneralStatus[];
  historyDocuments: HistoryDocuments[];
  translationInfo: TranslationInfoBase;
}

export class TransactionListingClone implements ITransactionListingClone {
  id: string;
  identifier: string;
  owner: TEntityPickNameOnly;
  tenant: TEntityPickNameOnly;
  policy: PolicyNameOnly;
  provisions: TransactionListingProvision[];
  documentTypeIds: string[];
  premises: TransactionListingPremise[];
  historyGeneral: HistoryGeneralStatus[];
  historyDocuments: HistoryDocuments[];
  translationInfo: TranslationInfoBase;
  insertedAt: Date;
  updatedAt: Date;

  constructor(input: ITransactionListingClone) {
    this.id = input.id;
    this.identifier = input.identifier;
    this.owner = input.owner;
    this.tenant = input.tenant;
    this.policy = input.policy;
    this.provisions = input.provisions;
    this.documentTypeIds = input.documentTypeIds;
    this.premises = input.premises;
    this.historyGeneral = input.historyGeneral;
    this.historyDocuments = input.historyDocuments;
    this.translationInfo = input.translationInfo;
    this.insertedAt = input.insertedAt;
    this.updatedAt = input.updatedAt;
  }

  static fragments(): string {
    return `
      id
      identifier
      owner {
        name
      }
      tenant {
        name
      }
      policy {
        name
      }
      provisions {
        provision {
          id
          name
        }
      }
      documentTypeIds
      premises {
        property {
          id
          buildingName
          floors {
            id
            name
            spaces {
              id
              name
              grossArea
            }
          }
        }
        spaceIds
        floorsStatus {
          floorId
          whole
        }
      }
      historyGeneral {
        date
        status
      }
      historyDocuments {
        documentTypeId
        history {
          pendingChanges
          status
        }
      }
      translationInfo {
        contract {
          id
          identifier
        }
      }
      insertedAt
      updatedAt
    `;
  }
}
