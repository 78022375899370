import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface CleanupUnusedNodesQueryVariables {
  provisionId: string | null;
}

const CLEANUP_UNUSED_NODES = (provisionId: string | null) => {
  const variables: CleanupUnusedNodesQueryVariables = {
    provisionId,
  };

  return {
    query: gql`
      query ($provisionId: ID) {
        cleanupUnusedNodes(provisionId: $provisionId) {
          result
        }
      }
    `,
    variables,
  };
};

export const cleanupUnusedNodes = createAsyncThunk(
  'provisions/cleanupUnusedNodes',
  async ({ provisionId }: { provisionId: string | null }) => {
    const response = await adminClient.query(CLEANUP_UNUSED_NODES(provisionId));
    return response;
  },
);
