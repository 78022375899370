import Decimal from 'decimal.js';
import Contract2 from 'common/model/Contract2';
import Contract2Input from 'common/model/Contract2Input';
import ContractTranslationInfo from 'common/model/ContractTranslationInfo';
import LeaseType from 'common/model/LeaseType';
import ManagementChargeMonthlyAcFeesPayable from 'common/model/ManagementChargeMonthlyAcFeesPayable';
import NatureOfLease from 'common/model/NatureOfLease';
import OutOfHoursAcChargesPayable from 'common/model/OutOfHoursAcChargesPayable';
import SortOrder from 'common/model/SortOrder';
import TenancyType from 'common/model/TenancyType';
import { convertDateToISO } from 'utils/utils-date';
import { AcFeeInputClone, CAcFee } from './AcFee';
import { CContractEvent } from './ContractEvent';
import { CGovernmentRate, GovernmentRateInputClone } from './GovernmentRate';
import { CGovernmentRent, GovernmentRentInputClone } from './GovernmentRent';
import { CManagementCharge, ManagementChargeInputClone } from './ManagementCharge';

const eventsParamsBuilder = (eventsOrder?: SortOrder, eventsLimit?: number) => {
  if (!eventsLimit && !eventsOrder) return '';

  let result = '';

  if (eventsLimit) result += `(limit: ${eventsLimit}`;
  if (eventsOrder) result += `${result ? ', ' : '('}order: ${eventsOrder}`;

  result += ')';

  return result;
};

export interface Contract2InputClone
  extends Omit<
    Contract2Input,
    | 'acFees'
    | 'bindingOfferLetterDate'
    | 'governmentRates'
    | 'governmentRents'
    | 'leaseDate'
    | 'leaseType'
    | 'managementCharges'
    | 'tenantSignatureDate'
    | 'tenantPossessionDate'
    | 'monthlyAcFeesPayable'
    | 'outOfHoursAcPayble'
    | 'natureOfLease'
    | 'tenancyType'
  > {
  id: string;
  acFees: AcFeeInputClone[];
  bindingOfferLetterDate: Date | null;
  governmentRates: GovernmentRateInputClone[];
  governmentRents: GovernmentRentInputClone[];
  leaseDate: Date | null;
  leaseType: LeaseType | null;
  managementCharges: ManagementChargeInputClone[];
  tenantPossessionDate: Date | null;
  tenantSignatureDate: Date | null;
  monthlyAcFeesPayable: ManagementChargeMonthlyAcFeesPayable | null;
  outOfHoursAcPayble: OutOfHoursAcChargesPayable | null;
  natureOfLease: NatureOfLease | null;
  tenancyType: TenancyType | null;
}

export class CContract2 implements Contract2InputClone {
  id: string;
  acFees: AcFeeInputClone[];
  agentCompany: string;
  agentName: string;
  bindingOfferLetterDate: Date | null;
  description: string;
  governmentRates: GovernmentRateInputClone[];
  governmentRents: GovernmentRentInputClone[];
  identifier: string;
  leaseDate: Date | null;
  leaseType: LeaseType | null;
  managementCharges: ManagementChargeInputClone[];
  tenantPossessionDate: Date | null;
  tenantSignatureDate: Date | null;
  translationInfo?: ContractTranslationInfo | null;
  monthlyAcFeesPayable: ManagementChargeMonthlyAcFeesPayable | null;
  outOfHoursAcPayble: OutOfHoursAcChargesPayable | null;
  averageEffectiveRent: Decimal;
  natureOfLease: NatureOfLease | null;
  tenancyType: TenancyType | null;

  constructor(input: Contract2 | null) {
    this.id = input ? input.id : '';
    this.acFees = input ? input.acFees.map(AcFee => new CAcFee(AcFee)) : [];
    this.agentCompany = input ? input.agentCompany : '';
    this.agentName = input ? input.agentName : '';
    this.bindingOfferLetterDate = input && input.bindingOfferLetterDate ? new Date(input.bindingOfferLetterDate) : null;
    this.description = input ? input.description : '';
    this.governmentRates = input
      ? input.governmentRates.map(rate => new CGovernmentRate(rate))
      : [new CGovernmentRate(null)];
    this.governmentRents = input
      ? input.governmentRents.map(rent => new CGovernmentRent(rent))
      : [new CGovernmentRent(null)];
    this.identifier = input ? input.identifier : '';
    this.leaseDate = input && input.leaseDate ? new Date(input.leaseDate) : null;
    this.leaseType = input ? input.leaseType : null;
    this.managementCharges = input
      ? input.managementCharges.map(charge => new CManagementCharge(charge))
      : [new CManagementCharge(null)];
    this.tenantPossessionDate = input && input.tenantPossessionDate ? new Date(input.tenantPossessionDate) : null;
    this.tenantSignatureDate = input && input.tenantSignatureDate ? new Date(input.tenantSignatureDate) : null;
    this.translationInfo = input && input.translationInfo ? input.translationInfo : null;
    this.monthlyAcFeesPayable = input ? input.monthlyAcFeesPayable : null;
    this.outOfHoursAcPayble = input ? input.outOfHoursAcPayble : null;
    this.averageEffectiveRent = input ? input.averageEffectiveRent : new Decimal(0);
    this.natureOfLease = input ? input.natureOfLease : null;
    this.tenancyType = input ? input.tenancyType : null;
  }

  static fragment(eventsOrder?: SortOrder, eventsLimit?: number) {
    return `
        id
        acFees {
          ${CAcFee.fragment()}
        }
        agentCompany
        agentName
        bindingOfferLetterDate
        description
        events${eventsParamsBuilder(eventsOrder, eventsLimit)} {
          ${CContractEvent.fragment()}
        }
        governmentRates {
          ${CGovernmentRate.fragment()}
        }
        governmentRents {
          ${CGovernmentRent.fragment()}
        }
        identifier
        leaseDate
        leaseType
        natureOfLease
        tenancyType
        monthlyAcFeesPayable
        outOfHoursAcPayble
        managementCharges {
          ${CManagementCharge.fragment()}
        }
        tenantPossessionDate
        tenantSignatureDate
        translationInfo {
          date
          transaction {
            id
          }
        }
      `;
  }

  static formatForAPI(contract: CContract2 | null) {
    if (!contract) {
      throw new Error('Contract formatForAPI: Contract could not be determined. Format aborted.');
    }
    const {
      acFees,
      agentCompany,
      agentName,
      bindingOfferLetterDate,
      description,
      governmentRates,
      governmentRents,
      identifier,
      leaseDate,
      leaseType,
      natureOfLease,
      tenancyType,
      managementCharges,
      tenantPossessionDate,
      tenantSignatureDate,
      monthlyAcFeesPayable,
      outOfHoursAcPayble,
    } = contract;

    const params = {
      acFees: acFees.map(fee => CAcFee.formatForAPI(fee)),
      agentCompany,
      agentName,
      bindingOfferLetterDate: convertDateToISO(bindingOfferLetterDate),
      description,
      governmentRates: governmentRates.map(rate => CGovernmentRate.formatForAPI(rate)),
      governmentRents: governmentRents.map(rent => CGovernmentRent.formatForAPI(rent)),
      identifier,
      leaseDate: convertDateToISO(leaseDate),
      leaseType,
      natureOfLease,
      tenancyType,
      managementCharges: managementCharges.map(charge => CManagementCharge.formatForAPI(charge)),
      tenantPossessionDate: convertDateToISO(tenantPossessionDate),
      tenantSignatureDate: convertDateToISO(tenantSignatureDate),
      monthlyAcFeesPayable: monthlyAcFeesPayable || null,
      outOfHoursAcPayble: outOfHoursAcPayble || null,
    };

    return params;
  }
}
