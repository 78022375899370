import { ProvisionCategoryDetailClone, ProvisionDetailClone, TProvisionOnlyId } from 'common/_classes';
import Parameter from 'common/model/Parameter';
import ParameterTable from 'common/model/ParameterTable';
import ProvisionCategory from 'common/model/ProvisionCategory';
import ProvisionContent from 'common/model/ProvisionContent';
import { DocumentTypeProps } from 'common/api/policies';

export type IParameterOnlyId = Pick<Parameter, 'id'>;
export type IParameterPickName = Pick<Parameter, 'id' | 'name'>;

export type IParameterTableOnlyId = Pick<ParameterTable, 'id'>;
export type IParameterTablePickName = Pick<ParameterTable, 'id' | 'name'>;

export type ProvisionCategoryOnlyId = Pick<ProvisionCategory, 'id'>;
export type ProvisionCategoryPickName = Pick<ProvisionCategory, 'id' | 'name'>;
export type ProvisionCategoryBase = Pick<ProvisionCategory, 'id' | 'name' | 'color' | 'index' | 'provisions'>;
export type ProvisionContentOnlyDocTypeId = Pick<ProvisionContent, 'documentTypeId'>;

// TODO to clean it
export interface ContentClone {
  content: string;
  documentType?: DocumentTypeProps;
  documentTypeId: string;
  isAmendment?: boolean;
}

//TODO : define type ProvisionInput and put this function as a constructor associated to it.
export const convertToProvisionInput = (activeProvision: ProvisionDetailClone) /*: ProvisionInput*/ => {
  const params = JSON.parse(JSON.stringify(activeProvision));
  params.entityId = activeProvision.entity?.id;
  params.provisionCategoryId = activeProvision.provisionCategory?.id;
  params.existenceParameterId = activeProvision.existenceParameter ? activeProvision.existenceParameter.id : null;
  (params.useType = activeProvision?.useType || null), delete params.id;
  delete params.entity;
  delete params.provisionCategory;
  delete params.existenceParameter;
  delete params.showLinkedParameter;

  return params;
};

//TODO : define type ProvisionCategoryInput and put this function as a constructor associated to it.
export const convertToProvisionCategoryInput = (
  provisionCategory: ProvisionCategoryDetailClone,
) /*: ProvisionCategoryInput*/ => {
  const params /*: ProvisionCategoryInput*/ = {
    name: provisionCategory.name,
    color: provisionCategory.color,
    index: provisionCategory.index,
  };

  return params;
};

export interface ProvisionFilters {
  language?: string;
  entityId?: string;
  jurisdiction?: string;
  useType?: string;
}

export interface ProvisionCategoryClone extends Omit<ProvisionCategoryBase, 'provisions'> {
  provisions: TProvisionOnlyId[];
}
