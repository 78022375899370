import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { useCallback, useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { debounce, orderBy } from 'lodash';
import GuidelinesDisplayOnly from 'components/GuidelinesDisplayOnly';
import ParameterAnswerAccordion, { GuidelineLocationTypes } from 'components/ParameterAnswerAccordion';
import { createApprovalAnswers, getAnswersToBeDeleted } from 'components/PreviewTab/Discussion/LinkToParametersModal';
import { PREVIEW_INFORMATION_TABS_OFFSET } from 'components/PreviewTab/PreviewEditorSideMenu';
import { ChangesBoxType } from 'views/transactions/CreatorViewer/Tabs/ApprovalTab/ApprovalChanges';
import { ParameterModes } from 'store/miscellaneous/miscellaneousSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import Parameter from 'common/model/Parameter';
import ParameterTable from 'common/model/ParameterTable';
import ParameterTableColumn from 'common/model/ParameterTableColumn';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { listContextParametersGroup } from 'common/api/parameters';
import { AnswerProps, createPolicyAnswers } from 'common/api/policies';
import { createTransactionParameterApproval } from 'common/api/transactions/approvals/createTransactionParameterApproval';
import { checkIfPoliciesPage } from 'utils/tsHelper';
import { checkIfAnswerExists, getAnswers, getLatestAnswerValue } from 'utils/utils-answer';
import { getCurrentDateISO } from 'utils/utils-date';
import TableOfParametersQuestionsForm from './TableOfParametersQuestionsForm';
import { nullifyAnswersOfUnusedColumns } from './getNullifiedAnswersOfUnusedColumns';
import './ParametersGroupQuestionsForm.scss';

const triggerID: string = 'ParametersGroupQuestionsForm-trigger';

export const checkNotMessageAnswer = (answer: AnswerProps) => {
  return answer?.transactionMessage === undefined || answer?.transactionMessage === null;
};

export const onUpdateParameterAnswers = (
  transactionId: string | undefined,
  activeTransactionAnswers: AnswerProps[],
  getContextParameters: () => void,
  runFormatterAndConditions: (() => void) | undefined,
  parameterMode: ParameterModes,
  conditional: boolean,
  dispatch: Dispatch<AnyAction>,
  iterationUpdated?: boolean,
): void => {
  if (checkIfPoliciesPage()) {
    dispatch(createPolicyAnswers()).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        getContextParameters();
        if (runFormatterAndConditions) {
          runFormatterAndConditions();
        }
      }
    });
  } else {
    const currentDate = getCurrentDateISO();
    const parameterApprovalId: string | undefined = activeTransactionAnswers.filter(
      (answer: AnswerProps) =>
        answer?.transactionParameterApproval?.id !== undefined && (answer.dateOfAnswer as string).includes(currentDate),
    )[0]?.transactionParameterApproval?.id;

    const temporaryAnswers = activeTransactionAnswers.filter(
      (answer: AnswerProps) => checkNotMessageAnswer(answer) && answer?.approvalState === ValidApprovalStates.Pending,
    );

    const answerIds = getAnswersToBeDeleted(temporaryAnswers);

    if (parameterApprovalId === null || parameterApprovalId === undefined) {
      dispatch(createTransactionParameterApproval()).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          const parameterApprovalId = response.payload.data.createTransactionParameterApproval.id;
          createApprovalAnswers(
            ChangesBoxType.Parameter,
            parameterApprovalId,
            temporaryAnswers,
            answerIds,
            transactionId,
            runFormatterAndConditions,
            parameterMode,
            conditional,
            dispatch,
            iterationUpdated,
          );
        }
      });
    } else {
      createApprovalAnswers(
        ChangesBoxType.Parameter,
        parameterApprovalId,
        temporaryAnswers,
        answerIds,
        transactionId,
        runFormatterAndConditions,
        parameterMode,
        conditional,
        dispatch,
        iterationUpdated,
      );
    }
  }
};

export const checkIfIterationParameterUpdated = (
  activeTransactionAnswers: AnswerProps[],
  tablesByGroup: ParameterTable[],
) => {
  const parameterIds = tablesByGroup.map((tableByGroup: ParameterTable) => tableByGroup.rowNumber.parameter.id);
  const answerParameterIds = activeTransactionAnswers.filter(
    (answer: AnswerProps) =>
      answer.blockRef !== undefined && answer.paramRef.parameterId && answer.paramRef.tableId === null,
  );
  for (let i = 0; i < answerParameterIds.length; i++) {
    const paramRef = answerParameterIds[i].paramRef;
    if (parameterIds.includes(paramRef.parameterId)) {
      return true;
    }
  }
  return false;
};

// Receives the list of registered questions
// and displays the question type within the accordion
const ParametersGroupQuestionsForm = ({ parameters }: { parameters: Parameter[] }): JSX.Element => {
  const dispatch = useAppDispatch();

  const checkIfTransaction = window.location.pathname.includes('transactions');

  const { activePolicy, activePolicyAnswers } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransaction, activeTransactionAnswers } = useAppSelector((state: RootState) => state.transactionDetail);
  const { activeGroupId, groupGuideline, parametersCollection, parameterTablesCollection } = useAppSelector(
    (state: RootState) => state.parametersTab,
  );
  const { selectedProvisionFilter, conditional, parameterMode } = useAppSelector(
    (state: RootState) => state.miscellaneous,
  );

  const tablesByGroup = parameterTablesCollection.filter(
    (obj: ParameterTable) => obj.parameterGroup?.id === activeGroupId,
  );

  const context: ExecuteContext = checkIfTransaction ? ExecuteContext.Transaction : ExecuteContext.Policy;
  const contextId = checkIfTransaction ? activeTransaction.id : activePolicy.id;

  const answers: AnswerProps[] = getAnswers(activePolicyAnswers, activeTransactionAnswers);
  const { activeTab: activePreviewTab } = useAppSelector((state: RootState) => state.hiddenMenu);

  const checkIfDiscussionTab = activePreviewTab === PREVIEW_INFORMATION_TABS_OFFSET.DISCUSSION;

  const onChangeParameters = () => {
    dispatch(
      listContextParametersGroup({
        context,
        contextId,
        provisionId: checkIfDiscussionTab ? null : selectedProvisionFilter,
        conditional: checkIfDiscussionTab ? true : conditional,
        mode: checkIfDiscussionTab ? ParameterModes.Detailed : parameterMode,
        parameterGroupId: activeGroupId as string,
      }),
    );
  };

  // Call only after 0.5s of no user input activity
  const onChangeRequest = debounce(() => {
    onChangeParameters();
  }, 500);

  const debounceOnChange = useCallback(() => onChangeRequest(), []);

  const paramsGroupList = orderBy(
    parameters,
    (obj: Parameter) => {
      return Number(obj.index);
    },
    'asc',
  );

  useEffect(() => {
    nullifyAnswersOfUnusedColumns(tablesByGroup, answers, dispatch);
  }, [parametersCollection, parameterTablesCollection]);

  useEffect(() => {
    const defaultTrigger = document.getElementById(triggerID);
    if (defaultTrigger) defaultTrigger.click();
  });

  return (
    <div className="parameters-group-questions-form">
      {/* ***************************************
       * header of the body to define the legend
       * ***************************************/}

      {/* ***************************************
       * Guideline of the group of parameters
       * ***************************************/}
      <GuidelinesDisplayOnly
        className="m-b-l"
        generalGuidance={groupGuideline?.generalGuidance}
        valuationGuidance={groupGuideline?.valuationGuidance}
      />
      {/* ***************************************
       * listing of parameters
       * ***************************************/}
      {/* Keeping it any for the momemt */}
      {paramsGroupList.map((parameter: any, index: number) => {
        if (!parameter.rowNumber) {
          if (parameter.type === 'TAB' && !parameter.rowNumber) {
            return;
          }

          /* implementation of a parameter answering box */
          if (!!parameter.parameterTableColumns && parameter.parameterTableColumns.length > 0) {
            return;
          }

          const checkIfAnswer = checkIfAnswerExists({
            answers,
            answerType: parameter.answerType,
            parameterId: parameter.id,
            tableId: undefined,
            tabIndex: undefined,
          });

          return (
            <div key={`question-forms-${index}`}>
              <ParameterAnswerAccordion
                parameter={parameter}
                index={index}
                key={index}
                checkIfAnswer={checkIfAnswer}
                tableId={null}
                tabIndex={null}
                guidelineLocation={GuidelineLocationTypes.RIGHT}
              />
            </div>
          );
        } else {
          /* implementation of a tabulation for table of parameter with parameter answering boxes */
          if (parameter.rowNumber) {
            if (parameter.rowNumber.parameter && parameter.rowNumber.parameter.id !== null) {
              let answerValue = getLatestAnswerValue({
                answers,
                answerType: parameter.rowNumber.parameter.answerType,
                parameterId: parameter.rowNumber.parameter.id,
              });

              // Hide Table iteration if no policy inside
              /* Need to check if this ones needed or not
                setTimeout(() => {
                  let questionTab = document.getElementById(`question-forms-${index}`);
                  const accordians = questionTab?.querySelectorAll('.custom-accordion');
                  if (accordians && accordians !== null) {
                    if (accordians.length === 0) {
                      if (questionTab !== null) {
                        questionTab.style.display = 'none';
                      }
                    }
                  }
                }, 10);
                */
              const tableData = { ...parameter };
              // Just have parameters with partOfTable -> ON
              tableData.columns = tableData.columns.filter(
                (obj: ParameterTableColumn) =>
                  !!obj.parameter.parameterTableColumns && obj.parameter.parameterTableColumns.length > 0,
              );

              if (answerValue && answerValue > 0) {
                return (
                  <div
                    id={`question-forms-${index}`}
                    key={`question-forms-${index}`}
                    className="question-tab"
                  >
                    <TableOfParametersQuestionsForm
                      tableData={tableData}
                      answerValue={answerValue}
                      parameter={parameter.rowNumber.parameter}
                      debounceOnChange={debounceOnChange}
                    />
                  </div>
                );
              }
            }
          }
        }
      })}
    </div>
  );
};

export default ParametersGroupQuestionsForm;
