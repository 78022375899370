import { ParameterTableColumnClone } from 'common/_classes';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import Badge, { BadgeColor } from 'atoms/Badge';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import SpecialCheckBox from 'atoms/FormField/SpecialCheckBox';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateActiveParameter } from 'store/parameters/parameterDetailSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import ParameterTypes from 'common/model/ParameterTypes';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { DROPDOWN_OPTION } from 'utils/UI';
import { ANSWER_TYPE_OPTIONS } from 'utils/utils-answer';
import Choices from './Choices';

interface ParameterGeneralFormProps {
  mode: MODE_OPTIONS;
  type: string;
}

interface TYPE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: ParameterTypes;
}

const TYPE_OPTIONS: TYPE_OPTION[] = [
  { key: 1, text: 'Commercial', value: ParameterTypes.Commercial },
  { key: 2, text: 'Detailed Amendment', value: ParameterTypes.DetailedAmendment },
];

const ParameterGeneralForm = ({ mode }: ParameterGeneralFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const checkCreate: boolean = location.pathname.includes('create');

  const { activeParameter } = useAppSelector((state: RootState) => state.parameterDetail);
  const { name, question, answerType, type, unit, choices, questionNote, mandatoryChoice, parameterTableColumns } =
    activeParameter;

  const parameterTableDetailsUrl = (id: string | null): string => {
    return `/parameters/table/${id}/details`;
  };

  const onNavigateToTable = (e: React.MouseEvent<HTMLElement>, id: string | null): void => {
    e.preventDefault();
    navigate(parameterTableDetailsUrl(id));
  };

  const onChange = (key: string, value: string) => dispatch(updateActiveParameter({ key, value }));

  return (
    <Grid>
      <Grid.Column width={11}>
        <Grid.Row>
          <Grid.Column
            width={11}
            className="m-b-sm"
          >
            <InputField
              label="Parameter name"
              value={name}
              disabled={mode === MODE_OPTIONS.READ}
              required={mode === MODE_OPTIONS.EDIT}
              onChange={onChange}
              fieldKey="name"
              dataTest="parameter-name"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            width={11}
            className="m-b-sm"
          >
            <InputField
              label="Question"
              value={question}
              disabled={mode === MODE_OPTIONS.READ}
              required={mode === MODE_OPTIONS.EDIT}
              onChange={onChange}
              fieldKey="question"
              dataTest="parameter-question"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column
            width={11}
            className="m-b-sm"
          >
            <TextAreaField
              label="Question Note"
              value={questionNote}
              disabled={mode === MODE_OPTIONS.READ}
              onChange={onChange}
              fieldKey="questionNote"
              dataTest="parameter-question-note"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid.Column>
      <Grid.Column
        width={4}
        className="p-t-xm m-t-sm"
      >
        {!checkCreate && parameterTableColumns.length > 0 && (
          <>
            <Badge
              badgeColor={BadgeColor.ORANGE}
              dataTest="used-in-table"
            >
              USED IN A TABLE
            </Badge>
            <div className="table-wrapper columns m-t-sm">
              <table
                className="ui table dashboard-table provision-use"
                data-test="parameter-used-in-table"
              >
                <thead>
                  <tr>
                    <th>List of tables using this parameter</th>
                  </tr>
                </thead>

                <tbody>
                  {parameterTableColumns.map((column: ParameterTableColumnClone, index: number) => (
                    <tr key={index}>
                      <td>
                        <a
                          href={parameterTableDetailsUrl(column.table.id)}
                          onClick={e => onNavigateToTable(e, column.table.id)}
                        >
                          {column.table.name}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Grid.Column>
      <Grid.Row>
        <Grid.Column
          width={11}
          className="m-b-sm"
        >
          <SelectField
            label="Parameter answer type"
            value={answerType}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select answer type..."
            dataTest="answer-type"
            options={ANSWER_TYPE_OPTIONS}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="answerType"
          />

          {(answerType === AnswerTypes.SingleChoice || answerType === AnswerTypes.MultiChoice) && (
            <Choices
              //  @ts-ignore
              choices={choices}
              mode={mode}
            />
          )}
          {answerType === AnswerTypes.NumberUnit && (
            <InputField
              label="Unit"
              value={unit}
              disabled={mode === MODE_OPTIONS.READ}
              required={mode === MODE_OPTIONS.EDIT}
              onChange={onChange}
              fieldKey="unit"
              dataTest="number-unit"
            />
          )}
        </Grid.Column>

        {answerType === AnswerTypes.MultiChoice && (
          <Grid.Column
            width={5}
            className="p-t-l m-t-xs"
          >
            <SpecialCheckBox
              label="Mandatory choice?"
              mode={mode}
              value={mandatoryChoice}
              onChange={onChange}
              fieldKey="mandatoryChoice"
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column
          width={11}
          className="m-b-sm"
        >
          <SelectField
            label="Parameter type"
            value={type}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select parameter type..."
            dataTest="parameter-type"
            options={TYPE_OPTIONS}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="type"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ParameterGeneralForm;
