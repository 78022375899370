import { Form, Icon } from 'semantic-ui-react';
import AnswerTypes from 'common/model/AnswerTypes';
import { AnswerJsonBoolean, initAnswerValue } from 'common/api/formatters/types';
import QuestionNote from './QuestionNote';

interface BooleanContentProps extends AnswerJsonBoolean {
  onChange: (value: boolean | null) => void;
  note: string | null;
}

const BooleanContent = ({ value, onChange, note }: BooleanContentProps): JSX.Element => {
  const initValue: boolean = initAnswerValue(AnswerTypes.Boolean);

  const onHandleChange = (value: boolean | null): void => {
    onChange(value);
  };

  const getValue = (value?: any): string => {
    return value === '' ? initValue : value?.toString();
  };

  return (
    <Form>
      <Form.Group grouped>
        <Form.Radio
          label="Yes"
          name="parameterBooleanQuestion"
          type="radio"
          onChange={() => onHandleChange(true)}
          checked={getValue(value) === 'true'}
          // @ts-ignore
          value="true"
        />
        <Form.Radio
          label="No"
          name="parameterBooleanQuestion"
          type="radio"
          onChange={() => onHandleChange(false)}
          checked={getValue(value) === 'false'}
          // @ts-ignore
          value="false"
        />
      </Form.Group>
      <QuestionNote note={note} />
      <span
        onClick={() => onHandleChange(initValue)}
        className="parameters-questions-clear-data"
        role="button"
      >
        <Icon name="trash alternate outline" />
        Clear Data
      </span>
    </Form>
  );
};

export default BooleanContent;
