import { Dispatch, useState } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { onUpdateParameterAnswers } from 'components/ParametersGroupQuestionsForm';
import { checkIfIterationParameterUpdated } from 'components/ParametersGroupQuestionsForm';
import { PREVIEW_INFORMATION_TABS_OFFSET } from 'components/PreviewTab/PreviewEditorSideMenu';
import { ParameterModes } from 'store/miscellaneous/miscellaneousSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import ParameterTable from 'common/model/ParameterTable';
import { listContextParameters } from 'common/api/parameters';
import { AnswerProps, ParameterCategoryBase } from 'common/api/policies';
import { TransactionProps } from 'common/api/transactions';
import { checkIfPoliciesPage } from 'utils/tsHelper';
import { ParameterCount, getAnswers, getParameterCount } from 'utils/utils-answer';
import { CardElement } from '..';
import ParameterGroupsBox from './ParameterGroupsBox';
import './CategoriesTabs.scss';

const getTabMenuConfig = (
  categoryList: ParameterCategoryBase[],
  getCardElementsList: (category: ParameterCategoryBase) => CardElement[],
  answers: AnswerProps[],
): TabMenuProps[] => {
  const totalUnansweredParameters = (elementsList: CardElement[]): number => {
    let count = 0;
    for (let i = 0; i < elementsList.length; i++) {
      const parameterCount: ParameterCount = getParameterCount(elementsList[i]?.parametersListByGroup, answers);
      count += parameterCount.totalQuestions - parameterCount.definedAnswers;
    }
    return count;
  };

  const getCategoryParameters = (elementsList: CardElement[]): JSX.Element => {
    return (
      <Grid
        columns={16}
        className="category-parameters"
      >
        {elementsList.map((cardElement: CardElement, index) => {
          const parameterCount: ParameterCount = getParameterCount(cardElement?.parametersListByGroup, answers);
          if (parameterCount.totalQuestions !== 0) {
            return (
              <Grid.Row
                key={`category-parameters-${index}`}
                className="parameter-groups-box"
              >
                <ParameterGroupsBox
                  groupElement={cardElement}
                  index={index}
                  answers={answers}
                />
              </Grid.Row>
            );
          }
        })}
      </Grid>
    );
  };

  let tabs = [];

  for (let i = 0; i < categoryList.length; i++) {
    const name = categoryList[i].name.toLowerCase();

    const elementsList = getCardElementsList(categoryList[i]);

    const unansweredParameters = totalUnansweredParameters(elementsList);

    tabs.push({
      key: `categories-${name}`,
      dataTest: `categories-${name}`,
      label: categoryList[i].name,
      count: unansweredParameters,
      customTab: getCategoryParameters(elementsList),
    });
  }

  return tabs;
};

export const onSaveAnswers = (
  checkIfTransaction: boolean,
  activeTransactionAnswers: AnswerProps[],
  tablesByGroup: ParameterTable[],
  activePreviewTab: PREVIEW_INFORMATION_TABS_OFFSET,
  activeTransaction: TransactionProps,
  getContextParameters: () => void,
  parameterMode: ParameterModes,
  conditional: boolean,
  dispatch: Dispatch<any>,
): void => {
  const iterationUpdated = checkIfTransaction
    ? checkIfIterationParameterUpdated(activeTransactionAnswers, tablesByGroup)
    : false;

  if (activePreviewTab !== PREVIEW_INFORMATION_TABS_OFFSET.DISCUSSION) {
    onUpdateParameterAnswers(
      activeTransaction.id,
      activeTransactionAnswers,
      getContextParameters,
      undefined,
      parameterMode,
      conditional,
      dispatch,
      iterationUpdated,
    );
  } else {
    getContextParameters();
  }
};

const CategoriesTabs = ({
  getCardElementsList,
  categoryList,
}: {
  getCardElementsList: (category: ParameterCategoryBase) => CardElement[];
  categoryList: ParameterCategoryBase[];
}): JSX.Element => {
  const { activePolicyAnswers, activePolicy } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransaction, activeTransactionAnswers } = useAppSelector((state: RootState) => state.transactionDetail);
  const { selectedProvisionFilter, conditional, parameterMode } = useAppSelector(
    (state: RootState) => state.miscellaneous,
  );
  const { activeGroupId, parameterTablesCollection } = useAppSelector((state: RootState) => state.parametersTab);
  const [activeTab, setActiveTab] = useState(0);

  const answers: AnswerProps[] = getAnswers(activePolicyAnswers, activeTransactionAnswers);

  const tablesByGroup = parameterTablesCollection.filter(
    (obj: ParameterTable) => obj.parameterGroup?.id === activeGroupId,
  );

  const onTabChange = (tab: string | number | undefined) => {
    setActiveTab(tab as number);
  };

  // On Save button Click
  const checkIfTransaction = window.location.pathname.includes('transactions');
  const { activeTab: activePreviewTab } = useAppSelector((state: RootState) => state.hiddenMenu);

  const checkIfDiscussionTab = activePreviewTab === PREVIEW_INFORMATION_TABS_OFFSET.DISCUSSION;
  const context: ExecuteContext = checkIfTransaction ? ExecuteContext.Transaction : ExecuteContext.Policy;
  const contextId = checkIfTransaction ? activeTransaction.id : activePolicy.id;

  const dispatch = useAppDispatch();

  const getContextParameters = () => {
    dispatch(
      listContextParameters({
        context,
        contextId,
        provisionId: checkIfDiscussionTab ? null : selectedProvisionFilter,
        conditional: checkIfDiscussionTab ? true : conditional,
        mode: checkIfDiscussionTab ? ParameterModes.Detailed : parameterMode,
      }),
    );
  };

  /*
  Might be needed in the future
  const onResetAnswers = (): void => {
    getContextParameters();

    const resetAnswers =
      context === ExecuteContext.Policy ? resetPolicyParameterGroupAnswers : resetTransactionParameterGroupAnswers;
    dispatch(resetAnswers());
  };*/

  return (
    <>
      <div>
        {!checkIfTransaction && (
          <Button
            className="update-answers-save btn grey-bg"
            onClick={() =>
              onSaveAnswers(
                checkIfTransaction,
                activeTransactionAnswers,
                tablesByGroup,
                activePreviewTab,
                activeTransaction,
                getContextParameters,
                parameterMode,
                conditional,
                dispatch,
              )
            }
          >
            SAVE
          </Button>
        )}
      </div>
      <TabReact
        activeTabIndex={activeTab}
        customTabChangeAction={onTabChange}
        tabMenu={getTabMenuConfig(categoryList, getCardElementsList, answers)}
      />
    </>
  );
};

export default CategoriesTabs;
