import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import TextAreaField from 'atoms/FormField/TextArea';
import { updateActiveContractInput } from 'store/contractsV2/contractDetailSliceV2';
import { MODE_OPTIONS, ModeProps, ViewProps } from 'common/api/miscellaneous';
import { LEASE_TYPE_OPTIONS } from 'utils/UI';

const GeneralForm = ({ mode, isCreated }: ModeProps & ViewProps) => {
  const dispatch = useAppDispatch();

  const { activeContract } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);

  const onChange = (key: string, value: string) => dispatch(updateActiveContractInput({ key, value }));

  const disabled = mode === MODE_OPTIONS.READ || isCreated;
  const required = mode === MODE_OPTIONS.EDIT;

  return (
    <div>
      <div className="grid">
        <div className="col-2">
          <InputField
            label="Tenancy identifier"
            dataTest="contract-identifier-input"
            value={activeContract?.identifier || null}
            disabled={disabled}
            required={required}
            onChange={onChange}
            fieldKey="identifier"
          />
        </div>
        <div className="col-2">
          <SelectField
            label="Nature of lease"
            fieldKey="leaseType"
            dataTest=""
            value={activeContract?.leaseType}
            disabled={disabled}
            options={LEASE_TYPE_OPTIONS}
            onChange={onChange}
          />
        </div>
        <div className="col-2">
          <TextAreaField
            label="Special Remarks"
            dataTest="contract-summary-description"
            value={activeContract?.description || null}
            disabled={disabled}
            onChange={onChange}
            fieldKey="description"
          />
        </div>
      </div>

      <div className="grid m-t-s">
        <div className="col-2">
          <InputField
            label="Agent name"
            value={activeContract?.agentName || null}
            disabled={disabled}
            onChange={onChange}
            fieldKey="agentName"
          />
        </div>

        <div className="col-2">
          <InputField
            label="Agent company"
            value={activeContract?.agentCompany || null}
            disabled={disabled}
            onChange={onChange}
            fieldKey="agentCompany"
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralForm;
