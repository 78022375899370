import { ModeProps } from 'common/api/miscellaneous';
import PartiesForm from './PartiesForm';
import PolicyDocuments from './PolicyDocuments';
import Premises from './Premises';
import PremisesCharges from './PremisesCharges';
import SpecialRemarks from './SpecialRemarks';
import './SetupTab.scss';

const TransactionSetupContent = ({ mode }: ModeProps) => {
  return (
    <div className="transaction-setup-content">
      <div className="grid">
        <div className="col-2 row-label">Units/Spaces</div>
        <div className="col-9">
          <Premises mode={mode} />
        </div>
      </div>

      <div className="grid">
        <div className="col-2 row-label">Parties</div>
        <div className="col-9">
          <PartiesForm mode={mode} />
        </div>
      </div>

      <div className="grid">
        <div className="col-2 row-label">Policy & Documents</div>
        <div className="col-9">
          <PolicyDocuments mode={mode} />
        </div>
      </div>

      <div className="grid">
        <div className="col-2 row-label">Special remarks</div>
        <div className="col-9">
          <SpecialRemarks mode={mode} />
        </div>
      </div>

      <div className="grid">
        <div className="col-12 row-label">Area configuration</div>
        <div className="col-12">
          <PremisesCharges mode={mode} />
        </div>
      </div>
    </div>
  );
};

export default TransactionSetupContent;
