import { useEffect, useState } from 'react';
import { Form, Icon, Input } from 'semantic-ui-react';
import { InputFieldType } from 'atoms/FormField/Input';
import AnswerTypes from 'common/model/AnswerTypes';
import { AnswerJsonNumberUnit, initAnswerValue } from 'common/api/formatters/types';
import { numberInputValidator } from 'utils/utils-clean-input';
import { addSpaceOrComma, removeSpaceOrComma } from 'utils/utils-number';
import { generateUniqueId } from 'utils/utils-random';
import QuestionNote from '../QuestionNote';
import './NumberContent.scss';

interface NumberContentProps extends AnswerJsonNumberUnit {
  answerType: AnswerTypes;
  onChange: (value: any) => void;
  unit?: string;
  note: string | null;
}

const NumberContent = ({ value, answerType, unit, onChange, note }: NumberContentProps): JSX.Element => {
  const initValue: string = initAnswerValue(answerType);

  const [inputId] = useState<string>(generateUniqueId(null));

  const onChangeHandler = (value: number | string): void => {
    const valueN: string | number = value !== initValue ? +value : initValue;
    onChange(valueN);
  };

  useEffect(() => {
    if (answerType === AnswerTypes.NumberUnit) {
      const inputElement: HTMLElement | null | undefined = document.getElementById(inputId)?.parentElement;
      if (inputElement && inputElement.parentElement && unit) {
        inputElement.parentElement.style.setProperty('--unit', `"${unit}"`);
      }
    }
  }, []);

  return (
    <Form
      className="number-form"
      data-test="number-form"
    >
      <Input
        className={`number-type ${answerType}`}
        id={inputId}
        type={InputFieldType.TEXT}
        placeholder="Number"
        data-test={answerType}
        onChange={e => onChangeHandler(removeSpaceOrComma(e.target.value))}
        value={addSpaceOrComma(value, false)}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => numberInputValidator(e, true, false, value)}
      />
      <QuestionNote note={note} />
      <span
        onClick={() => onChangeHandler(initValue)}
        className="parameters-questions-clear-data"
        role="button"
        data-test="clear-data-button"
      >
        <Icon name="trash alternate outline" />
        Clear Data
      </span>
    </Form>
  );
};

export default NumberContent;
