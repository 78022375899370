import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';

interface GetNegotiationHeaderInfoQueryVariables {
  transactionId: string;
}

const GET_NEGOTIATION_HEADER_INFO = (id: string | undefined) => {
  if (!id) throw new Error('Get Landlord Transaction: id is required');

  const variables: GetNegotiationHeaderInfoQueryVariables = {
    transactionId: id,
  };

  return {
    query: gql`
      query ($transactionId: ID!) {
        getNegotiationHeaderInfo(transactionId: $transactionId) {
          headerName
          activityInfo
        }
      }
    `,
    variables,
  };
};

export const getNegotiationHeaderInfo = createAsyncThunk(
  'transactions/getNegotiationHeaderInfo',
  async ({ id }: { id: string | undefined }) => {
    const response = await client.query(GET_NEGOTIATION_HEADER_INFO(id));
    return response;
  },
);
