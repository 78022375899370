import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import CheckboxField from 'atoms/FormField/Checkbox';
import InputField from 'atoms/FormField/Input';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { generateUniqueId } from 'utils/utils-random';
import './PolicyRegulatories.scss';

interface PolicyRegulatoriesProps extends ModeProps {
  regulatories: string[];
  editable: boolean;
  onChange?: (key: string, value: any) => void;
  onOpen?: () => void;
  onClose?: () => void;
}

const PolicyRegulatories = ({
  mode,
  regulatories,
  editable,
  onChange,
  onOpen,
  onClose,
}: PolicyRegulatoriesProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [list, setList] = useState<{ key: string; value: string }[]>([]);

  const updateStore = (list: { key: string; value: string }[]): void => {
    if (!onChange) return;

    if (timer) {
      clearTimeout(timer);
    }

    const nonEmptyValues: string[] = list.filter(item => item.value).map(item => item.value);

    setTimer(setTimeout(() => onChange('regulatories', nonEmptyValues), 500));
  };

  const onUpdate = (key: string, value: string): void => {
    if (!editable || !onChange) return;

    const index: number = list.findIndex(item => item.key === key);
    const listCopy = [...list];
    listCopy.splice(index, 1, { key, value });
    setList(listCopy);
    updateStore(listCopy);
  };

  const onDelete = (key: string): void => {
    if (!editable) return;

    const newList = list.filter(item => item.key !== key);
    setList(newList);
    updateStore(newList);
  };

  const onAdd = (): void => {
    if (!editable) return;

    const length: number = list.length;
    const listCopy = [...list];
    listCopy.splice(length, 0, {
      key: generateUniqueId(''),
      value: '',
    });
    setList(listCopy);
  };

  const toggleOpen = (open: boolean): void => {
    if (open) {
      setOpen(true);
      onOpen && onOpen();
    } else {
      setOpen(false);
      onClose && onClose();
    }
  };

  const toggleSwitch = (_key: string, value: boolean): void => {
    if (!editable) return;

    if (!value) {
      setList([]);
      updateStore([]);
    } else {
      onAdd();
    }

    toggleOpen(value);
  };

  useEffect(() => {
    /* set internal states */
    if (mode === MODE_OPTIONS.READ) {
      toggleOpen(regulatories.length > 0);

      setList(
        regulatories.map((value: string) => ({
          key: generateUniqueId(''),
          value,
        })),
      );
    }

    if (mode === MODE_OPTIONS.EDIT && !editable) {
      setList(
        regulatories.map((value: string) => ({
          key: generateUniqueId(''),
          value,
        })),
      );
    }
  }, [regulatories, mode]);

  const canEdit: boolean = editable && mode === MODE_OPTIONS.EDIT && onChange !== undefined;

  return (
    <div className="policy-regulations">
      <div className="label-row">
        <label>Post-completion documents required</label>
        {editable && (
          <CheckboxField
            fieldKey="hasRentFreePeriod"
            value={open}
            dataTest="regulatory-check-box"
            disabled={!canEdit}
            onChange={toggleSwitch}
            toggle={true}
          />
        )}
      </div>

      {list.length > 0 ? (
        <ul className="listing m-t-s p-l-xxs">
          {list.map(({ value, key }, index) => (
            <li key={index}>
              <span className="sn">{index + 1}</span>
              <InputField
                className="m-l-xl"
                value={value}
                dataTest="regulatory-documents"
                disabled={!canEdit}
                onChange={onUpdate}
                fieldKey={key}
              />

              {canEdit && list.length > 1 && (
                <div
                  className="delete-icon"
                  onClick={() => onDelete(key)}
                  role="button"
                  tabIndex={0}
                  data-test="regulatory-documents-delete"
                >
                  <Icon
                    className="color-red-soft"
                    icon="fluent:delete-20-regular"
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <div className="listing">None</div>
      )}

      {canEdit && open && (
        <div
          className="regulations-add-row m-t-ml"
          onClick={onAdd}
        >
          <span className="circle-m plus-icon">
            <Icon icon="mdi:plus" />
          </span>
          <span
            className="m-l-xs"
            data-test="add-other-documents-button"
          >
            Add other documents
          </span>
        </div>
      )}
    </div>
  );
};

export default PolicyRegulatories;
