import ParametersDashboard from 'views/parameters/Dashboard';
import ParameterCreatorViewer from 'views/parameters/ParameterCreatorViewer';
import TableOfParameterCreatorViewer from 'views/parameters/TableOfParameterCreatorViewer';
import { isAuthenticated } from './redirects';

const ParametersRoutes = () => [
  {
    path: '/parameters',
    children: [
      {
        path: '/parameters/dashboard',
        element: isAuthenticated(<ParametersDashboard />),
      },
      {
        path: '/parameters/create',
        element: isAuthenticated(<ParameterCreatorViewer />),
      },
      {
        path: '/parameters/:id/details',
        element: isAuthenticated(<ParameterCreatorViewer isCreated={true} />),
      },
      {
        path: '/parameters/table/create',
        element: isAuthenticated(<TableOfParameterCreatorViewer />),
      },
      {
        path: '/parameters/table/:id/details',
        element: isAuthenticated(<TableOfParameterCreatorViewer isCreated={true} />),
      },
    ],
  },
];

export default ParametersRoutes;
