import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import InputField from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import 'views/provisions/CreatorViewer/Tabs/GeneralTab/ProvisionGeneralForm/ProvisionGeneralForm.scss';
import { createNewEntity } from 'store/entities/entityDetailSlice';
import { updateActiveTransaction } from 'store/transactions/transactionDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import { getAutomatedTransactionIdentifier, listPremises } from 'common/api/transactions';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';

const PartiesForm = ({ mode }: ModeProps) => {
  const {
    activeTransaction: { tenantId, premises, agentCompany, agentName },
    tenantEntitiesOptions,
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const landlordFieldKey = 'ownerId';
  const propertyId = premises.length !== 0 ? premises[0].property?.id : null;

  const onChange = (key: string, value: any) => {
    if (key === landlordFieldKey) {
      dispatch(listPremises({ ownerId: value }));
    }
    if (key === 'tenantId' && propertyId !== undefined) {
      dispatch(
        getAutomatedTransactionIdentifier({
          tenantId: value,
          propertyId: propertyId,
        }),
      ).then((response: any) => {
        if (response.meta.requestStatus === 'fulfilled') {
          dispatch(updateActiveTransaction({ key, value }));
        }
      });
    } else {
      dispatch(updateActiveTransaction({ key, value }));
    }
  };

  const createNewTenant = (): void => {
    dispatch(createNewEntity(null));
    navigate(`/tenant-entities/create?from=transactions&step=1`);
  };

  useEffect(() => {
    // Auto select current landlord
    onChange(landlordFieldKey, getCurrentLandlordEntityId());
  }, []);

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  return (
    <div className="grid flex-gap-sm">
      <div className="col-2 m-none p-none superscript-btn-col">
        {required && (
          <Button
            className="superscript-btn color-white bg-blue-very-dark-grayish"
            data-test="add-new-tenant-button"
            onClick={createNewTenant}
          >
            <Icon
              className="color-white m-r-xxs"
              icon={Icons.Add}
            />
            NEW TENANT
          </Button>
        )}

        <SelectField
          label="Tenant"
          fieldKey="tenantId"
          dataTest="transaction-tenant"
          value={tenantId}
          disabled={disabled}
          required={required}
          options={tenantEntitiesOptions}
          onChange={onChange}
          search={true}
        />
      </div>

      <div className="col-2 m-none p-none">
        <InputField
          label="Agent name"
          dataTest="transaction-agent-name"
          value={agentName}
          disabled={disabled}
          onChange={onChange}
          fieldKey="agentName"
        />
      </div>

      <div className="col-2 m-none p-none">
        <InputField
          label="Agent company"
          dataTest="transaction-agent-company"
          value={agentCompany}
          disabled={disabled}
          onChange={onChange}
          fieldKey="agentCompany"
        />
      </div>
    </div>
  );
};

export default PartiesForm;
