import MPolicy from 'common/model/Policy';
import MPolicyProvision from 'common/model/PolicyProvision';
import MProvision from 'common/model/Provision';
import MProvisionCategory from 'common/model/ProvisionCategory';
import MProvisionContent from 'common/model/ProvisionContent';

export type TProvisionContentPick = Pick<MProvisionContent, 'content' | 'documentTypeId'>;

export interface ProvisionClone
  extends Omit<
    Pick<MProvision, 'id' | 'name' | 'index' | 'provisionCategory' | 'contents'>,
    'provisionCategory' | 'contents'
  > {
  provisionCategory: Pick<MProvisionCategory, 'id'>;
  contents: TProvisionContentPick[];
}

export interface IPolicyProvisionDetailClone extends Omit<MPolicyProvision, 'id' | 'policy' | 'provision'> {
  id?: string;
  policy: Pick<MPolicy, 'id'>;
  provision: ProvisionClone;
}

export class PolicyProvisionDetailClone implements IPolicyProvisionDetailClone {
  id?: string;
  policy: Pick<MPolicy, 'id'>;
  provision: ProvisionClone;
  mandatory: boolean;
  selectedByDefault: boolean;

  constructor(input: IPolicyProvisionDetailClone) {
    this.id = input.id;
    this.policy = input.policy;
    this.provision = input.provision;
    this.mandatory = input.mandatory;
    this.selectedByDefault = input.selectedByDefault;
  }

  static fragments(): string {
    return `
      id
      policy {
        id
      }
      provision {
        id
        name
        index
        provisionCategory {
          id
        }
        contents {
          content
          documentTypeId
        }
      }
      mandatory
      selectedByDefault
    `;
  }
}
