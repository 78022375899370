import ProvisionCreatorViewer from 'views/provisions/CreatorViewer';
import ProvisionsDashboard from 'views/provisions/Dashboard';
import { isAuthenticated } from './redirects';

const ProvisionsRoutes = () => [
  {
    path: '/provisions',
    children: [
      {
        path: '/provisions/dashboard',
        element: isAuthenticated(<ProvisionsDashboard />),
      },
      {
        path: '/provisions/create',
        element: isAuthenticated(<ProvisionCreatorViewer />),
      },
      {
        path: '/provisions/:id/details',
        element: isAuthenticated(<ProvisionCreatorViewer isCreated={true} />),
      },
    ],
  },
];

export default ProvisionsRoutes;
