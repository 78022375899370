import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import { PageHeaderProps } from 'layouts/PageHeader';
import { PageHeaderToolbarProps } from 'layouts/PageHeader/PageHeaderToolbar';
import PageLayout from 'layouts/PageLayout';
import { BenchmarkInput, createBenchmark, getBenchmark, updateBenchmark } from 'common/api/benchmark';
import { MODE_OPTIONS, ViewProps } from 'common/api/miscellaneous';
import { moveTo } from 'utils/utils-actions';
import BenchmarkForm from '../BenchmarkForm';
import './CreatorViewer.scss';

export const validateBenchMark = (benchmark: BenchmarkInput): boolean => {
  if (benchmark.identifier && benchmark.identifier.trim() && benchmark.startDate && benchmark.endDate) {
    return false;
  }
  return true;
};

const BenchmarkViewer = ({ isCreated }: ViewProps) => {
  const location = useLocation();
  const isCreatePage = location.pathname.includes('create');
  const [mode, setMode] = useState<MODE_OPTIONS>(isCreatePage ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ);
  const formRef = useRef(null);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { activeBenchmark, isLoading: benchmarkLoading } = useAppSelector((state: RootState) => state.benchmarkDetail);
  const { isLoading: miscellaneousLoading } = useAppSelector((state: RootState) => state.miscellaneous);
  const navigate = useNavigate();

  useEffect(() => {
    if (isCreated) {
      if (id) {
        dispatch(getBenchmark({ id }));
      } else {
        throw new Error('BenchmarkViewer ID is missing');
      }
    }
  }, [id]);

  const checkDisabled = (): boolean => {
    return validateBenchMark(activeBenchmark);
  };

  /**
   * On Save button Click
   */
  const onSave = () => {
    if (isCreatePage) {
      moveTo({
        moveToEvent: dispatch(createBenchmark()),
        navigate,
        url: '/benchmarks/dashboard',
      });
    } else {
      dispatch(updateBenchmark());
    }
  };

  /**
   * On Cancel button Click
   */
  const onCancel = () => {
    if (isCreatePage) {
      navigate('/benchmarks/dashboard');
    } else {
      if (id) {
        dispatch(getBenchmark({ id }));
      } else {
        throw new Error('BenchmarkViewer ID is missing');
      }
    }
  };

  const getPageHeader = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Benchmarks',
        url: '/benchmarks/dashboard',
      },
      {
        title: activeBenchmark.identifier || '',
      },
    ];

    const headerButtons: PageHeaderToolbarProps = {
      headerToolbar: (
        <DetailsControlButtons
          mode={mode}
          setMode={setMode}
          checkDisabled={checkDisabled()}
          onCancel={onCancel}
          onSave={onSave}
        />
      ),
    };
    return {
      showBreadCrumb: true,
      breadCrumb,
      headerButtons: headerButtons,
    };
  };

  return (
    <PageLayout
      pageSpinner={benchmarkLoading || miscellaneousLoading}
      pageHeader={getPageHeader()}
      className="benchmark-page"
    >
      {isCreatePage ? (
        <BenchmarkForm mode={MODE_OPTIONS.EDIT} />
      ) : (
        <BenchmarkForm
          mode={mode}
          ref={formRef}
        />
      )}
    </PageLayout>
  );
};

export default BenchmarkViewer;
