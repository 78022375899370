import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';
import Scale from 'common/model/Scale';
import { PortfolioRevenueAnalysis, PropertiesEvents, PropertyRevenueAnalysis } from 'common/api/dashboard';
import {
  generatePortfolioRevenueAnalysisBase,
  generatePropertiesEvents,
  generatePropertyRevenueAnalysisBase,
  generateSummaryStatisticsOfTheMonth,
  getRevenueRiskAnalysis,
} from 'common/api/dashboard/dashboardTab';

const propertiesEvents: PropertiesEvents = {
  contractEvents: [],
  endDate: '',
  propertiesEvents: [],
  startDate: '',
};

const propertyRevenueAnalysis: PropertyRevenueAnalysis = {
  startDate: '',
  endDate: '',
  propertyId: '',
  propertyIdentifier: '',
  propertyName: '',
  period: Scale.Month,
  propertyData: [],
  unitsData: [],
};

const portfolioRevenueAnalysis: PortfolioRevenueAnalysis = {
  endDate: '',
  period: Scale.Month,
  portfolioData: [],
  propertiesData: [],
  startDate: '',
  unitsData: [],
};

const initialState = {
  date: moment().format('YYYY-MM-DD'),
  riskAnalysisLoading: false,
  riskAnalysis: {
    industries: [],
    origins: [],
    tenants: [],
    totalRevenue: '0',
  },
  scale: Scale.Month,
  propertiesEvents,
  propertyEventsIsLoading: false,
  dateFilter: {
    startDate: moment().startOf('year').format('YYYY-MM-DD'),
    endDate: moment().endOf('year').format('YYYY-MM-DD'),
  },
  summaryStats: null as any,
  propertyRevenueAnalysis,
  portfolioRevenueAnalysis,
  isLoading: false,
  summaryStatsLoading: false,
};

const dashboardTabSlice = createSlice({
  name: 'dashboardTab',
  initialState,
  reducers: {
    updateDate: (state, action) => {
      state.date = moment(action.payload.date).format('YYYY-MM-DD');
    },
    updateForecastFilterDates: (state, action) => {
      const { date, type } = action.payload;
      state.dateFilter[type as 'endDate' | 'startDate'] = moment(date).format('YYYY-MM-DD');
    },
    updateForecastScale: (state, action) => {
      state.scale = action.payload.value;
    },
  },
  extraReducers: builder => {
    //getRevenueRiskAnalysis
    builder.addCase(getRevenueRiskAnalysis.pending, state => {
      state.riskAnalysisLoading = true;
    });
    builder.addCase(getRevenueRiskAnalysis.fulfilled, (state, action) => {
      state.riskAnalysis = action.payload.data.getRevenueRiskAnalysis2;
      state.riskAnalysisLoading = false;
    });
    builder.addCase(getRevenueRiskAnalysis.rejected, (state, action) => {
      state.riskAnalysisLoading = false;
      console.warn(action.error);
      toast.error('getRevenueRiskAnalysis API request failed');
    });

    builder.addCase(generatePropertyRevenueAnalysisBase.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(generatePropertyRevenueAnalysisBase.fulfilled, (state, action) => {
      state.propertyRevenueAnalysis = action.payload.data.generatePropertyRevenueAnalysisBase2;
      state.isLoading = false;
    });
    builder.addCase(generatePropertyRevenueAnalysisBase.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('generatePropertyRevenueAnalysisBase API request failed');
    });

    builder.addCase(generatePortfolioRevenueAnalysisBase.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(generatePortfolioRevenueAnalysisBase.fulfilled, (state, action) => {
      state.portfolioRevenueAnalysis = action.payload.data.generatePortfolioRevenueAnalysisBase2;
      state.isLoading = false;
    });
    builder.addCase(generatePortfolioRevenueAnalysisBase.rejected, (state, action) => {
      state.isLoading = false;
      console.warn(action.error);
      toast.error('generatePortfolioRevenueAnalysisBase API request failed');
    });

    //generatePropertiesEvents
    builder.addCase(generatePropertiesEvents.pending, state => {
      state.propertyEventsIsLoading = true;
    });
    builder.addCase(generatePropertiesEvents.fulfilled, (state, action) => {
      state.propertiesEvents = action.payload.data.generatePropertiesEvents2;
      state.propertyEventsIsLoading = false;
    });
    builder.addCase(generatePropertiesEvents.rejected, (state, action) => {
      state.propertyEventsIsLoading = false;
      console.warn(action.error);
      toast.error('generatePropertiesEvents API request failed');
    });

    builder.addCase(generateSummaryStatisticsOfTheMonth.pending, state => {
      state.summaryStatsLoading = true;
    });
    builder.addCase(generateSummaryStatisticsOfTheMonth.fulfilled, (state, action) => {
      state.summaryStats = action.payload.data.generateSummaryStatisticsOfTheMonth2;
      state.summaryStatsLoading = false;
    });
    builder.addCase(generateSummaryStatisticsOfTheMonth.rejected, (state, action) => {
      state.summaryStatsLoading = false;
      console.warn(action.error);
      toast.error('generateSummaryStatisticsOfTheMonth API request failed');
    });
  },
});

export const { updateDate, updateForecastFilterDates, updateForecastScale } = dashboardTabSlice.actions;

export default dashboardTabSlice.reducer;
