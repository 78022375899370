import { createSlice } from '@reduxjs/toolkit';
import { EditorContentStoreState, contentReducers, editorContentInitialState } from './reducers/contentReducers';
import {
  EditorCrossReferenceStoreState,
  crossReferenceReducers,
  editorCrossReferenceInitialState,
} from './reducers/crossReference';
import { EditorNodeStoreState, editorNodeInitialState, nodeExtraReducers, nodeReducers } from './reducers/nodeReducers';
import {
  EditorProvisionStoreState,
  editorProvisionInitialState,
  provisionReducers,
} from './reducers/provisionReducers';
import { EditorShortcutStoreState, editorShortcutInitialState, shortcutReducers } from './reducers/shortcutReducer';

export interface EditorState {
  content: EditorContentStoreState;
  node: EditorNodeStoreState;
  provision: EditorProvisionStoreState;
  crossReference: EditorCrossReferenceStoreState;
  shortcut: EditorShortcutStoreState;
}

const initialState: EditorState = {
  content: editorContentInitialState,
  node: editorNodeInitialState,
  provision: editorProvisionInitialState,
  crossReference: editorCrossReferenceInitialState,
  shortcut: editorShortcutInitialState,
};

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    ...contentReducers,
    ...nodeReducers,
    ...provisionReducers,
    ...crossReferenceReducers,
    ...shortcutReducers,
  },
  extraReducers: builder => {
    nodeExtraReducers(builder);
  },
});

export const editorStoreActions = editorSlice.actions;

export default editorSlice.reducer;
