import ContractValuationDataOthers from 'common/model/ContractValuationDataOthers';
import LeaseType from 'common/model/LeaseType';
import NatureOfLease from 'common/model/NatureOfLease';

export class CContractValuationDataOthers {
  agentCompany: string;
  agentName: string;
  natureOfLease: NatureOfLease;
  specialRemarks: string;
  tenantIndustry: string;

  constructor(input: ContractValuationDataOthers) {
    this.agentCompany = input.agentCompany;
    this.agentName = input.agentName;
    this.natureOfLease = input.natureOfLease;
    this.specialRemarks = input.specialRemarks;
    this.tenantIndustry = input.tenantIndustry;
  }

  static fragment() {
    return `
        agentCompany
        agentName
        natureOfLease
        specialRemarks
        tenantIndustry
    `;
  }
}
