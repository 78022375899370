import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ParameterModes } from 'store/miscellaneous/miscellaneousSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { getAnswers, setAnswerInput } from 'utils/utils-answer';
import { getClientType } from 'utils/utils-authentication';
import { AnswerProps } from '../policies';

//TODO this is related to Policy and Transaction, hence should be in a different store and different subfolder

interface ListContextParametersGroupQueryVariables {
  context: ExecuteContext;
  contextId: string | undefined;
  landlordId: string | null;
  provisionId: string | null;
  groupId: string | null;
  tempNewAnswers: any;
  conditional: boolean;
  mode: ParameterModes;
}

export const LIST_CONTEXT_PARAMETERS_GROUP = async (
  context: ExecuteContext,
  contextId: string | undefined,
  provisionId: string | null,
  groupId: string | null,
  tempNewAnswers: any,
  conditional: boolean,
  mode: ParameterModes,
  // eslint-disable-next-line
) => {
  const tempNewAnswersInput = (await setAnswerInput(tempNewAnswers)).map(tempNewAnswer => {
    return {
      answer: tempNewAnswer.answer,
      answerType: tempNewAnswer.answerType,
      dateOfAnswer: tempNewAnswer.dateOfAnswer,
      paramRef: { ...tempNewAnswer.paramRef, offset: null, position: null },
    };
  });

  const variables: ListContextParametersGroupQueryVariables = {
    context,
    contextId,
    landlordId: getCurrentLandlordEntityId(),
    provisionId,
    groupId,
    tempNewAnswers: tempNewAnswersInput,
    conditional,
    mode,
  };

  return {
    query: gql`
      query (
        $context: ExecuteContext!
        $contextId: ID!
        $landlordId: ID!
        $groupId: ID
        $provisionId: ID
        $tempNewAnswers: [AnswerInput]!
        $conditional: Boolean!
        $mode: ParameterModes!
      ) {
        listContextParametersGroup(
          context: $context
          contextId: $contextId
          landlordId: $landlordId
          groupId: $groupId
          provisionId: $provisionId
          tempNewAnswers: $tempNewAnswers
          conditional: $conditional
          mode: $mode
        ) {
          parameters {
            id
            name
            answerType
            choices {
              id
              default
              text
              archive
              index
              applicableRowIndexes
            }
            index
            mandatoryChoice
            internalOriginFlag
            internalOriginId
            parameterGroup {
              id
              name
              index
              category {
                id
                name
                color
                index
              }
            }
            parameterTableColumns {
              title
              parameter {
                id
              }
              table {
                id
              }
            }
            guideline {
              explanatoryNotes
              generalGuidance
              internalNotes
              referenceType
              valuationGuidance
            }
            type
            question
            questionNote
            unit
            hasInventory
            inventoryFunction
            insertedAt
            updatedAt
          }
          parameterTables {
            id
            name
            type
            description
            parameterGroup {
              id
              name
              index
              category {
                id
                name
                color
                index
              }
            }
            index
            rowNumber {
              parameter {
                id
                name
                answerType
                question
              }
              table {
                id
                name
              }
            }
            columns {
              title
              applicableRowIndexes
              parameter {
                id
                question
                questionNote
                answerType
                type
                index
                choices {
                  id
                  default
                  text
                  archive
                  index
                  applicableRowIndexes
                }
                parameterTableColumns {
                  id
                }
                guideline {
                  explanatoryNotes
                  generalGuidance
                  internalNotes
                  referenceType
                  valuationGuidance
                }
              }
              table {
                id
              }
              conditions {
                operator
                list {
                  type
                  comparator
                  answer
                  position
                  paramRef {
                    index
                    offset
                    position
                    tableId
                    parameterId
                  }
                  paramRef2 {
                    index
                    offset
                    position
                    tableId
                    parameterId
                  }
                }
              }
            }
            insertedAt
            updatedAt
          }
        }
      }
    `,
    variables,
  };
};

export const listContextParametersGroup = createAsyncThunk(
  'parametersTab/listContextParametersGroup',
  async (
    {
      context,
      contextId,
      provisionId,
      conditional,
      mode,
      parameterGroupId,
    }: {
      context: ExecuteContext;
      contextId: string | undefined;
      provisionId: string | null;
      conditional: boolean;
      mode: ParameterModes;
      parameterGroupId: string;
    },
    { getState },
  ) => {
    const {
      policyDetail: { activePolicyAnswers },
      transactionDetail: { activeTransactionAnswers },
    } = getState() as RootState;
    const answers: AnswerProps[] = getAnswers(activePolicyAnswers, activeTransactionAnswers);
    // Get all the temporary answers
    const tempNewAnswers: AnswerProps[] = answers.filter((answer: AnswerProps) => answer.blockRef !== undefined);

    //const groupId = activeGroupId !== null ? activeGroupId : parameterGroupId;
    // Keeping it null for the moment
    const groupId = null;

    const response = await getClientType().query(
      await LIST_CONTEXT_PARAMETERS_GROUP(context, contextId, provisionId, groupId, tempNewAnswers, conditional, mode),
    );
    if (response.errors) {
      throw new Error(response.errors[0].message);
    }
    return response.data;
  },
);
