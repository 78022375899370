import React, { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ParetoGraphWithOutlinedContainer from 'atoms/ParetoGraphWithOutlinedContainer';
import { getRevenueRiskAnalysis } from 'common/api/dashboard/dashboardTab';

const RiskAnalysisGraphs = (): React.ReactElement => {
  const dispatch = useAppDispatch();

  const { riskAnalysis, date, riskAnalysisLoading } = useAppSelector((state: RootState) => state.dashboardTab);

  const industries = riskAnalysis?.industries ?? [];
  const tenants = riskAnalysis?.tenants ?? [];
  const origins = riskAnalysis?.origins ?? [];

  const { activeProperty } = useAppSelector((state: RootState) => state.stackingPlan);

  useEffect(() => {
    dispatch(getRevenueRiskAnalysis());
  }, [date, activeProperty]);

  return (
    <div className="flex flex-column m-t-l">
      <h2 className="infobar-header">Portfolio Exposure</h2>
      <ParetoGraphWithOutlinedContainer
        riskAnalysisData={industries}
        graphTitle="Industry mix"
        loading={riskAnalysisLoading}
        className="m-t-l"
      />
      <ParetoGraphWithOutlinedContainer
        riskAnalysisData={tenants}
        graphTitle="Top Revenue Generating Tenants"
        loading={riskAnalysisLoading}
        className="m-t-l"
      />
      <ParetoGraphWithOutlinedContainer
        riskAnalysisData={origins}
        graphTitle="Country of Origin"
        loading={riskAnalysisLoading}
        className="m-t-l"
      />
    </div>
  );
};

export default RiskAnalysisGraphs;
