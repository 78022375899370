import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getNegotiationHeaderInfo } from 'common/api/transactions';
import './NegotiationHeader.scss';

const NegotiationHeader = () => {
  const dispatch = useAppDispatch();
  const { negotiationHeaderInfo } = useAppSelector((state: RootState) => state.transactionDetail);

  const { id: transactionId } = useParams();

  useEffect(() => {
    if (transactionId) {
      dispatch(getNegotiationHeaderInfo({ id: transactionId }));
    }
  }, [transactionId]);

  return (
    <Grid className="pm-none transaction-header">
      <Grid.Row
        className="pm-none"
        verticalAlign="middle"
      >
        <Grid.Column className="transaction-info-wrapper">
          <div className="transaction-info">
            <div className="breadcrumb">
              <span>Transactions</span>
              <span>›</span>
              <span>{negotiationHeaderInfo?.headerName}</span>
            </div>
            <span className="transaction-id">Transaction ID: {transactionId}</span>
          </div>
        </Grid.Column>

        <Grid.Column className="analytics-column">
          <span
            dangerouslySetInnerHTML={{
              __html: negotiationHeaderInfo?.activityInfo?.replace(/(V\d+)/g, '<strong>$1</strong>') || '',
            }}
          />
        </Grid.Column>

        <Grid.Column className="stats-wrapper">
          <div className="stats-container">
            <div className="stat-item">
              <div className="stat-value-container">
                <div className="stat-value">$25,000/mo.</div>
                <div className="stat-percentage positive">+4%</div>
              </div>
              <div className="stat-label">Face Rent</div>
            </div>

            <div className="stat-item">
              <div className="stat-value-container">
                <div className="stat-value">$20.59/sf net</div>
                <div className="stat-percentage negative">-15%</div>
              </div>
              <div className="stat-label">Effective Rent</div>
            </div>

            <div className="stat-item">
              <div className="stat-value-container">
                <div className="stat-value">$4.2M</div>
                <div className="stat-percentage negative">-8%</div>
              </div>
              <div className="stat-label">Total Occupancy Cost</div>
            </div>

            <div className="stat-item">
              <div className="stat-value-container">
                <div className="stat-value positive">16% ABOVE</div>
              </div>
              <div className="stat-label">MARKET Beta</div>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default NegotiationHeader;
