import { Form, Icon, Input } from 'semantic-ui-react';
import AnswerTypes from 'common/model/AnswerTypes';
import { PeriodJsonContent, initAnswerValue } from 'common/api/formatters/types';
import QuestionNote from './QuestionNote';

interface PeriodContentProps {
  value: PeriodJsonContent;
  onChange: (value: PeriodJsonContent) => void;
  note: string | null;
}

const PeriodContent = ({ value, onChange, note }: PeriodContentProps): JSX.Element => {
  const initValue: PeriodJsonContent = initAnswerValue(AnswerTypes.Period);

  const onChangeHandler = (changedValue: string, key: string): void => {
    let periodCopy;

    if (!changedValue) {
      periodCopy = initValue;
    } else {
      periodCopy = { ...value };
      // @ts-ignore
      periodCopy[key] = changedValue;
    }
    onChange(periodCopy);
  };

  return (
    <Form>
      <Input
        label="Start Date"
        type="date"
        placeholder="Start Date"
        onChange={e => onChangeHandler(e.target.value, 'startDate')}
        value={value.startDate}
      />
      &nbsp;&nbsp;
      <Input
        type="date"
        label="End Date"
        placeholder="End Date"
        onChange={e => onChangeHandler(e.target.value, 'endDate')}
        value={value.endDate}
      />
      <QuestionNote note={note} />
      <span
        onClick={e => onChangeHandler('', 'clear')}
        className="parameters-questions-clear-data"
        role="button"
      >
        <Icon name="trash alternate outline" />
        Clear Data
      </span>
    </Form>
  );
};

export default PeriodContent;
