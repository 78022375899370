import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'apollo';
import { RootState } from 'store';
import { setHistoryDocumentsInput, setHistoryGeneralInput, setPremiseInput } from '.';
import { HistoryDocumentsProps, InitializationOptions, TransactionProps } from './types';

interface UpdateTransactionQueryVariables {
  currentLandlordEntityId: string;
  transactionId: string;
  params: any;
  toInitialize: any;
}

interface InitializeProps extends InitializationOptions {
  transactionId?: string;
}

export const UPDATE_TRANSACTION_WITH_INITALIZATION = async (
  transactionId: string | undefined,
  activeTransaction: TransactionProps,
  toInitialize: InitializationOptions,
) => {
  const {
    id,
    identifier,
    documentTypeIds,
    description,
    premises,
    ownerId,
    policyId,
    tenantId,
    historyGeneral,
    historyDocuments,
    regulatories,
    agentName,
    agentCompany,
  } = activeTransaction;
  let transactionID;
  if (transactionId) {
    transactionID = transactionId;
  } else {
    transactionID = id;
  }

  if (!transactionID) throw new Error('Update Transaction: id is required');

  const params = {
    agentName,
    agentCompany,
    ownerId,
    policyId,
    tenantId,
    identifier,
    documentTypeIds,
    regulatories,
    description,
    premises: setPremiseInput(premises),
    historyGeneral: setHistoryGeneralInput(historyGeneral),
    historyDocuments: setHistoryDocumentsInput(
      historyDocuments.filter((doc: HistoryDocumentsProps) =>
        documentTypeIds.some((docId: string) => docId === doc.documentTypeId),
      ),
    ),
  };

  const currentLandlordEntityId = activeTransaction.ownerId;

  if (!currentLandlordEntityId) throw new Error('Update Transaction: currentLandlordEntityId is required');

  const variables: UpdateTransactionQueryVariables = {
    currentLandlordEntityId,
    transactionId: transactionID,
    params,
    toInitialize,
  };

  return {
    mutation: gql`
      mutation (
        $currentLandlordEntityId: ID!
        $transactionId: ID!
        $params: TransactionInput!
        $toInitialize: ToInitialize!
      ) {
        updateTransactionWithInitialization(
          currentLandlordEntityId: $currentLandlordEntityId
          transactionId: $transactionId
          params: $params
          toInitialize: $toInitialize
        ) {
          id
          identifier
          documentTypeIds
          regulatories
          description
          agentName
          agentCompany
          owner {
            id
            name
          }
          tenant {
            id
            name
          }
          policy {
            id
          }
          contents {
            content
            documentType {
              name
              id
            }
          }
          answers {
            answer
            answerType
            dateOfAnswer
            fromPolicy
            fromInventory
            paramRef {
              parameterId
              tableId
              index
            }
            user {
              id
              firstName
              lastName
            }
            transaction {
              id
            }
          }
          premises {
            property {
              buildingName
              measurementUnit
              floors {
                id
                name
                index
                comments
                characteristics
                ceilingHeight
                floorLoading
                activationDate
                deactivationDate
                spaces {
                  id
                  name
                  registeredName
                  type
                  usedForOccupancyRate
                  grossArea
                  netArea
                  lettableArea
                  saleableArea
                  efficiencyRatio
                  comments
                  characteristics
                  activationDate
                  deactivationDate
                }
                floorPlans {
                  id
                  reviewFlag
                  floorPlan {
                    id
                    name
                    path
                    public
                    url
                  }
                  revisionDate
                  unitsNumber
                  whollyOwned
                  grossArea
                  netArea
                  lettableArea
                  saleableArea
                  efficiencyRatio
                  insertedAt
                }
              }
            }
            spaceIds
            floorsStatus {
              floorId
              whole
            }
          }
          historyGeneral {
            date
            documents {
              document {
                id
                name
                path
                public
                url
              }
              documentTypeId
            }
            status
            notes
            user {
              id
              firstName
              lastName
              photoUrl
            }
          }
          historyDocuments {
            documentTypeId
            history {
              user {
                id
                firstName
                lastName
                photoUrl
              }
              tenant {
                id
                name
              }
              pendingChanges
              note
              date
              documents {
                id
                name
                path
                public
                url
              }
              version
              status
            }
          }
          insertedAt
          updatedAt
        }
      }
    `,
    variables,
  };
};

export const updateTransactionWithInitalization = createAsyncThunk(
  'transactions/updateTransactionWithInitialization',
  async ({ transactionId, contents, parameters }: InitializeProps, { getState }) => {
    const {
      transactionDetail: { activeTransaction },
    } = getState() as RootState;
    const response = await client.mutate(
      await UPDATE_TRANSACTION_WITH_INITALIZATION(transactionId, activeTransaction, {
        contents,
        parameters,
      }),
    );
    return response;
  },
);
