import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Checkbox, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import EditorField from 'atoms/FormField/EditorField';
import SpecialCheckBox from 'atoms/FormField/SpecialCheckBox';
import GuidelinesComponent from 'components/Guidelines';
import RevisionForm from 'components/RevisionForm';
import TabContainer from 'components/TabContainer';
import ParameterUseTemplate from 'views/parameters/ParameterUseTemplate';
import { resetRowNumber, updateActiveParameterGuideLine } from 'store/parameters/parameterDetailSlice';
import { updateActiveParameter } from 'store/parameters/parameterDetailSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import SortOrder from 'common/model/SortOrder';
import { PARAMETER_TABLE_IMPLEMENTATION } from 'common/api/miscellaneous';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import {
  MultiLevelParamPaginationProps,
  listParameterGroups,
  listParameterTablesPage,
  listParameters,
} from 'common/api/multiLevelMenu';
import { listParameterSubGroups } from 'common/api/parameters';
import GroupManagementForm from './GroupManagementForm';
import ParameterGeneralForm from './ParameterGeneralForm';
import ParameterInformationTabToolbar from './ParameterInformationTabToolbar';
import RowConstrain from './RowConstrain';
import TableParameterGeneralForm from './TableParameterGeneralForm';
import './ParameterInformationTab.scss';

interface ContentProps {
  mode: MODE_OPTIONS;
  type: PARAMETER_TABLE_IMPLEMENTATION;
}

const Content = ({ mode, type }: ContentProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [fetchedChoiceConditionData, setFetchedChoiceConditionData] = useState(false);

  const { activeTableParameter, activeParameter } = useAppSelector((state: RootState) => state.parameterDetail);

  const { guideline, hasInventory, inventoryFunction, id: parameter_id, provisionExistence } = activeParameter;
  const { rowNumber } = activeTableParameter;

  const onChange = (key: string, value: string) => dispatch(updateActiveParameter({ key, value }));

  const isParameter: boolean = type === PARAMETER_TABLE_IMPLEMENTATION.PARAMETER;
  const isTable: boolean = type === PARAMETER_TABLE_IMPLEMENTATION.TABLE;

  useEffect(() => {
    if (
      !fetchedChoiceConditionData &&
      activeParameter.answerType &&
      [AnswerTypes.MultiChoice, AnswerTypes.SingleChoice].includes(activeParameter.answerType)
    ) {
      const FIRST_PAGINATION: number = 1000;
      const params: MultiLevelParamPaginationProps = {
        first: FIRST_PAGINATION,
        sortOrder: SortOrder.Asc,
      };

      dispatch(listParameters({ ...params, choices: true }));
      dispatch(listParameterGroups());
      dispatch(listParameterSubGroups());
      dispatch(listParameterTablesPage(params));

      setFetchedChoiceConditionData(true);
    }
  }, [activeParameter]);

  return (
    <Form className="parameter-create-view-information-tab">
      <Grid className="pm-none">
        <Grid.Row className="pm-none">
          <Grid.Column width={10}>
            <SectionComponent title={isTable ? '1. Details' : '1. Parameter Definition'}>
              {isParameter ? (
                <ParameterGeneralForm
                  mode={mode}
                  type={type}
                />
              ) : (
                <TableParameterGeneralForm mode={mode} />
              )}
            </SectionComponent>

            <SectionComponent
              title="2. Group Management"
              className="m-t-xl"
            >
              <GroupManagementForm
                mode={mode}
                type={type}
              />
            </SectionComponent>
          </Grid.Column>

          <Grid.Column width={6}>
            <SectionComponent title="Versioning Information">
              <RevisionForm note="This parameter has been updated with a new option." />
            </SectionComponent>

            {isParameter && (
              <SectionComponent
                title="3. Inventory"
                className="m-t-xxl"
              >
                <SpecialCheckBox
                  label="Connected to inventory"
                  mode={mode}
                  value={hasInventory}
                  onChange={onChange}
                  info={true}
                  fieldKey="hasInventory"
                />
                {hasInventory && (
                  <EditorField
                    className="m-t-sm"
                    onChange={(key, value) => onChange(key, value)}
                    value={inventoryFunction}
                    placeholder="Type here..."
                    disabled={mode === MODE_OPTIONS.READ}
                    fieldKey="inventoryFunction"
                    height="320"
                    codeSample={true}
                    code={true}
                  />
                )}
              </SectionComponent>
            )}

            {isParameter && (
              <SectionComponent
                title="4. Provision Existence"
                className="m-t-xxl"
              >
                <SpecialCheckBox
                  label="Connected to a provision"
                  mode={mode}
                  value={provisionExistence}
                  onChange={onChange}
                  info={true}
                  fieldKey="provisionExistence"
                  dataTest="connected-to-provision-checkbox"
                />
              </SectionComponent>
            )}

            {isTable && (
              <SectionComponent
                title={
                  <>
                    3. Constrain size of the table (rows)
                    <Checkbox
                      className="m-l-sm"
                      data-test="constrain-checkbox"
                      checked={rowNumber !== null}
                      toggle={true}
                      onChange={() => dispatch(resetRowNumber())}
                      disabled={mode === MODE_OPTIONS.READ}
                    />
                  </>
                }
                className="m-t-xl constrain-box"
              >
                <Grid.Row>
                  <Grid.Column width={11}>Set a specific number of rows that this table will always have.</Grid.Column>
                </Grid.Row>
                {rowNumber !== null && (
                  <Grid.Column
                    width={16}
                    className="pm-none"
                  >
                    <RowConstrain
                      mode={mode}
                      rowNumber={rowNumber}
                    />
                  </Grid.Column>
                )}
              </SectionComponent>
            )}
          </Grid.Column>

          {isParameter && (
            <>
              <Grid.Column
                width={16}
                className="m-t-xl"
              >
                <SectionComponent
                  className="guidelines-box"
                  data-test="guidelines"
                  title="5. Guidelines"
                >
                  <GuidelinesComponent
                    mode={mode}
                    guidelines={guideline}
                    onUpdateGuidelineType={updateActiveParameterGuideLine}
                  />
                </SectionComponent>
              </Grid.Column>

              {parameter_id && (
                <Grid.Column
                  width={16}
                  className="m-t-xl"
                >
                  <SectionComponent title={`${isParameter ? '6' : '5'}. Parameter Use`}>
                    <ParameterUseTemplate
                      displayAsRow={true}
                      parameter_id={parameter_id || ''}
                    />
                  </SectionComponent>
                </Grid.Column>
              )}
            </>
          )}
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const ParameterInformationTab = ({ type }: { type: PARAMETER_TABLE_IMPLEMENTATION }): JSX.Element => {
  const location = useLocation();

  const checkCreate: boolean = location.pathname.includes('create');
  const pageTypeCheck: MODE_OPTIONS = checkCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ;
  const [mode, setMode] = useState<MODE_OPTIONS>(pageTypeCheck);

  return (
    <TabContainer
      tabTitle={'General'}
      tabToolbar={
        <ParameterInformationTabToolbar
          mode={mode}
          setMode={setMode}
          isCreatePage={checkCreate}
          type={type}
        />
      }
      mode={mode}
    >
      <Content
        type={type}
        mode={checkCreate ? MODE_OPTIONS.EDIT : MODE_OPTIONS.READ}
      />
    </TabContainer>
  );
};

export default ParameterInformationTab;
