import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { updateCurrentDashboardTab } from 'store/dashboard/dashboardSlice';
import Dashboard from './Tabs/Dashboard';
import Forecasting from './Tabs/Forecasting';
import Forecasts from './Tabs/Forecasts';
import StackingPlan from './Tabs/StackingPlan';

export enum DASHBOARD_PAGE_TABS {
  DASHBOARD = 'dashboard',
  STACKING_PLAN = 'stacking-plan',
  FORECASTING = 'forecasting',
  FORECASTS = 'forecasts',
}

const getTabMenuConfig = (isCreateMode: boolean, activePropertyId?: string): TabMenuProps[] => {
  const showBuildingTabs = isCreateMode || !activePropertyId;
  const allTabs = [
    {
      key: 'dashboard-tab-information',
      href: `?tab=${DASHBOARD_PAGE_TABS.DASHBOARD}`,
      label: 'Summary',
      dataTest: 'nav-dashboard',
      customTab: <Dashboard />,
    },

    {
      key: 'dashboard-tab-forecasting',
      isDisabled: isCreateMode,
      href: `?tab=${DASHBOARD_PAGE_TABS.FORECASTING}`,
      label: 'Forecasts',
      dataTest: 'nav-forecasting',
      customTab: <Forecasting />,
    },
  ];
  const propertyTabs = [
    {
      key: 'dashboard-tab-stacking-plan',
      href: `?tab=${DASHBOARD_PAGE_TABS.STACKING_PLAN}`,
      label: 'Stacking Plan',
      dataTest: 'nav-stacking-plan',
      customTab: <StackingPlan />,
      isDisabled: isCreateMode || !activePropertyId,
    },
    {
      key: 'dashboard-tab-forecasts',
      href: `?tab=${DASHBOARD_PAGE_TABS.FORECASTING}`,
      label: 'Building Forecasts',
      dataTest: 'nav-forecasts',
      customTab: <Forecasts />,
      isDisabled: isCreateMode || !activePropertyId,
    },
  ];
  return showBuildingTabs ? allTabs : [...propertyTabs, ...allTabs];
};

const DashboardTabs = () => {
  const isCreateMode = location.pathname.includes('create');
  const { activeProperty } = useAppSelector((state: RootState) => state.stackingPlan);
  const { activeTabIndex } = useAppSelector((state: RootState) => state.dashboard);

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChangeAction={updateCurrentDashboardTab}
      tabMenu={getTabMenuConfig(isCreateMode, activeProperty?.id)}
    />
  );
};
export default DashboardTabs;
