import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import { useDebounce } from 'components/Editor/PageEditor';
import { getAnswersToBeDeleted } from 'views/transactions/TenantViewer/TenantPreviewSideMenu/Discussion/LinkToParametersModal';
import { updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import { ActiveDocType, clearActiveParameters, setActiveParameters } from 'store/miscellaneous/miscellaneousSlice';
import { resetActiveNode } from 'store/nodes/nodesSlice';
import { updateApprovalAnswer } from 'store/transactions/transactionDetailSlice';
import ConditionType from 'common/model/ConditionType';
import ExecuteContext from 'common/model/ExecuteContext';
import NodeType from 'common/model/NodeType';
import ValidApprovalStates from 'common/model/ValidApprovalStates';
import { getParameter, getParameterTable } from 'common/api/parameters';
import { AnswerProps } from 'common/api/policies';
import { ContentClone } from 'common/api/provisions';
import { updateTransactionDiscussionApproval } from 'common/api/transactions/approvals/updateTransactionDiscussionApproval';
import { ParamRefInput } from 'utils/types/nodes';
import { parseContent } from 'utils/utils-string';
import Discussion from '../Discussion';
import TableOfContent from './Tabs/TableOfContent';
import './PreviewEditorSideMenu.scss';

export enum PREVIEW_INFORMATION_TABS_OFFSET {
  TABLE_OF_CONTENTS = 0,
  CONTROL = 1,
  DISCUSSION = 2,
}

export const parseSelectedContent = (contents: ContentClone[], activeDocType: ActiveDocType) => {
  let content: ContentClone[] | string | undefined = contents?.filter(
    ({ documentType, isAmendment }) =>
      activeDocType?.id === documentType?.id && isAmendment === activeDocType?.isAmendment,
  );

  if (content && content?.length !== 0) {
    content = content[0].content;
  } else {
    content = '';
  }

  return parseContent(content);
};

const PreviewEditorSideMenu = ({ context }: { context: ExecuteContext }) => {
  const dispatch = useAppDispatch();

  const { activeTab } = useAppSelector((state: RootState) => state.hiddenMenu);
  const { activePolicyContents } = useAppSelector((state: RootState) => state.policyDetail);
  const {
    activeTransactionContents,
    transactionApprovalAnswers,
    activeTransactionChannel,
    activeTransactionAnswers,
    activeMessageId,
    activeTransaction,
  } = useAppSelector((state: RootState) => state.transactionDetail);
  const { activeNode } = useAppSelector((state: RootState) => state.nodes);

  const contents: ContentClone[] = context === ExecuteContext.Policy ? activePolicyContents : activeTransactionContents;

  const nodeType = activeNode?.type;
  const messageAnswer = transactionApprovalAnswers?.find(
    answer => answer.transactionMessage?.transactionChannel?.id === activeTransactionChannel?.id,
  );
  const { activeGroupId, parameterTablesCollection } = useAppSelector((state: RootState) => state.parametersTab);
  const { parameterMode, conditional } = useAppSelector((state: RootState) => state.miscellaneous);
  const draftApprovalMessage = messageAnswer?.transactionDiscussionApproval?.draftMessage;
  const approvalId = messageAnswer?.transactionDiscussionApproval?.id;
  const [draftMessage, setDraftMessage] = useState(draftApprovalMessage);

  const temporaryAnswers = activeTransactionAnswers.filter(
    (answer: AnswerProps) =>
      (answer?.transactionMessage?.id === activeMessageId || answer?.transactionMessageId === activeMessageId) &&
      answer?.approvalState === ValidApprovalStates.Pending,
  );
  const answerIds = getAnswersToBeDeleted(temporaryAnswers);

  useEffect(() => {
    if (draftApprovalMessage) {
      setDraftMessage(draftApprovalMessage);
    }
  }, [draftApprovalMessage]);

  const autoSaveDraft = useDebounce(() => {
    dispatch(updateTransactionDiscussionApproval({ approvalId: approvalId as string, draftMessage }));
  }, 2000);

  const onChangeApprovalMessage = (key: string, value: string) => {
    if (messageAnswer) {
      setDraftMessage(value);
      autoSaveDraft(approvalId, value, dispatch);
      dispatch(updateApprovalAnswer({ answerId: messageAnswer?.id, key: 'draftMessage', value }));
    } else {
      setDraftMessage(value);
    }
  };

  // TODO: Need to make draft message independent from approval answers
  // const onSave = () => {
  //   dispatch(createTransactionDiscussionApproval({ draftMessage })).then((response: any) => {
  //     if (response.meta.requestStatus === 'fulfilled') {
  //       const discussionApprovalId = response.payload.data.createTransactionDiscussionApproval.id;
  //       const tablesByGroup = parameterTablesCollection.filter(
  //         (obj: ParameterTable) => obj.parameterGroup?.id === activeGroupId,
  //       );

  //       createApprovalAnswers(
  //         ChangesBoxType.Discussion,
  //         discussionApprovalId,
  //         temporaryAnswers,
  //         answerIds,
  //         activeTransaction.id,
  //         undefined,
  //         parameterMode,
  //         conditional,
  //         dispatch,
  //       );
  //     }
  //   });
  // };

  const getParameterDetails = (paramRef: ParamRefInput) => {
    const { parameterId, tableId } = paramRef;

    if (!parameterId) return;

    const promises = [
      // @ts-ignore
      dispatch(getParameter({ id: parameterId, loading: false })),
    ];

    if (tableId) {
      // @ts-ignore
      promises.push(
        // @ts-ignore
        dispatch(getParameterTable({ id: tableId, loading: false })),
      );
    }

    Promise.all(promises).then((responses: any) => {
      const [parameterResponse, tableResponse] = responses;

      if (parameterResponse.meta.requestStatus === 'fulfilled') {
        let tableName: null | string = null;

        if (tableId) {
          if (tableResponse.meta.requestStatus === 'fulfilled') {
            tableName = tableResponse.payload.data.getParameterTable.name;
          }
        }

        const data = {
          ...parameterResponse.payload.data.getParameter,
          tableName,
          paramRef,
        };
        dispatch(setActiveParameters({ data }));
      }
    });
  };

  useEffect(() => {
    dispatch(clearActiveParameters());
    if (nodeType !== NodeType.Provision) {
      const getKey = ({ parameterId, tableId, index, offset, position }: ParamRefInput) =>
        `${parameterId}-${tableId}-${index}-${offset}-${position}`;

      const listOfParameters = activeNode?.conditions;

      const uniqueParameters: { [key: string]: boolean } = {};

      if ([NodeType.Clause, NodeType.Text].includes(nodeType as NodeType)) {
        listOfParameters?.forEach(({ list }) => {
          list.forEach(({ paramRef, paramRef2, type }) => {
            if (paramRef) {
              const key = getKey(paramRef);

              if (!uniqueParameters[key]) {
                uniqueParameters[key] = true;
                getParameterDetails(paramRef);
              }
            }

            if ([ConditionType.ParamParam, ConditionType.CountParam].includes(type) && paramRef2) {
              const key = getKey(paramRef2);

              if (!uniqueParameters[key]) {
                uniqueParameters[key] = true;
                getParameterDetails(paramRef2);
              }
            }
          });
        });
      } else {
        activeNode?.paramRefs?.forEach(paramRef => {
          if (paramRef) {
            const key = getKey(paramRef as ParamRefInput);

            if (!uniqueParameters[key]) {
              uniqueParameters[key] = true;
              getParameterDetails(paramRef as ParamRefInput);
            }
          }
        });
      }
    }
  }, [activeNode]);

  useEffect(() => {
    dispatch(resetActiveNode());
    dispatch(clearActiveParameters());
  }, []);

  const checkIfPolicy = context === ExecuteContext.Policy;

  const transactionMenuOptions: TabMenuProps[] = [
    {
      key: 'sidebar-tab-table',
      label: 'Table of Contents',
      dataTest: 'table-of-contents',
      customTab: (
        <TableOfContent
          contents={contents}
          nodeType={activeNode?.type}
        />
      ),
    },
    {
      key: 'sidebar-tab-main-comms',
      label: 'Main Comms',
      dataTest: 'main-comms',
      customTab: <></>,
    },
    {
      key: 'issues-tab',
      label: 'Issues',
      dataTest: 'issues-tab',
      customTab: (
        <Discussion
          draftMessage={draftMessage}
          setDraftMessage={setDraftMessage}
          onChangeApprovalMessage={onChangeApprovalMessage}
        />
      ),
    },
    {
      key: 'files-tab',
      label: 'Files',
      dataTest: 'files-tab',
      customTab: <></>,
    },
  ];

  const policyMenuOptions = [
    {
      key: 'sidebar-tab-table',
      label: 'Table of Contents',
      dataTest: 'table-of-contents',
      customTab: (
        <TableOfContent
          contents={contents}
          nodeType={activeNode?.type}
        />
      ),
    },
  ];

  const menuOptions = checkIfPolicy ? policyMenuOptions : transactionMenuOptions;

  return (
    <TabReact
      className={`preview-sidebar-menu-tab ${activeTab === PREVIEW_INFORMATION_TABS_OFFSET.DISCUSSION && 'negotiation-tab'}`}
      dataTest="preview-sidebar-menu-tab"
      activeTabIndex={activeTab}
      onTabChange={(activeIndex: number) => dispatch(updateSidebarTab(activeIndex))}
      tabMenu={menuOptions}
      nested={true}
    />
  );
};

export default PreviewEditorSideMenu;
