import { Form, Icon, TextArea } from 'semantic-ui-react';
import AnswerTypes from 'common/model/AnswerTypes';
import { AnswerJsonString, initAnswerValue } from 'common/api/formatters/types';
import QuestionNote from './QuestionNote';

interface TextContentProps extends AnswerJsonString {
  onChange: (value: string) => void;
  note: string | null;
}

const TextContent = ({ value, onChange, note }: TextContentProps): JSX.Element => {
  const initValue: string = initAnswerValue(AnswerTypes.Text);

  const onChangeHandler = (value: string): void => {
    onChange(value);
  };

  return (
    <Form>
      <TextArea
        placeholder="Type here"
        onChange={e => onChangeHandler(e.target.value)}
        value={value as string}
      />
      <QuestionNote note={note} />
      <span
        onClick={() => onChangeHandler(initValue)}
        className="parameters-questions-clear-data"
        role="button"
      >
        <Icon name="trash alternate outline" />
        Clear Data
      </span>
    </Form>
  );
};

export default TextContent;
