import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';

interface DeleteNodeQueryVariables {
  id: string;
}

export const DELETE_NODE = (nodeId: string) => {
  if (!nodeId) throw new Error('Delete Node: nodeId is required');

  const variables: DeleteNodeQueryVariables = {
    id: nodeId,
  };

  return {
    mutation: gql`
      mutation ($id: ID!) {
        deleteNode(id: $id) {
          name
          id
        }
      }
    `,
    variables,
  };
};

export const deleteNode = createAsyncThunk('refactoredEditor/node/deleteNode', async ({ id }: { id: string }) => {
  const response = await adminClient.mutate(DELETE_NODE(id));
  return response;
});
