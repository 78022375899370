import MProvision from 'common/model/Provision';
import { ProvisionCategoryBase } from 'common/api/provisions';

export type TProvisionOnlyId = Pick<MProvision, 'id'>;

export interface IProvisionCategoryDetailClone
  extends Omit<ProvisionCategoryBase, 'id' | 'name' | 'index' | 'color' | 'provisions'> {
  id?: string;
  name: string | null;
  index: number | null;
  color: string | null;
  provisions?: TProvisionOnlyId[];
}

export class ProvisionCategoryDetailClone implements IProvisionCategoryDetailClone {
  id?: string;
  name: string | null;
  index: number | null;
  color: string | null;
  provisions?: TProvisionOnlyId[];

  constructor(input: IProvisionCategoryDetailClone) {
    this.id = input.id;
    this.name = input.name;
    this.index = input.index;
    this.color = input.color;
    this.provisions = input.provisions;
  }

  static fragments(): string {
    return `
      id
      name
      color
      index
      provisions {
        id
      }
    `;
  }
}
