import { Accordion, Grid, Icon } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import ParametersGroupQuestionsForm from 'components/ParametersGroupQuestionsForm';
import { updateSubGroupsAccordion } from 'store/parametersTab/parametersTabSlice';
import Parameter from 'common/model/Parameter';
import { AnswerProps } from 'common/api/policies';
import { ParameterCount, getParameterCount } from 'utils/utils-answer';
import CountBox from '../CountBox';
import './ParameterSubGroupsBox.scss';

export interface SubGroup {
  subGroup: string;
  parameters: Parameter[];
}

const ParameterSubGroupsBox = ({
  subGroup,
  index,
  answers,
  groupId,
}: {
  subGroup: SubGroup;
  index: number;
  answers: AnswerProps[];
  groupId: string;
}) => {
  const dispatch = useAppDispatch();
  const { openedSubgroups } = useAppSelector((state: RootState) => state.parametersTab);
  const accordionKey = `${groupId}-${subGroup.subGroup}`;

  const isActive = openedSubgroups.find((subName: string) => subName === accordionKey) !== undefined;

  const totalUnansweredParameters = (parameters: Parameter[]): number => {
    const parameterCount: ParameterCount = getParameterCount(parameters, answers);
    return parameterCount.totalQuestions - parameterCount.definedAnswers;
  };

  const unansweredCount = totalUnansweredParameters(subGroup.parameters);
  const countZero = unansweredCount !== 0;

  return (
    <Accordion
      id={subGroup.subGroup}
      className={`parameter-groups-box sub-group custom-accordion p-s ${countZero && 'count-zero'}`}
    >
      <Accordion.Title
        active={isActive}
        index={index}
        onClick={() => dispatch(updateSubGroupsAccordion({ add: !isActive, group: accordionKey }))}
      >
        {unansweredCount !== 0 && <CountBox count={unansweredCount} />}
        <Icon name="dropdown" />
        <b>{subGroup.subGroup}</b>
      </Accordion.Title>
      <Accordion.Content
        className="custom-accordion-content"
        active={isActive}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <ParametersGroupQuestionsForm parameters={subGroup.parameters} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion.Content>
    </Accordion>
  );
};

export default ParameterSubGroupsBox;
