import { createSlice } from '@reduxjs/toolkit';
import { EntityPickName, ProvisionCategoryDetailClone, ProvisionListingClone } from 'common/_classes';
import { toast } from 'react-toastify';
import NodeIntegrityResult from 'common/model/NodeIntegrityResult';
import ProvisionUse from 'common/model/ProvisionUse';
import { DocumentTypeClone } from 'common/api/miscellaneous';
import {
  ProvisionCategoryClone,
  ProvisionFilters,
  checkNodeIntegrity,
  checkProvisionUse,
  cleanupUnusedNodes,
  createProvisionCategory,
  deleteProvision,
  deleteProvisionCategory,
  duplicateProvision,
  getProvisionPdfHtml,
  listLandlordEntities,
  listProvisionCategories,
  listProvisions,
  listProvisionsAndCategories,
  updateProvisionCategory,
} from 'common/api/provisions';
import { DROPDOWN_OPTION } from 'utils/UI';

const initCategory: ProvisionCategoryDetailClone = {
  name: null,
  color: null,
  index: null,
};

const initProvisionUse: ProvisionUse = {
  policies: [],
  transactions: [],
};

const initFilters: ProvisionFilters = {
  language: undefined,
  entityId: undefined,
  jurisdiction: undefined,
  useType: undefined,
};

interface ProvisionsListingState {
  activeCategory: ProvisionCategoryDetailClone;
  provisionsList: ProvisionListingClone[];
  provisionCategoriesList: ProvisionCategoryClone[];
  categoriesOptions: DROPDOWN_OPTION[];
  isLoading: boolean;
  silentLoading: boolean;
  search: string | null;
  isSearching: boolean;
  provisionModalState: boolean;
  activeModalProvision: null;
  categoriesLoading: boolean;
  nodeIntegrityData: string | null;
  provisionUse: ProvisionUse;
  provisionUseLoading: boolean;
  nodeIntegrityLoader: boolean;
  owners: EntityPickName[];
  filters: ProvisionFilters;
}

const initialState: ProvisionsListingState = {
  activeCategory: initCategory,
  provisionsList: [],
  provisionCategoriesList: [],
  categoriesOptions: [],
  isLoading: false,
  silentLoading: false,
  search: null,
  isSearching: false,
  provisionModalState: false,
  activeModalProvision: null,
  categoriesLoading: false,
  nodeIntegrityData: null,
  provisionUse: initProvisionUse,
  provisionUseLoading: false,
  nodeIntegrityLoader: false,
  owners: [],
  filters: initFilters,
};

const provisionsListingSlice = createSlice({
  name: 'provisionsListing',
  initialState,
  reducers: {
    setActiveCategory: (state, action) => {
      const { category } = action.payload;
      state.activeCategory = category;
    },
    resetProvisionUse: state => {
      state.provisionUse = initProvisionUse;
    },
    updateActiveCategory: (state, action) => {
      const { key } = action.payload;
      let { value } = action.payload;
      if (key === 'index') {
        value = parseInt(value);
      }
      // @ts-ignore
      state.activeCategory[key] = value;
    },
    resetActiveCategory: state => {
      state.activeCategory = initCategory;
    },
    updateSearch: (state, action) => {
      state.search = action.payload;
    },
    resetProvisionsSearch: state => {
      state.search = null;
    },
    updateProvisionFilters: (state, action) => {
      const { key, value } = action.payload;
      // @ts-ignore
      state.filters[key] = value;
    },
    updateProvisionModalState: (state, action) => {
      const { open, provision } = action.payload;
      state.provisionModalState = open;
      state.activeModalProvision = provision ? provision : null;
    },
  },
  extraReducers: builder => {
    //duplicateProvision
    builder.addCase(duplicateProvision.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(duplicateProvision.fulfilled, state => {
      state.isLoading = false;
      toast.success(`Provision duplicated successfully`);
    });
    builder.addCase(duplicateProvision.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('duplicateProvision API request rejected');
    });

    //deleteProvision
    builder.addCase(deleteProvision.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteProvision.fulfilled, (state, action) => {
      state.provisionsList = state.provisionsList.filter(element => element.id !== action.meta.arg.id);
      state.isLoading = false;
      toast.success('Provision deleted successfully');
    });
    builder.addCase(deleteProvision.rejected, (state, action) => {
      state.isLoading = false;
      toast.error(action.error.message);
    });

    //listProvisionsAndCategories
    builder.addCase(listProvisionsAndCategories.pending, state => {
      if (state.search) {
        state.isSearching = true;
      } else {
        state.isLoading = true;
      }
    });
    builder.addCase(listProvisionsAndCategories.fulfilled, (state, action) => {
      const provisions = action.payload.data.listProvisions.edges.map((element: any) => element.node);
      const categories = action.payload.data.listProvisionCategories.edges.map((element: any) => element.node);
      state.provisionsList = provisions;
      state.provisionCategoriesList = categories;
      state.isLoading = false;
      state.isSearching = false;
    });
    builder.addCase(listProvisionsAndCategories.rejected, (state, action) => {
      state.isLoading = false;
      state.isSearching = false;
      console.error(action.error);
      toast.error('listProvisionsAndCategories API request rejected');
    });

    //createProvisionCategory
    builder.addCase(createProvisionCategory.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(createProvisionCategory.fulfilled, (state, action) => {
      const createProvisionCategory: ProvisionCategoryClone = action.payload.data.createProvisionCategory;
      state.activeCategory = initCategory;
      state.provisionCategoriesList = [...state.provisionCategoriesList, createProvisionCategory];
      state.isLoading = false;
      toast.success('Category created successfully');
    });
    builder.addCase(createProvisionCategory.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('createProvisionCategory API request rejected');
    });

    //updateProvisionCategory
    builder.addCase(updateProvisionCategory.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(updateProvisionCategory.fulfilled, state => {
      state.activeCategory = initCategory;
      state.isLoading = false;
      toast.success(`Provision category updated successfully`);
    });
    builder.addCase(updateProvisionCategory.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('updateProvisionCategory API request rejected');
    });

    //deleteProvisionCategory
    builder.addCase(deleteProvisionCategory.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(deleteProvisionCategory.fulfilled, (state, action) => {
      state.provisionCategoriesList = state.provisionCategoriesList.filter(
        element => element.id !== action.meta.arg.id,
      );
      state.isLoading = false;
      toast.success('Provision category deleted successfully');
    });
    builder.addCase(deleteProvisionCategory.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('deleteProvisionCategory API request rejected');
    });

    //listProvisionCategories
    builder.addCase(listProvisionCategories.pending, state => {
      state.categoriesLoading = false;
    });
    builder.addCase(listProvisionCategories.fulfilled, (state, action) => {
      const categoriesList = action.payload.data.listProvisionCategories.edges.map((element: any) => element.node);
      // Create Category Options
      const categoriesOptions: DROPDOWN_OPTION[] = categoriesList.map((category: any, index: number) => {
        const result: DROPDOWN_OPTION = {
          key: index,
          text: category.name,
          value: category.id,
        };
        return result;
      });

      state.categoriesOptions = categoriesOptions;
      state.categoriesLoading = false;
    });
    builder.addCase(listProvisionCategories.rejected, (state, action) => {
      state.categoriesLoading = false;
      console.error(action.error);
      toast.error('listProvisionCategories API request rejected');
    });

    //listProvisions
    builder.addCase(listProvisions.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(listProvisions.fulfilled, (state, action) => {
      const provisions = action.payload.data.listProvisions;
      state.provisionsList = provisions.edges
        .map((element: any) => element.node)
        .map((element: any) => {
          return { ...element, name: element.name };
        });
      state.isLoading = false;
    });
    builder.addCase(listProvisions.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('listProvision API request rejected');
    });

    //checkNodeIntegrity
    builder.addCase(checkNodeIntegrity.pending, state => {
      state.nodeIntegrityData = null;
      state.nodeIntegrityLoader = true;
    });
    builder.addCase(checkNodeIntegrity.fulfilled, (state, action) => {
      const data: NodeIntegrityResult = action.payload.data.checkNodeIntegrity;
      state.nodeIntegrityData = data.result;
      state.nodeIntegrityLoader = false;
      state.search = null;
    });
    builder.addCase(checkNodeIntegrity.rejected, (state, action) => {
      state.nodeIntegrityLoader = false;
    });

    //cleanupUnusedNodes
    builder.addCase(cleanupUnusedNodes.rejected, (state, action) => {
      toast.error('An error occured: Cleanup Unused Nodes failed to load');
      console.error(action.error);
    });

    //checkProvisionUse
    builder.addCase(checkProvisionUse.pending, state => {
      state.provisionUseLoading = true;
    });
    builder.addCase(checkProvisionUse.fulfilled, (state, action) => {
      const data = action.payload.data.checkProvisionUse;
      state.provisionUse = data;
      state.provisionUseLoading = false;
    });
    builder.addCase(checkProvisionUse.rejected, (state, action) => {
      state.provisionUseLoading = false;
      console.error(action.error);
      toast.error('checkProvisionUse API request rejected');
    });

    builder.addCase(listLandlordEntities.fulfilled, (state, action) => {
      state.owners = action.payload.data.listLandlordEntities;
    });
    builder.addCase(listLandlordEntities.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('An error occured: List of Landlord Entities failed to load');
    });

    // getProvisionPdfHtml
    builder.addCase(getProvisionPdfHtml.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getProvisionPdfHtml.fulfilled, (state, action) => {
      const html = action.payload.data.getProvisionPdfHtml;

      const endOfInternalStyle = html.indexOf('</style>');
      const newHtml = html.slice(0, endOfInternalStyle) + 'body{padding: 5rem}' + html.slice(endOfInternalStyle);

      const tab = window.open();
      tab?.document.write(newHtml);
      tab?.document.close();
      state.isLoading = false;
    });
    builder.addCase(getProvisionPdfHtml.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getProvionPdfHtml API request rejected');
    });
  },
});

export const {
  resetActiveCategory,
  resetProvisionsSearch,
  resetProvisionUse,
  setActiveCategory,
  updateActiveCategory,
  updateSearch,
  updateProvisionFilters,
  updateProvisionModalState,
} = provisionsListingSlice.actions;

export default provisionsListingSlice.reducer;
