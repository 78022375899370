import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ContractTimeline } from 'common/api/contracts';
import {
  // getContract,
  // getContractTimeline,
  // getFutureContract,
  getProperty,
  getPropertyEvents,
  getStackingPlanTransactions,
  getStackingPlans,
} from 'common/api/dashboard/stackingPlan';
import {
  Blocks,
  Contract,
  Floor,
  GraphInput,
  PropertyTimeline,
  STACKING_PLAN_VIEW_OPTIONS,
  TransactionProps,
} from 'utils/types/stackingPlan';
import { filterUnitsWithMonitoringDate } from 'utils/utils-property';

interface StackingPlanStateTypes {
  activeView: STACKING_PLAN_VIEW_OPTIONS;
  activeProperty: null | { id: string };
  isLoading: boolean;
  isContractLoading: boolean;
  totalFloor: number;
  buildingNumberOfUndergroundFloors: number;
  floors: Floor[];
  floorsWithSpaces: Floor[];
  blocks: Blocks[];
  activeSideBar: number;
  selectedFloor: {
    floorId: string | null;
    floorName: string;
    floorNumber: number;
    floorArea: number;
    contractId: string | null;
    futureContractIds: null | string[];
  };
  contracts: Contract[];
  selectedContract: Contract | null;
  selectedFutureContracts: Contract[];
  monitoringDate: string;
  graphData: GraphInput[];
  rawGraphData: GraphInput[];
  contractsTimeline: ContractTimeline[];
  selectedContractTimeline: ContractTimeline | null;
  selectedPropertyTimeline: PropertyTimeline | null;
  onGoingTransactions: TransactionProps[];
}

const initialState: StackingPlanStateTypes = {
  activeView: STACKING_PLAN_VIEW_OPTIONS.StackingPlan,
  activeProperty: null,
  isLoading: false,
  isContractLoading: false,
  totalFloor: 0,
  buildingNumberOfUndergroundFloors: 0,
  floors: [],
  floorsWithSpaces: [],
  blocks: [],
  activeSideBar: 0,
  selectedFloor: {
    floorId: null,
    floorName: '',
    floorNumber: 0,
    floorArea: 0,
    contractId: null,
    futureContractIds: null,
  },
  contracts: [],
  selectedContract: null,
  selectedFutureContracts: [],
  monitoringDate: moment().format('YYYY-MM-DD'),
  graphData: [],
  rawGraphData: [],
  contractsTimeline: [],
  selectedContractTimeline: null,
  selectedPropertyTimeline: null,
  onGoingTransactions: [],
};

const filterDuplicate = (contract: Contract, contracts: Contract[]) => {
  if (contract) {
    const index = contracts.findIndex(({ id }: Contract) => id === contract.id);
    if (index === -1) {
      contracts.push(contract);
    } else {
      contracts[index] = contract;
    }
  }
  return contracts;
};

const stackingPlanSlice = createSlice({
  name: 'stackingPlan',
  initialState,
  reducers: {
    selectedProperty: (state, action) => {
      state.activeProperty = action.payload.property;
    },
    updateSideBar: (state, action) => {
      state.activeSideBar = action.payload.sideBar;
    },
    updateSelectedFloor: (state, action) => {
      state.selectedFloor = action.payload.floor;
    },
    updateSelectedContract: (state, action) => {
      state.selectedContract = action.payload.contract;
    },
    updateGraphData: (state, action) => {
      state.graphData = action.payload.graphData;
    },
    updateRowGraphData: (state, action) => {
      state.graphData = action.payload.graphData;
      state.rawGraphData = action.payload.graphData;
    },
    updateSelectedContractTimeline: (state, action) => {
      state.selectedContractTimeline = action.payload.contractTimeline;
    },
    updateView: (state, action) => {
      state.activeView = action.payload.view;
    },
    updateMonitoringDate: (state, action) => {
      state.monitoringDate = action.payload.monitoringDate;
    },
    updateSelectedFutureContract: (state, action) => {
      state.selectedFutureContracts = action.payload.contracts;
    },
  },
  extraReducers: builder => {
    builder.addCase(getStackingPlans.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getStackingPlans.fulfilled, (state, action) => {
      const { floors = [], floorsNumber, buildingNumberOfUndergroundFloors, blocks } = action.payload.getStackingPlan2;
      state.floors = floors;
      state.totalFloor = floorsNumber;
      state.buildingNumberOfUndergroundFloors = buildingNumberOfUndergroundFloors;
      state.blocks = blocks;
      state.isLoading = false;
    });
    builder.addCase(getStackingPlans.rejected, (state, action) => {
      state.isLoading = false;
      toast.error('Some Error Occurred');
    });
    builder.addCase(getProperty.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(getProperty.fulfilled, (state, action) => {
      state.floorsWithSpaces = filterUnitsWithMonitoringDate(action.payload.getProperty.floors, state.monitoringDate);
      state.isLoading = false;
    });
    builder.addCase(getProperty.rejected, (state, action) => {
      state.isLoading = false;
      console.error(action.error);
      toast.error('getProperty API request rejected');
    });
    // builder.addCase(getContract.pending, state => {
    //   state.isContractLoading = true;
    // });
    // builder.addCase(getContract.fulfilled, (state, action) => {
    //   const contract = action.payload?.getContract ?? null
    //   state.contracts = filterDuplicate(contract, state.contracts);
    //   state.selectedContract = contract;
    //   state.isContractLoading = false;
    // });
    // builder.addCase(getContract.rejected, (state, action) => {
    //   state.isContractLoading = false;
    //   console.error(action.error);
    //   toast.error('getContract API request rejected');
    // });
    // builder.addCase(getContractTimeline.fulfilled, (state, action) => {
    //   const timeline: ContractTimeline = action.payload.generateContractTimeline;
    //   const index = state.contractsTimeline.findIndex(
    //     ({ contractId }: ContractTimeline) => contractId === timeline.contractId,
    //   );
    //   if (index === -1) {
    //     state.contractsTimeline.push(timeline);
    //   }
    //   state.selectedContractTimeline = timeline;
    // });
    // builder.addCase(getContractTimeline.rejected, (_state, action) => {
    //   console.error(action.error);
    //   toast.error('getContractTimeline API request rejected');
    // });
    builder.addCase(getPropertyEvents.fulfilled, (state, action) => {
      state.selectedPropertyTimeline = action.payload.getPropertyEvents;
    });
    builder.addCase(getPropertyEvents.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('getPropertyEvents API request rejected');
    });

    // builder.addCase(getFutureContract.fulfilled, (state, action) => {
    //   const contract = action.payload?.getContract ?? null
    //   state.contracts = filterDuplicate(contract, state.contracts);
    //   if(contract) {
    //     state.selectedFutureContracts.push(contract);
    //   }
    // });
    // builder.addCase(getFutureContract.rejected, (_state, action) => {
    //   console.error(action.error);
    //   toast.error('getFutureContract API request rejected');
    // });

    builder.addCase(getStackingPlanTransactions.fulfilled, (state, action) => {
      state.onGoingTransactions = action.payload.getStackingPlanTransactions.transactions || [];
    });
    builder.addCase(getStackingPlanTransactions.rejected, (_state, action) => {
      console.error(action.error);
      toast.error('getStackingPlanTransactions API request rejected');
    });
  },
});

export const {
  selectedProperty,
  updateSideBar,
  updateSelectedFloor,
  updateGraphData,
  updateRowGraphData,
  updateSelectedContractTimeline,
  updateView,
  updateSelectedContract,
  updateMonitoringDate,
  updateSelectedFutureContract,
} = stackingPlanSlice.actions;

export default stackingPlanSlice.reducer;
