import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { GuidelineClone } from 'common/_classes';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import Parameter from 'common/model/Parameter';
import ParameterTable from 'common/model/ParameterTable';
import { listContextParameters, listContextParametersGroup } from 'common/api/parameters';

export interface ParametersTabState {
  parametersCollection: Parameter[];
  parameterTablesCollection: ParameterTable[];
  parametersCollectionOld: Parameter[];
  parameterTablesCollectionOld: ParameterTable[];
  groupName: string;
  activeGroupId: string | null;
  groupGuideline: GuidelineClone | null;
  allParameters: Parameter[];
  // TO BE IMPROVED
  openedGroups: string[];
  openedSubgroups: string[];
}

const initialState: ParametersTabState = {
  parametersCollection: [],
  parameterTablesCollection: [],
  parametersCollectionOld: [],
  parameterTablesCollectionOld: [],
  groupName: '',
  activeGroupId: null,
  groupGuideline: null,
  allParameters: [],
  openedGroups: [],
  openedSubgroups: [],
};

const parametersTabSlice = createSlice({
  name: 'parametersTab',
  initialState,
  reducers: {
    receiveGroupGuideline: (state, action: PayloadAction<any>) => {
      const { guideline } = action.payload;
      state.groupGuideline = guideline;
    },
    updateGroupName: (state, action: PayloadAction<any>) => {
      const { groupId, groupName } = action.payload;
      state.activeGroupId = groupId;
      state.groupName = groupName;
    },
    updateGroupsAccordion: (state, action) => {
      if (action.payload.add) {
        state.openedGroups = [...state.openedGroups, action.payload.groupId];
      } else {
        state.openedGroups = state.openedGroups.filter(id => id !== action.payload.groupId);
      }
    },
    updateSubGroupsAccordion: (state, action) => {
      if (action.payload.add) {
        state.openedSubgroups = [...state.openedSubgroups, action.payload.group];
      } else {
        state.openedSubgroups = state.openedSubgroups.filter(id => id !== action.payload.group);
      }
    },
    resetGroupsAccordion: state => {
      state.openedGroups = [];
      state.openedSubgroups = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(listContextParameters.pending, state => {
      state.parametersCollection = [];
      state.parameterTablesCollection = [];
    });
    builder.addCase(listContextParameters.fulfilled, (state, action) => {
      const { parameters, parameterTables } = action.payload?.listContextParameters || {};
      state.parametersCollection = parameters || [];
      state.parameterTablesCollection = parameterTables || [];
      if (action.payload.activeGroupIds.length === 0) {
        state.allParameters = parameters;
      }
    });
    builder.addCase(listContextParameters.rejected, (_state, action) => {
      toast.error('An error occurred when loading the Parameters');
    });

    builder.addCase(listContextParametersGroup.fulfilled, (state, action) => {
      const { parameters, parameterTables } = action.payload?.listContextParametersGroup || {};
      // Update new context parameters list
      state.parametersCollection = parameters || [];
      state.parameterTablesCollection = parameterTables || [];
    });
    builder.addCase(listContextParametersGroup.rejected, (_state, action) => {
      console.error('listContextParametersGroup rejected: ', action.error);
      toast.error('An error occurred when loading the Parameters');
    });
  },
});

export const {
  updateGroupName,
  receiveGroupGuideline,
  updateGroupsAccordion,
  updateSubGroupsAccordion,
  resetGroupsAccordion,
} = parametersTabSlice.actions;

/* Selectors */
export const selectParametersTabCollection = (state: RootState) => state.parametersTab.parametersCollection;

export default parametersTabSlice.reducer;
