import { Accordion, Grid, Icon } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CardElement } from 'components/CardParameters';
import GuideLineBoxes from 'components/GuideLineBoxes';
import { updateGroupName, updateGroupsAccordion } from 'store/parametersTab/parametersTabSlice';
import Parameter from 'common/model/Parameter';
import { AnswerProps } from 'common/api/policies';
import { ParameterCount, getParameterCount } from 'utils/utils-answer';
import CountBox from './CountBox';
import ParameterSubGroupsBox, { SubGroup } from './ParameterSubGroupsBox';
import './ParameterGroupsBox.scss';

const ParameterGroupsBox = ({
  groupElement,
  index,
  answers,
}: {
  groupElement: CardElement;
  index: number;
  answers: AnswerProps[];
}) => {
  const dispatch = useAppDispatch();
  const { openedGroups } = useAppSelector((state: RootState) => state.parametersTab);
  const isActive = openedGroups.find((groupId: string) => groupId === groupElement.id) !== undefined;

  const groupBySubGroup = (data: Parameter[]) => {
    const groupedData = data.reduce((acc: any, item: Parameter) => {
      const subGroupName = item.parameterSubGroup?.name || 'No Sub Group'; // Assign "Default" if missing

      if (!acc[subGroupName]) {
        acc[subGroupName] = [];
      }
      acc[subGroupName].push(item);

      return acc;
    }, {});

    // Convert grouped object to an array and sort it
    return Object.entries(groupedData)
      .sort(([subGroupA], [subGroupB]) => (subGroupA === 'No Sub Group' ? 1 : subGroupB === 'No Sub Group' ? -1 : 0))
      .map(([subGroup, parameters]) => ({ subGroup, parameters }));
  };

  // Group the merged data by subGroup
  const groupedParametersListBySubGroup = groupBySubGroup(groupElement.parametersListByGroup);

  const groupOpen = () => {
    dispatch(updateGroupName({ groupId: groupElement.id, groupName: groupElement.name }));
  };

  const totalUnansweredParameters = (groupElement: CardElement): number => {
    const parameterCount: ParameterCount = getParameterCount(groupElement.parametersListByGroup, answers);
    return parameterCount.totalQuestions - parameterCount.definedAnswers;
  };

  const unansweredCount = totalUnansweredParameters(groupElement);
  const countZero = unansweredCount === 0;

  return (
    <Accordion
      id={groupElement.id}
      className={`parameter-groups-box custom-accordion p-s m-l-s ${countZero && 'count-zero'}`}
      onClick={groupOpen}
    >
      <Accordion.Title
        active={isActive}
        index={index}
        onClick={() => dispatch(updateGroupsAccordion({ add: !isActive, groupId: groupElement.id }))}
      >
        {unansweredCount !== 0 && <CountBox count={unansweredCount} />}
        <Icon name="dropdown" />
        <b>{groupElement.name}</b>
        <GuideLineBoxes
          guideline={groupElement.guideline}
          posRight={true}
          onlyIcon={true}
        />
      </Accordion.Title>
      <Accordion.Content
        className="custom-accordion-content"
        active={isActive}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              {groupedParametersListBySubGroup.map((subGroup, index) => (
                <ParameterSubGroupsBox
                  index={index}
                  subGroup={subGroup as SubGroup}
                  answers={answers}
                  groupId={groupElement.id}
                />
              ))}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion.Content>
    </Accordion>
  );
};

export default ParameterGroupsBox;
