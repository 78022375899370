import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import TabContainer from 'components/TabContainer';
import { FORECAST_MODE } from 'store/dashboard/forecasts2Slice';
import { listLandlordTransactions } from 'common/api/transactions';
import { ForecastsHeader } from './ForecastHeader';
import { ForecastList } from './ForecastList';
import { ForecastsEditor } from './ForecastsEditor';

// import { listContracts2 } from 'common/api/contracts2';

const Forecasts: React.FC = () => {
  const dispatch = useDispatch();
  const { forecastMode } = useAppSelector((state: RootState) => state.forecasts2);

  useEffect(() => {
    dispatch(listLandlordTransactions({ first: 1000 }));
    //dispatch(listContracts2({ first:1000}));
  }, []);

  return (
    <TabContainer
      className={'forecasts-tab'}
      tabTitle="Forecasts"
    >
      <ForecastsHeader />
      {forecastMode === FORECAST_MODE.CREATE || forecastMode === FORECAST_MODE.READ ? <ForecastsEditor /> : <></>}
      {forecastMode === FORECAST_MODE.LIST ? <ForecastList /> : <></>}
    </TabContainer>
  );
};

export default React.memo(Forecasts);
