import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import TextAreaField from 'atoms/FormField/TextArea';
import 'views/provisions/CreatorViewer/Tabs/GeneralTab/ProvisionGeneralForm/ProvisionGeneralForm.scss';
import { updateActiveTransaction } from 'store/transactions/transactionDetailSlice';
import { MODE_OPTIONS, ModeProps } from 'common/api/miscellaneous';
import './SpecialRemarks.scss';

const SpecialRemarks = ({ mode }: ModeProps): JSX.Element => {
  const {
    activeTransaction: { description },
  } = useAppSelector((state: RootState) => state.transactionDetail);

  const { id: userId } = useAppSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const onChange = (key: string, value: any): void => {
    dispatch(updateActiveTransaction({ key, value, userId: userId }));
  };

  return (
    <TextAreaField
      className="special-remarks-textarea"
      label='Describe any special factors relating to the transaction. E.g. "anchor tenant" or "special tenant"...'
      value={description}
      onChange={onChange}
      fieldKey="description"
      disabled={mode === MODE_OPTIONS.READ}
      dataTest="special-remarks"
    />
  );
};

export default SpecialRemarks;
