import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { NodeInputProps } from 'common/api/nodes';

interface CreateNodeQueryVariables {
  params: NodeInputProps;
}

export const CREATE_NODE = (params: NodeInputProps) => {
  const variables: CreateNodeQueryVariables = {
    params,
  };

  return {
    mutation: gql`
      mutation ($params: NodeCreateInput!) {
        createNode(params: $params) {
          name
          id
        }
      }
    `,
    variables,
  };
};

export const createNode = createAsyncThunk('refactoredEditor/node/createNode', async (args: NodeInputProps) => {
  const response = await adminClient.mutate(CREATE_NODE(args));
  return response.data;
});
