import FloorStatus2Input from 'common/model/FloorStatus2Input';
import Premise2 from 'common/model/Premise2';
import Premise2Input from 'common/model/Premise2Input';

export class CPremise implements Premise2Input {
  propertyId: string;
  floors: FloorStatus2Input[];
  floorIds: string[];

  constructor(input: Premise2 | null) {
    this.propertyId = input ? input.property.id : '';
    this.floors = input ? input.floors : [];
    this.floorIds = input ? input.floors.map(f => f.floorId) : [];
  }

  static fragment() {
    return `
        floors {
            floorId
            spaceIds
            whole
        }
        property {
          id
          buildingName
          floors {
            id
            name
            ceilingHeight
            spaces {
              id
              name
              grossArea
              netArea
              lettableArea
              saleableArea
            }
          }
        }
    `;
  }

  static formatForAPI(premise: CPremise) {
    return {
      propertyId: premise ? premise.propertyId : '',
      floors: premise ? [...premise.floors] : [],
      floorIds: premise ? [...premise.floorIds] : [],
    };
  }
}
