import { ParameterCategoryBase } from './ParameterCategory';

interface IParameterCategoryDetailClone extends Omit<ParameterCategoryBase, 'id' | 'name' | 'index' | 'color'> {
  id?: string;
  name: string | null;
  index: number | null;
  color: string | null;
}

export class ParameterCategoryDetailClone implements IParameterCategoryDetailClone {
  id?: string;
  name: string | null;
  index: number | null;
  color: string | null;

  constructor(input: IParameterCategoryDetailClone) {
    this.id = input.id;
    this.name = input.name;
    this.index = input.index;
    this.color = input.color;
  }

  static fragments(): string {
    return `
      id
      name
      color
      index
    `;
  }

  static init(): ParameterCategoryDetailClone {
    return {
      name: null,
      index: null,
      color: null,
    };
  }
}
