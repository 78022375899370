import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { v4 } from 'uuid';
import { useAppSelector } from 'hooks';
import {
  FORECAST_MODE,
  IActiveForecast,
  initialiseNewForecast,
  setCurrentForecast,
} from 'store/dashboard/forecasts2Slice';
import { createForecast } from 'common/api/dashboard/forecasting/createForecast';
import { generateAssumedTenanciesThunk } from 'common/api/dashboard/forecasting/generateAssumedTenancies';

export const ForecastsHeader = () => {
  const dispatch = useDispatch();
  const { activeForecast, forecastMode, isLoading } = useAppSelector((state: RootState) => state.forecasts2);
  const { activeProperty } = useAppSelector((state: RootState) => state.stackingPlan);
  const onCreateForecast = useCallback(() => {
    dispatch(initialiseNewForecast());
  }, [dispatch]);

  const onGoBack = useCallback(() => {
    dispatch(setCurrentForecast(null));
  }, [dispatch]);

  const onSaveForecast = useCallback(() => {
    const newForecast: IActiveForecast = {
      ...activeForecast,
      id: v4(),
      buildingId: activeProperty?.id ?? '',
      comments: activeForecast?.comments ?? '',
      referenceId: activeForecast?.referenceId ?? '',
      version: activeForecast?.version ?? 0,
      newLeaseRentFree: activeForecast?.newLeaseRentFree ?? 0,
      newLeaseTerm: activeForecast?.newLeaseTerm ?? 0,
      effectiveDowntime: activeForecast?.effectiveDowntime ?? 0,
      renewalRate: activeForecast?.renewalRate ?? 0,
      voidMonths: activeForecast?.voidMonths ?? 0,
      yoyRentGrowth: activeForecast?.yoyRentGrowth ?? 0,
      marketRent: activeForecast?.marketRent ?? 0,
      startDate: activeForecast?.startDate ?? '',
      endDate: activeForecast?.endDate ?? '',
      duration: activeForecast?.duration ?? 0,
      buildingName: activeForecast?.buildingName ?? '',
      userId: activeForecast?.userId ?? '',
    };
    dispatch(createForecast(newForecast));
  }, [activeForecast, activeProperty]);

  return (
    <div className="flex justify-content-between flex-wrap m-b-lg">
      <div className="flex align-items-center justify-content-center">
        {forecastMode != FORECAST_MODE.LIST && (
          <h2>
            <span onClick={onGoBack}>{`Forecast List`}</span>
            {` > ${activeForecast?.referenceId}`}
          </h2>
        )}
      </div>

      <div className="flex align-items-center justify-content-center">
        {forecastMode === FORECAST_MODE.CREATE && (
          <Button onClick={onSaveForecast}>{isLoading ? 'Generating forecasts...' : 'Save'}</Button>
        )}
        {forecastMode === FORECAST_MODE.LIST && <Button onClick={onCreateForecast}>Create Forecast</Button>}
      </div>
    </div>
  );
};
