import Parameter from 'common/model/Parameter';
import MParameterGroup from 'common/model/ParameterGroup';
import ParameterGroup from 'common/model/ParameterGroup';
import { GuidelineClone } from './Guideline';
import { ParameterCategoryBase, TParameterCategoryOnlyId, TParameterCategoryPickName } from './ParameterCategory';

export interface ParameterPickIndex extends Omit<Pick<Parameter, 'id' | 'index'>, 'index'> {
  index: number;
}
export type TParameterGroupOnlyId = Pick<ParameterGroup, 'id'>;
export type TParameterGroupPickName = Pick<ParameterGroup, 'id' | 'name'>;

export class ParameterGroupPickName implements TParameterGroupPickName {
  id: string;
  name: string;

  constructor(input: TParameterGroupPickName) {
    this.id = input.id;
    this.name = input.name;
  }

  static fragments(): string {
    return `
      id
      name
    `;
  }
}

interface IParameterGroupClone
  extends Omit<
    Omit<ParameterGroup, 'insertedAt' | 'updatedAt'>,
    'category' | 'guideline' // 'parameterCount' | 'tableCount' TODO, check why they are not in the API generated model ?
  > {
  category: ParameterCategoryBase;
  guideline?: GuidelineClone;
  parameterCount: number;
  tableCount: number;
}

export class ParameterGroupClone implements IParameterGroupClone {
  id: string;
  name: string;
  index: number;
  category: ParameterCategoryBase;
  guideline?: GuidelineClone;
  parameterCount: number;
  tableCount: number;

  constructor(input: IParameterGroupClone) {
    this.id = input.id;
    this.name = input.name;
    this.index = input.index;
    this.category = input.category;
    this.guideline = input.guideline;
    this.parameterCount = input.parameterCount;
    this.tableCount = input.tableCount;
  }

  static fragments(): string {
    return `
      id
      name
      index
      category {
        id
        name
        color
        index
      }
      guideline {
        parameterGroup {
          id
        }
        explanatoryNotes
        generalGuidance
        internalNotes
        referenceType
        valuationGuidance
      }
      parameterCount
      tableCount
    `;
  }
}

export interface ParameterGroupMainDataClone
  extends Omit<Pick<MParameterGroup, 'id' | 'name' | 'category'>, 'category'> {
  category: TParameterCategoryPickName;
}

interface IParameterGroupDetailClone
  extends Omit<
    Pick<MParameterGroup, 'id' | 'name' | 'index' | 'category' | 'guideline'>,
    'id' | 'name' | 'index' | 'category' | 'guideline'
  > {
  id?: string;
  name: string | null;
  index: number | null;
  category: TParameterCategoryOnlyId | null;
  guideline: GuidelineClone;
}

export class ParameterGroupDetailClone implements IParameterGroupDetailClone {
  id?: string;
  name: string | null;
  index: number | null;
  category: TParameterCategoryOnlyId | null;
  guideline: GuidelineClone;

  constructor(input: IParameterGroupDetailClone) {
    this.id = input.id;
    this.name = input.name;
    this.index = input.index;
    this.category = input.category;
    this.guideline = input.guideline;
  }

  static fragments(): string {
    return `
      id
      name
      index
      category {
        id
      }
      guideline {
        explanatoryNotes
        generalGuidance
        internalNotes
        parameterGroup {
          id
        }
        referenceType
        valuationGuidance
      }
    `;
  }
}
