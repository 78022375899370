import { PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from '..';

export interface CrossRefData {
  provisionId: string;
  docId: string;
  clauseId: string;
}

export interface EditorCrossReferenceStoreState {
  activeCrossRef: CrossRefData | null;
  modalOpen: boolean;
}

export const editorCrossReferenceInitialState: EditorCrossReferenceStoreState = {
  modalOpen: false,
  activeCrossRef: null,
};

interface ReducerAction {
  setIsCrossReferenceModalOpen: (state: EditorState, action: PayloadAction<boolean>) => void;
  setCrossRef: (state: EditorState, action: PayloadAction<CrossRefData | null>) => void;
}

export const crossReferenceReducers: ReducerAction = {
  setIsCrossReferenceModalOpen: (state, action) => {
    state.crossReference.modalOpen = action.payload;
  },
  setCrossRef: (state, action) => {
    state.crossReference.activeCrossRef = action.payload;
  },
};
