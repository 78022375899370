import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { useDispatch } from 'react-redux';
import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import TableReactPrime from 'atoms/TableReactPrime';
import { setCurrentForecast } from 'store/dashboard/forecasts2Slice';
import { forecastColumnConfig } from './forecastColumnConfig';

export const ForecastList = () => {
  const { forecasts, isLoading } = useAppSelector((state: RootState) => state.forecasts2);
  const dispatch = useDispatch();

  const onViewDetails = useCallback(
    (id: string): void => {
      dispatch(setCurrentForecast(id));
    },
    [dispatch],
  );

  return (
    <TableReactPrime
      content={forecasts}
      columnConfig={forecastColumnConfig(onViewDetails)}
    />
  );
};

export default ForecastList;
