import { Parameter } from 'common/missingModels';
import { useAppDispatch } from 'hooks';
import SectionComponent from 'layouts/SectionComponent';
import { updateActiveTranslateToContract } from 'store/translatorsToContract/translatorDetailSlice';
import ParameterField from './ParameterField';

const ParameterSection = ({ parameters, mode }: { parameters: Parameter[]; mode: string }) => {
  const dispatch = useAppDispatch();
  const onChangeParam = (parameterKey: string, id: string) => {
    const updatedParams = parameters.map(param => (param.key === parameterKey ? { ...param, value: id } : param));
    dispatch(updateActiveTranslateToContract({ key: 'parameters', value: updatedParams }));
  };
  return (
    <SectionComponent
      className="m-b-sm"
      title={
        <>
          2. Parameters <span className="required-indicator-title">*</span>
        </>
      }
    >
      <div className="row">
        {parameters.map(parameter => (
          <ParameterField
            parameter={parameter}
            key={parameter.key}
            onChangeParam={onChangeParam}
            mode={mode}
          />
        ))}
      </div>
    </SectionComponent>
  );
};

export default ParameterSection;
