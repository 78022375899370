import { Button, Form, Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import GuidelinesComponent from 'components/Guidelines';
import ModalTemplate from 'components/ModalTemplate';
import {
  resetActiveCategory,
  resetActiveGroup,
  resetActiveSubGroup,
  updateActiveGroupGuideLine,
} from 'store/parameters/parametersListingSlice';
import { MODAL_MODE_OPTIONS, MODE_OPTIONS } from 'common/api/miscellaneous';
import { PARAMETERS_CLASSIFICATION_OPTIONS } from 'common/api/miscellaneous';
import { capitalizeFirstLetter } from 'utils/utils-string';
import CreateButton from './CreateButton';
import CreateForm from './CreateForm';
import './CreateModal.scss';

interface ContentProps {
  setModalStatus: Function;
  type: PARAMETERS_CLASSIFICATION_OPTIONS;
  mode: MODAL_MODE_OPTIONS;
  onExit: Function;
}

const Content = ({ type, mode, setModalStatus, onExit }: ContentProps): JSX.Element => {
  const {
    activeGroup: { guideline },
  } = useAppSelector((state: RootState) => state.parametersListing);

  return (
    <Form>
      <Grid className="pm-none">
        <Grid.Row className="p-none">
          <Grid.Column
            width={16}
            className="pm-none"
          >
            <CreateForm type={type} />
          </Grid.Column>
        </Grid.Row>

        {type === PARAMETERS_CLASSIFICATION_OPTIONS.GROUP && (
          <Grid.Row className="pm-none">
            <Grid.Column
              width={16}
              className="pm-none"
            >
              <h4
                className="section-title guidelines m-b-s"
                data-test="section-title"
              >
                Guidelines
              </h4>
              <GuidelinesComponent
                mode={MODE_OPTIONS.EDIT}
                guidelines={guideline}
                onUpdateGuidelineType={updateActiveGroupGuideLine}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row className="p-none btn-row">
          <Button
            className="cancel-btn"
            data-test="cancel-button"
            onClick={() => onExit()}
          >
            CANCEL
          </Button>
          <CreateButton
            setModalStatus={setModalStatus}
            type={type}
            mode={mode}
          />
        </Grid.Row>
      </Grid>
    </Form>
  );
};

interface CreateModalProps {
  setModalStatus: (isOpen: boolean) => void;
  type: PARAMETERS_CLASSIFICATION_OPTIONS;
  mode: MODAL_MODE_OPTIONS;
  status: boolean;
}

const CreateModal = ({ type, mode, status, setModalStatus }: CreateModalProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const clearInputFields = (): void => {
    if (type === PARAMETERS_CLASSIFICATION_OPTIONS.CATEGORY) {
      dispatch(resetActiveCategory());
    } else if (type === PARAMETERS_CLASSIFICATION_OPTIONS.GROUP) {
      dispatch(resetActiveGroup());
    } else if (type === PARAMETERS_CLASSIFICATION_OPTIONS.SUB_GROUP) {
      dispatch(resetActiveSubGroup());
    }
  };

  const onExit = (): void => {
    clearInputFields();
    setModalStatus(false);
  };

  const modalTitle: string = `${capitalizeFirstLetter(mode)} ${capitalizeFirstLetter(type)}`;

  return (
    <ModalTemplate
      isOpen={status}
      onToggleModalStatus={setModalStatus}
      className={
        type === PARAMETERS_CLASSIFICATION_OPTIONS.GROUP
          ? 'parameters-group'
          : type === PARAMETERS_CLASSIFICATION_OPTIONS.SUB_GROUP
            ? 'parameters-subgroup'
            : 'parameters'
      }
      title={modalTitle}
    >
      <Content
        type={type}
        mode={mode}
        setModalStatus={setModalStatus}
        onExit={onExit}
      />
    </ModalTemplate>
  );
};

export default CreateModal;
