import { IUserClone } from 'common/_classes';
import { ProvisionContentClone, ProvisionContentCloneExtend } from 'common/_classes';
import Decimal from 'decimal.js';
import { MouseEvent as ReactMouseEvent } from 'react';
import { isEqual, sortBy } from 'lodash';
import { InputFieldType } from 'atoms/FormField/Input';
import { OccupancyStatus } from 'components/Graphs/OccupancyVariance/Tooltip';
import { getNewContract } from 'store/contracts/helper';
import AreaMeasurementMethod from 'common/model/AreaMeasurementMethod';
import AreaMeasurementUnit from 'common/model/AreaMeasurementUnit';
import { PropertyAnalysisData, PropertyAreaStats, UnitAnalysisData } from 'common/api/dashboard';
import {
  DocumentTypeClone,
  DropdownProps,
  ReviewStatusClone,
  ReviewStatusMutationType,
} from 'common/api/miscellaneous';
import { PolicyProvisionDetailClone } from 'common/api/policies';
import { TransactionProvisionTemp } from 'common/api/transactions';
import { isNil } from 'utils/tsValidator';
import { addSpaceOrComma } from 'utils/utils-number';
import { ColorNames } from './utils-colors';
import { removeSpaceOrComma } from './utils-number';

export const namesListHK: string[] = ['HK', 'HongKong'];
export const invalidEmailText: string = 'Invalid email format';

export const getNewReviewer = (
  reviewFlag?: boolean,
  id?: string | null | undefined,
  firstName?: string | undefined,
  lastName?: string | undefined,
): IUserClone => ({
  id: reviewFlag && id ? id : null,
  firstName: reviewFlag && firstName ? firstName : null,
  lastName: reviewFlag && lastName ? lastName : null,
});

export const getNewReviewTime = (reviewFlag: boolean): Date | null => (reviewFlag ? new Date(Date.now()) : null);

type GetReviewFlagAndReviewStatusParamType = {
  reviewFlag: boolean;
  reviewStatus: ReviewStatusClone;
  [key: string]: any;
};

export const getReviewFlagAndReviewStatus = (
  activeEntityObject: GetReviewFlagAndReviewStatusParamType,
  frozenEntityObject: GetReviewFlagAndReviewStatusParamType | undefined,
): [boolean, ReviewStatusMutationType] => {
  const { reviewStatus: editedReviewStatus } = activeEntityObject;
  const { reviewFlag: frozenReviewFlag } = frozenEntityObject || {};

  let newReviewFlag = activeEntityObject.reviewFlag;

  let date: Date | string | null = editedReviewStatus.date;

  if (date && typeof date === 'object') {
    date = date.toISOString();
  } else if (date) {
    date = date;
  } else {
    date = null;
  }

  const userId = editedReviewStatus.user ? editedReviewStatus.user.id : null;

  const newReviewStatus: ReviewStatusMutationType = {
    date,
    userId,
  };

  if (frozenEntityObject) {
    const activeCopy = JSON.parse(JSON.stringify(activeEntityObject));
    delete activeCopy.reviewFlag;
    delete activeCopy.reviewStatus;

    const frozenCopy = JSON.parse(JSON.stringify(frozenEntityObject));
    delete frozenCopy.reviewFlag;
    delete frozenCopy.reviewStatus;

    const edited = !checkIfTwoObjectsAreEqual(activeCopy, frozenCopy);

    if (edited && frozenReviewFlag) {
      newReviewStatus.date = null;
      newReviewStatus.userId = null;
      newReviewFlag = false;
    }
  }

  return [newReviewFlag, newReviewStatus];
};

export const getFrozenObjectCopy = (obj: object): Readonly<object> => {
  const replacer = (key: string, value: any) => (value === undefined ? null : value);
  return Object.freeze(JSON.parse(JSON.stringify(obj, replacer)));
};

export const checkIfTwoObjectsAreEqual = (obj1: object, obj2: object): boolean =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

// T - The underlying value type of the Nullable<T> generic type.
type Nullable<T> = T | null | undefined;

export const checkIfNotDefined = (value: Nullable<any>): boolean => {
  return value === null || value === undefined;
};

const checkIfUnsavedData = <T>(oldValue: T, newValue: T): boolean => {
  return !isEqual(oldValue, newValue);
};

export const checkIfUnsavedContract = (activeContract: any, oldContractVersion: any): boolean => {
  const urlPath = window.location.pathname;
  const contractPages = ['/contracts/create', `/contracts/${activeContract.id}/details`];
  if (contractPages.includes(urlPath)) {
    const oldVersion = urlPath === '/contracts/create' ? getNewContract() : oldContractVersion;
    if (checkIfUnsavedData(oldVersion, activeContract)) {
      return true;
    }
  }
  return false;
};

export const checkIfPoliciesPage = (): boolean => window.location.pathname.includes('policies');

/**
 ** Specifically checks for 0 as a valid value,
 ** distinguishing it from other "falsy" values in JavaScript
 ** like '' (empty string), NaN, false, undefined, or null,
 ** which is necessary to preserve '0' as a valid input
 **/
export const handleZeroValue = (numberToCheck: number): number | null => {
  return numberToCheck || numberToCheck === 0 ? numberToCheck : null;
};

export const editorContextMenuForceClose = (): void => {
  const menuQuery = '.tox-tbtn--enabled';
  const buttons = document.querySelectorAll(menuQuery); // Select all enabled buttons

  if (buttons.length > 0) {
    const lastButton = buttons[buttons.length - 1] as HTMLElement; // Get the last one
    lastButton?.click(); // Click the last button if it exists
  }
};

//sortContents sorts the contents in the same order as documentsList and extend it with the name of the document
export const sortContents = (
  contentList: ProvisionContentClone[],
  documentsList: DocumentTypeClone[],
): ProvisionContentCloneExtend[] => {
  const sortedListOfIds = documentsList.map((document: DocumentTypeClone) => document.id);
  const sortedContentList = [...contentList].sort(
    (a: ProvisionContentClone, b: ProvisionContentClone) =>
      sortedListOfIds.indexOf(a.documentTypeId) - sortedListOfIds.indexOf(b.documentTypeId),
  );
  const sortedContent = sortedContentList.map(content => {
    const documentName: string | undefined = documentsList.find(
      document => document.id === content.documentTypeId,
    )?.name;
    return { ...content, documentName };
  });

  return sortedContent;
};

export const getDocumentName = (documentTypeId: string, documentTypesList: DocumentTypeClone[]): string => {
  const documentName = documentTypesList.find((documentType: DocumentTypeClone) => documentType.id === documentTypeId);
  if (documentName) {
    return documentName.name;
  }
  return '';
};

// NOTE: Should we not be using process.env.NODE_ENV instead of window.location.href?
// e.g. export const checkIfLocalEnv = (): boolean => process.env.NODE_ENV === 'development'

export const checkIfLocalEnv = (): boolean => window.location.href.includes('localhost');

export const getCurrencyShow = (revenue: string): boolean => revenue !== 'unavailable';

// Recursive function for environments without structuredClone
export const deepClone = <T>(obj: T): T => {
  if (obj === null || typeof obj !== 'object') {
    return obj; // Return the value if it's not an object
  }

  // Handle File objects directly (preserve them)
  if (obj instanceof File) {
    return obj as T;
  }

  // Handle Date objects (create a new Date instance)
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as T;
  }

  // Handle specific custom classes like Decimal.js
  if (obj instanceof Decimal) {
    return new Decimal(obj) as T; // Return a new Decimal instance with the same value
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map(item => deepClone(item)) as unknown as T;
  }

  // Handle other custom classes
  if (typeof obj === 'object' && obj.constructor && obj.constructor !== Object) {
    // Clone instances of custom classes by calling their constructor and copying properties
    const clonedInstance = new (obj.constructor as { new (...args: any[]): T })();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        (clonedInstance as any)[key] = deepClone((obj as any)[key]);
      }
    }
    return clonedInstance;
  }

  // Handle regular objects
  const clonedObj: any = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      clonedObj[key] = deepClone((obj as any)[key]);
    }
  }

  return clonedObj;
};

export const isProvisionInPolicy = (provisions: PolicyProvisionDetailClone[], id?: string): boolean => {
  if (!id) return false;
  return !!provisions.find((element: PolicyProvisionDetailClone) => element.provision.id === id);
};

export const removeActiveClassFromPreviousDate = (dateProvisionIsShowing: boolean): void => {
  if (dateProvisionIsShowing) {
    const parent: Element = document.getElementsByClassName('react-datepicker__month')[0];

    const element: Element = parent.getElementsByClassName('react-datepicker__day--keyboard-selected')[0];

    element?.classList.remove('react-datepicker__day--keyboard-selected');
    element?.setAttribute('aria-selected', 'false');
    element?.setAttribute('tabindex', '-1');
  }
};

/**
 * It removes the node from array
 * eg. [{node:{}}] => [{}]
 * @param list Array
 * @returns
 */
export const getDetailsFromEdges = <T>(list: { node: T }[]): T[] => {
  return list.map(item => item.node);
};

export const getAmount = (value: string | null | undefined): string => {
  return value && value !== '0.00' ? `${addSpaceOrComma(value, false)}` : '0';
};

export const getVacancyText = (contractualArea: string, forecastArea: string): OccupancyStatus => {
  if (!isNil(forecastArea) && isNil(contractualArea)) return OccupancyStatus.NOT_OCCUPIED;
  return OccupancyStatus.OCCUPIED;
};

export const getColour = (value: string | undefined | null): '' | ColorNames.GREEN | ColorNames.RED => {
  if ([undefined, null, ''].includes(value)) return '';

  const parseValue = parseFloat(value as string);

  if (parseValue === 0) return '';

  return parseValue < 0 ? ColorNames.RED : ColorNames.GREEN;
};

export const getMaxAbsoluteVariance = (data: PropertyAnalysisData[]): number => {
  let max: number = 0;

  for (let i = 0; i < data.length; i++) {
    const statsArea: PropertyAreaStats = data[i].statsArea;

    const {
      occupancyOverPerformance: { variance: oVariance },
      occupancyUnderPerformance: { variance: uVariance },
    } = statsArea || {
      occupancyOverPerformance: {},
      occupancyUnderPerformance: {},
    };

    if (oVariance) {
      const parsedVariance: number = Math.abs(parseFloat(oVariance));
      max = parsedVariance > max ? parsedVariance : max;
    }

    if (uVariance) {
      const parsedVariance: number = Math.abs(parseFloat(uVariance));
      max = parsedVariance > max ? parsedVariance : max;
    }
  }

  return max;
};

export const getBarWidth = (maxVariance: number, variance: string | null | undefined): number => {
  if (maxVariance === 0 || !variance) return 0;
  return (Math.abs(parseFloat(variance)) * 100) / maxVariance;
};

export const preventReload = (
  e: ReactMouseEvent<HTMLAnchorElement | HTMLDivElement | HTMLButtonElement, MouseEvent>,
): void => e.preventDefault();

export const sortByFloorIndexAndUnitName = (data: UnitAnalysisData[]): UnitAnalysisData[] =>
  data.sort((a, b) => a.floorIndex - b.floorIndex || a.unitName.localeCompare(b.unitName));

export const isHTMLElement = (node: Element): node is HTMLElement => node.nodeType === Node.ELEMENT_NODE;

export const checkIfFilterIsPresent = (filterList: string[], filter: string): boolean => filterList.includes(filter);

export const handlePaste = (e: any, onChange: any, key?: any, type: InputFieldType = InputFieldType.TEXT): void => {
  let clipboardData, pastedData;
  // Stop data actually being pasted into div
  e.stopPropagation();
  e.preventDefault();

  // Get pasted data via clipboard API
  clipboardData = e.clipboardData; // || window.clipboardData as any;
  pastedData = clipboardData.getData('Text');

  if (type === InputFieldType.NUMBER) {
    pastedData = removeSpaceOrComma(pastedData);
  }

  //check that pastedData contains only digits
  if (/^\d+$/.test(pastedData)) {
    if (pastedData !== undefined) {
      if (key) {
        onChange(key, pastedData);
      } else {
        onChange(pastedData);
      }
    }
  } else {
    alert('Pasted data contains characters that are not digits');
  }
};

export const getCurrentLandlordEntityId = (): string | null => localStorage.getItem('sericin_selected_entity');

export const getObjectId = (id: string | null | undefined, obj: { id: string } | null | undefined): string | null => {
  if (id) {
    return id;
  }

  if (obj) {
    return obj.id;
  }

  return null;
};

export const dataURLtoFile = (base64Str: string, filename: string): File => {
  const dataurl = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Str}`;
  let data = dataurl.split(','),
    // @ts-ignore
    mime = data[0].match(/:(.*?);/)[1],
    bstr = atob(data[1]),
    size = bstr.length,
    u8arr = new Uint8Array(size);

  while (size--) {
    u8arr[size] = bstr.charCodeAt(size);
  }

  return new File([u8arr], filename, { type: mime });
};

export const autoDownloadExportedFile = (file: File): void => {
  const element = document.createElement('a');
  element.setAttribute('href', URL.createObjectURL(file));
  element.setAttribute('download', file.name);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const conditionalClassName = (
  condition: boolean | null | undefined | string,
  className: string | undefined,
): string => (condition && className ? className : '');

// Gets the content inside the tinymce editor
export const getActiveDocument = (): Document | null => {
  const activeDoc: HTMLIFrameElement | null = document.querySelector('.tox-edit-area__iframe');
  if (activeDoc !== null) {
    const contentDocument = activeDoc.contentDocument;
    if (contentDocument) {
      return contentDocument;
    }

    const contentWindow = activeDoc.contentWindow;
    if (contentWindow !== null) {
      return contentWindow.document;
    }

    return null;
  }
  return null;
};

export const provisionsDropdown = (
  provisions: PolicyProvisionDetailClone[] | TransactionProvisionTemp[],
): DropdownProps[] => {
  const resetProvisionDropdown = {
    key: null,
    text: '--Reset--',
    value: null,
  };
  const provisionsDropdown = provisions.map((item: PolicyProvisionDetailClone | TransactionProvisionTemp) => ({
    key: item.provision?.id,
    text: item.provision?.name,
    value: item.provision?.id,
  }));

  return [resetProvisionDropdown, ...sortBy(provisionsDropdown, 'text')] as DropdownProps[];
};
