import TabContainer from 'components/TabContainer';
import DateSelect from './DateSelect';
import ForecastGraphs from './ForecastGraphs';
import RiskAnalysisGraphs from './RiskAnalysisGraphs';
import StatisticsInfoBar from './StatisticsInfoBar';
import './Dashboard.scss';

const Dashboard = (): JSX.Element => {
  return (
    <TabContainer
      className={'dashboard-tab'}
      tabTitle="Dashboard"
    >
      <div className="flex justify-content-start flex-wrap">
        <div className="flex align-items-center justify-content-center">
          <h2 className="infobar-header m-b-xs">Snapshot</h2>
        </div>
        <div className="flex align-items-center justify-content-center m-l-sm m-t-sm">
          <DateSelect />
        </div>
      </div>
      <StatisticsInfoBar />

      <ForecastGraphs />
      <RiskAnalysisGraphs />
    </TabContainer>
  );
};

export default Dashboard;
