import { EntityPickName, GuidelineClone, ProvisionContentClone } from 'common/_classes';
import MProvision from 'common/model/Provision';
import MUseType from 'common/model/UseType';
import { IParameterOnlyId, ProvisionCategoryOnlyId } from 'common/api/provisions';

export interface IProvisionDetailClone
  extends Omit<
    Omit<MProvision, 'insertedAt' | 'updatedAt'>, //removed
    | 'id'
    | 'language'
    | 'entity'
    | 'jurisdiction'
    | 'nodes' //modified to ?
    | 'name'
    | 'description'
    | 'summary'
    | 'index'
    | 'existenceParameter'
    | 'guideline'
    | 'contents'
    | 'provisionCategory'
    | 'useType' //modified to accept null
  > {
  id?: string;
  name: string | null;
  description: string | null;
  provisionCategory: ProvisionCategoryOnlyId | null;
  summary: string | null;
  index: number | null;
  contents: ProvisionContentClone[];
  guideline: GuidelineClone;
  language?: string | null;
  entityId?: string | null;
  jurisdiction?: string | null;
  useType?: MUseType | null;
  entity?: EntityPickName | null;
  existenceParameter: IParameterOnlyId | null;
  showLinkedParameter: boolean;
}

export class ProvisionDetailClone implements IProvisionDetailClone {
  id?: string;
  name: string | null;
  description: string | null;
  provisionCategory: ProvisionCategoryOnlyId | null;
  summary: string | null;
  index: number | null;
  contents: ProvisionContentClone[];
  guideline: GuidelineClone;
  language?: string | null;
  entityId?: string | null;
  jurisdiction?: string | null;
  useType?: MUseType | null;
  entity?: EntityPickName | null;
  existenceParameter: IParameterOnlyId | null;
  showLinkedParameter: boolean;
  isStructure: boolean;
  restartNumbering: boolean;

  constructor(input: IProvisionDetailClone) {
    this.id = input.id;
    this.name = input.name;
    this.description = input.description;
    this.provisionCategory = input.provisionCategory;
    this.summary = input.summary;
    this.index = input.index;
    this.contents = input.contents;
    this.guideline = input.guideline;
    this.language = input.language;
    this.entityId = input.entityId;
    this.jurisdiction = input.jurisdiction;
    this.useType = input.useType;
    this.entity = input.entity;
    this.existenceParameter = input.existenceParameter;
    this.showLinkedParameter = input.showLinkedParameter;
    this.isStructure = input.isStructure;
    this.restartNumbering = input.restartNumbering;
  }

  static fragments(): string {
    return `
      id
      name
      description
      isStructure
      restartNumbering
      summary
      index
      contents {
        id
        content
        documentTypeId
      }
      provisionCategory {
        id
        name
      }
      guideline {
        explanatoryNotes
        generalGuidance
        internalNotes
        provision {
          id
        }
        referenceType
        valuationGuidance
      }
      language
      entity {
        id
        name
      }
      jurisdiction
      useType
      existenceParameter{
        id
      }
    `;
  }
}
