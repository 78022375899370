import ConditionComparator from 'common/model/ConditionComparator';
import ConditionsOperator from 'common/model/ConditionsOperator';
import ParamRefOffset from 'common/model/ParamRefOffset';
import ParamRefPosition from 'common/model/ParamRefPosition';
import { DROPDOWN_OPTION } from 'utils/UI';

export interface ParamRefLocal {
  tableId: string | null;
  parameterId: string | null;
  index: number | null;
  offset: ParamRefOffset | null;
  position: ParamRefPosition | null;
}

export const PREFIX_CONDITION: DROPDOWN_OPTION[] = [
  { value: ConditionsOperator.And, text: 'AND' },
  { value: ConditionsOperator.Or, text: 'OR' },
].map((e, key: number) => ({ ...e, key }));

export const LOGIC_COMPARISON: DROPDOWN_OPTION[] = [
  { value: ConditionComparator.Equal, text: '==' },
  { value: ConditionComparator.NotEqual, text: '!=' },
  { value: ConditionComparator.GreaterThan, text: '>' },
  { value: ConditionComparator.GreaterThanOrEqual, text: '>=' },
  { value: ConditionComparator.LessThanOrEqual, text: '<=' },
].map((e, key: number) => ({ ...e, key }));

export const LOGIC_COMPARISON_2: DROPDOWN_OPTION[] = [
  { value: ConditionComparator.Equal, text: '==' },
  { value: ConditionComparator.NotEqual, text: '!=' },
].map((e, key: number) => ({ ...e, key }));

export const LOGIC_COMPARISON_3: DROPDOWN_OPTION[] = [
  { value: ConditionComparator.Equal, text: '==' },
  { value: ConditionComparator.NotEqual, text: '!=' },
  { value: ConditionComparator.None, text: 'None' },
  { value: ConditionComparator.Any, text: 'Any' },
  { value: ConditionComparator.All, text: 'All' },
  { value: ConditionComparator.NotAll, text: 'Not all' },
  { value: ConditionComparator.Contains, text: 'Contains' },
  { value: ConditionComparator.DoesNotContain, text: 'Does not contain' },
].map((e, key: number) => ({ ...e, key }));

export const LOGIC_COMPARISON_4: DROPDOWN_OPTION[] = [
  { value: ConditionComparator.IsFirst, text: 'Is first' },
  { value: ConditionComparator.IsNotFirst, text: 'Is not first' },
  { value: ConditionComparator.IsLast, text: 'Is last' },
  { value: ConditionComparator.IsNotLast, text: 'Is not last' },
].map((e, key: number) => ({ ...e, key }));

export const LOGIC_COMPARISON_C_P: DROPDOWN_OPTION[] = [
  { value: ConditionComparator.Zero, text: '0' },
  { value: ConditionComparator.One, text: '1' },
  { value: ConditionComparator.GreaterThanOrEqualOne, text: '>=1' },
  { value: ConditionComparator.GreaterThanOrEqualTwo, text: '>=2' },
].map((e, key: number) => ({ ...e, key }));
