import { PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from '..';

export interface EditorShortcutStoreState {
  isShortcut: boolean;
}

export const editorShortcutInitialState: EditorShortcutStoreState = {
  isShortcut: false,
};

interface ReducerAction {
  setEditorShortcut: (state: EditorState, action: PayloadAction<boolean>) => void;
}

export const shortcutReducers: ReducerAction = {
  setEditorShortcut: (state, action) => {
    state.shortcut.isShortcut = action.payload;
  },
};
