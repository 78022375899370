import { Forecasts } from 'common/_classes/forecastsV2';
import { useAppDispatch } from 'hooks';
import { CustomColumnProps, ViewDetailsProps } from 'atoms/TableReactPrime';
import TableActionButtons, { TableActionButton } from 'atoms/TableReactPrime/TableActionButtons';
import { Icons } from 'utils/utils-icons';

interface ForecastRowActionButtonParams {
  rowId: string;
  onViewDetails: ViewDetailsProps;
}

export const ForecastRowActionButtons = ({ rowId, onViewDetails }: ForecastRowActionButtonParams) => {
  const dispatch = useAppDispatch();

  const onDelete = (id: string) => {
    // dispatch(deleteForecast({ id }));
    console.log('archive me: ', id);
  };

  const buttons: TableActionButton[] = [
    {
      icon: Icons.EyeSolid,
      tooltip: 'View Forecast',
      onClick: () => onViewDetails(rowId),
      dataTest: 'View Details',
    },
    {
      icon: Icons.TrashFill,
      tooltip: 'Delete Forecast',
      onClick: () => onDelete(rowId),
      isPopup: true,
      popupText: 'Do you really want to delete this Forecast?',
      popupConfirmationButton: 'DELETE',
      dataTest: 'delete-button',
    },
  ];

  return <TableActionButtons buttons={buttons} />;
};
export class ForecastsRow {
  id: string;
  referenceId: string;
  buildingName: string;

  constructor(forecast: Forecasts) {
    this.id = forecast.id;
    this.referenceId = forecast.referenceId;
    this.buildingName = forecast.buildingName;
  }
}

export const forecastColumnConfig = (onViewDetails: ViewDetailsProps): CustomColumnProps[] => [
  // {
  //   field: 'id',
  //   header: 'ID',
  //   filter: true,
  //   filterPlaceholder: 'Search by ID',
  //   filterField: 'id',
  //   sortable: true,
  // },
  {
    field: 'referenceId',
    header: 'Reference',
    filter: true,
    filterPlaceholder: 'Search by reference',
    filterField: 'referenceId',
    sortable: true,
  },
  {
    field: 'duration',
    header: 'Forecast Length (years)',
    filter: true,
    filterPlaceholder: 'Search by forecast length',
    filterField: 'duration',
    sortable: true,
  },
  {
    field: 'buildingName',
    header: 'Building Name',
    filter: true,
    filterPlaceholder: 'Search by Building Name',
    filterField: 'buildingName',
    sortable: true,
  },
  {
    field: 'version',
    header: 'Version',
    filter: true,
    filterPlaceholder: 'Search by Version',
    filterField: 'version',
    sortable: true,
  },
  // {
  //     field: 'userId',
  //     header: 'User ID',
  //     filter: true,
  //     filterPlaceholder: 'Search by User ID',
  //     filterField: 'userId',
  //     sortable: true,
  // },
  {
    mandatory: true,
    field: 'options',
    header: 'Actions',
    body: (row: ForecastsRow) => (
      <ForecastRowActionButtons
        rowId={row?.id}
        onViewDetails={onViewDetails}
      />
    ),
    frozen: true,
    alignFrozen: 'right',
  },
];
