import { RootState } from 'store';
import { useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import { ViewProps } from 'common/api/miscellaneous';
import Contract from './Contract';

const Contractv2Details = ({ isCreated }: ViewProps) => {
  const { activeContract, isLoading } = useAppSelector(({ contractDetailV2 }: RootState) => contractDetailV2);
  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Tenancy',
        url: '/tenancy/dashboard',
      },
      {
        title: activeContract?.identifier || '',
      },
    ];

    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageSpinner={isLoading}
      pageHeader={getPageHeaderProps()}
    >
      <Contract isCreated={isCreated} />
    </PageLayout>
  );
};

export default Contractv2Details;
