import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import RevenuePerformanceVsForecastGraph from 'components/Graphs/RevenuePerfomanceVsForecast';
import UpcomingEventsGraph from 'components/GraphsTimeline/UpcomingEventsGraph';
import { DASHBOARD_PAGE_TABS } from 'views/dashboard/DashboardTabConfig';
import AnalysisContext from 'common/model/AnalysisContext';
import Scale from 'common/model/Scale';
import {
  generatePortfolioRevenueAnalysisBase,
  generatePropertiesEvents,
  generatePropertyRevenueAnalysisBase,
} from 'common/api/dashboard/dashboardTab';
import PeriodSelect from './PeriodSelect';

const ForecastGraphs = () => {
  const dispatch = useAppDispatch();

  const { context, activePortfolio } = useAppSelector((state: RootState) => state.dashboard);
  const { activeProperty } = useAppSelector((state: RootState) => state.stackingPlan);

  const {
    propertyRevenueAnalysis,
    scale,
    isLoading,
    portfolioRevenueAnalysis,
    dateFilter: { startDate, endDate },
    propertiesEvents,
    propertyEventsIsLoading,
  } = useAppSelector((state: RootState) => state.dashboardTab);

  useEffect(() => {
    if (context === AnalysisContext.Portfolio) {
      dispatch(generatePortfolioRevenueAnalysisBase());
    } else if (context === AnalysisContext.Property) {
      dispatch(generatePropertyRevenueAnalysisBase());
    }

    dispatch(generatePropertiesEvents());
  }, [dispatch, activeProperty, activePortfolio, startDate, endDate]);

  return (
    <>
      <div className="flex justify-content-start flex-wrap m-t-l m-b-s">
        <div className="flex align-items-center justify-content-center">
          <h2 className="infobar-header m-b-xs">Tenancy Events & Revenue Forecast</h2>
        </div>
        <div className="flex align-items-center justify-content-center m-l-sm m-t-sm">
          <PeriodSelect />
        </div>
      </div>

      <UpcomingEventsGraph
        isLoading={propertyEventsIsLoading}
        propertiesEvents={propertiesEvents}
        period={scale as Scale}
      />

      <RevenuePerformanceVsForecastGraph
        propertyRevenueAnalysis={propertyRevenueAnalysis}
        period={scale as Scale}
        isLoading={isLoading}
        portfolioRevenueAnalysis={portfolioRevenueAnalysis}
        tab={DASHBOARD_PAGE_TABS.DASHBOARD}
        context={context}
      />
    </>
  );
};
export default ForecastGraphs;
