import { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { orderBy } from 'lodash';
import { Icon } from '@iconify/react';
import SelectField from 'atoms/FormField/Select';
import { DropdownProps, MODE_OPTIONS } from 'common/api/miscellaneous';
import { _FLOOR_PORTION_OPTIONS } from 'utils/UI';
import { conditionalClassName, deepClone } from 'utils/tsHelper';
import { Icons } from 'utils/utils-icons';
import { PremisesFormOnChangeFunction } from '..';
import {
  PremiseSelectedProps,
  onAddPremiseFloor,
  onDeletePremiseFloor,
  onUpdatePremiseFloorId,
  onUpdatePremiseFloorPortion,
  onUpdatePremiseSelectedUnits,
} from '../utils-premiseForm';

interface FloorActionPayload {
  floorRowIndex: number;
  premiseIndex: number;
  value?: string;
}

export type FloorOnChangeFunction = (args: FloorActionPayload) => void;

interface FloorRowProps {
  mode: MODE_OPTIONS;
  firstRow: boolean;
  floorRowIndex: number;
  premises: PremiseSelectedProps[];
  haveDeleteButton: boolean;
  propertyId: string;
  premiseIndex: number;
  floorOptions: DropdownProps[];
  getSpaceOptions: () => DropdownProps[];
  onChange: PremisesFormOnChangeFunction;
}

const FloorRow = ({
  mode,
  floorRowIndex,
  haveDeleteButton,
  floorOptions,
  premiseIndex,
  premises,
  onChange,
  getSpaceOptions,
  firstRow,
}: FloorRowProps): JSX.Element => {
  const [spaceOptions, setSpaceOptions] = useState<DropdownProps[]>([]);

  const { id: floorId, floorPortion, spaceIds } = premises[premiseIndex].floorIds[floorRowIndex];

  const onUpdate =
    (updateHandler: Function, checkValueExists: boolean = true): FloorOnChangeFunction =>
    ({ floorRowIndex, premiseIndex, value }) => {
      if (checkValueExists && !value) return;

      const updatedPremises = deepClone(premises);
      const currentFloors = updatedPremises[premiseIndex].floorIds;

      updatedPremises[premiseIndex].floorIds = updateHandler(currentFloors, floorRowIndex, value);

      onChange(updatedPremises);
    };

  const onFloorPortionUpdate = (key: string, value: any): void =>
    onUpdate(onUpdatePremiseFloorPortion)({
      floorRowIndex,
      premiseIndex,
      value,
    });

  const onFloorUnitsUpdate = (key: string, value: any): void =>
    onUpdate(onUpdatePremiseSelectedUnits)({
      floorRowIndex,
      premiseIndex,
      value,
    });

  const onFloorIdUpdate = (key: string, value: any): void =>
    onUpdate(onUpdatePremiseFloorId)({
      floorRowIndex,
      premiseIndex,
      value,
    });

  const onDelete = (): void =>
    onUpdate(
      onDeletePremiseFloor,
      false,
    )({
      floorRowIndex,
      premiseIndex,
    });

  // TODO issue to solve here: do not use key
  const floorOptionsToShow = (options: DropdownProps[]): DropdownProps[] => {
    return options.filter((option: DropdownProps) => {
      return option.key === floorId || !option.selected;
    });
  };

  useEffect(() => {
    // UPDATE UNITS/SPACES OPTIONS AVAILABLE BASED ON THE FLOOR (i.e floorId) SELECTED
    if (floorId) {
      const spaceOptions: DropdownProps[] = getSpaceOptions();
      const options: DropdownProps[] = orderBy(spaceOptions, option => Number(option.text), 'asc');

      setSpaceOptions(options);
    }
  }, [floorId]);

  const onAddFloor = (premiseIndex: number): void => {
    const updatedPremises = deepClone(premises);
    const currentFloors = updatedPremises[premiseIndex].floorIds;

    updatedPremises[premiseIndex].floorIds = onAddPremiseFloor(currentFloors);

    onChange(updatedPremises);
  };

  const disabled = mode === MODE_OPTIONS.READ;
  const required = mode === MODE_OPTIONS.EDIT;

  return (
    <div className={`grid flex-gap-sm premise-floor-row ${conditionalClassName(!firstRow, 'm-t-sm')}`}>
      <div className="col-3 p-none superscript-btn-col">
        {firstRow && required && (
          <Button
            className="superscript-btn color-white bg-blue-very-dark-grayish"
            data-test="add-new-floor-button"
            onClick={() => onAddFloor(premiseIndex)}
          >
            <Icon
              className="color-white m-r-xxs"
              icon={Icons.Add}
            />
            ADD FLOOR
          </Button>
        )}

        <SelectField
          label={firstRow ? 'Floor' : ''}
          placeholder="Select floor"
          fieldKey="floorId"
          value={floorId}
          dataTest="select-floor"
          disabled={disabled}
          options={floorOptionsToShow(floorOptions)}
          onChange={onFloorIdUpdate}
          search={true}
          required={required}
        />
      </div>

      <div className="col-3 p-none">
        <SelectField
          label={firstRow ? 'Whole or part' : ''}
          placeholder="Select whole or part floor"
          dataTest="select-whole-or-part-floor"
          fieldKey="floorPortion"
          value={floorPortion}
          options={_FLOOR_PORTION_OPTIONS}
          disabled={disabled}
          onChange={onFloorPortionUpdate}
          required={required}
        />
      </div>

      <div className="col-3 p-none">
        <SelectField
          label={firstRow ? 'Units/spaces' : ''}
          placeholder="Select spaces / units"
          dataTest="select-units"
          fieldKey="spaceIds"
          value={spaceIds}
          options={spaceOptions}
          disabled={disabled}
          onChange={onFloorUnitsUpdate}
          multiple={true}
          required={required}
        />
      </div>
      {required && haveDeleteButton && (
        <div
          className="col-1 p-none"
          style={{ position: 'relative' }}
        >
          <div
            className="delete-btn bg-gray-very-light-v4"
            data-test="delete-floor-button"
            onClick={onDelete}
            role="button"
            tabIndex={0}
          >
            <Icon
              className="color-red-soft"
              icon={Icons.Delete}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FloorRow;
