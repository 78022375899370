import MGuideline from 'common/model/Guideline';
import MParameter from 'common/model/Parameter';
import MParameterGroup from 'common/model/ParameterGroup';
import MProvision from 'common/model/Provision';
import MReferenceTypes from 'common/model/ReferenceTypes';
import { checkNotEmpty } from 'utils/tsValidator';

export interface IGuidelineClone
  extends Omit<
    Omit<MGuideline, 'insertedAt' | 'updatedAt'>, //removed
    | 'id'
    | 'parameter'
    | 'parameterGroup'
    | 'provision' //modified to ?
    | 'explanatoryNotes'
    | 'internalNotes'
    | 'generalGuidance'
    | 'valuationGuidance'
    | 'referenceType' //modified to accept null
  > {
  id?: string;
  explanatoryNotes: string | null;
  internalNotes: string | null;
  generalGuidance: string | null;
  valuationGuidance: string | null;
  referenceType: MReferenceTypes | null;
  parameter?: MParameter;
  parameterGroup?: MParameterGroup;
  provision?: MProvision;
}

export class GuidelineClone implements IGuidelineClone {
  id?: string;
  explanatoryNotes: string | null;
  internalNotes: string | null;
  generalGuidance: string | null;
  valuationGuidance: string | null;
  referenceType: MReferenceTypes | null;
  parameter?: MParameter;
  parameterGroup?: MParameterGroup;
  provision?: MProvision;

  constructor(input: GuidelineClone) {
    this.id = input.id;
    this.explanatoryNotes = input.explanatoryNotes;
    this.internalNotes = input.internalNotes;
    this.generalGuidance = input.generalGuidance;
    this.valuationGuidance = input.valuationGuidance;
    this.referenceType = input.referenceType;
    this.parameter = input.parameter;
    this.parameterGroup = input.parameterGroup;
    this.provision = input.provision;
  }

  static fragments(): string {
    return `
      id
      parameter {
        id
      }
      parameterGroup {
        id
      }
      provision {
        id
      }
      referenceType
      explanatoryNotes
      generalGuidance
      internalNotes
      valuationGuidance
    `;
  }

  static init(referenceType: MReferenceTypes): GuidelineClone {
    const result: GuidelineClone = new GuidelineClone({
      referenceType,
      parameter: undefined,
      parameterGroup: undefined,
      provision: undefined,
      generalGuidance: null,
      valuationGuidance: null,
      explanatoryNotes: null,
      internalNotes: null,
    });
    return result;
  }
}

type TGuidelinesOnly = Pick<MGuideline, 'generalGuidance' | 'valuationGuidance' | 'explanatoryNotes' | 'internalNotes'>;

export class GuidelinesOnly implements TGuidelinesOnly {
  generalGuidance: string;
  valuationGuidance: string;
  explanatoryNotes: string;
  internalNotes: string;

  constructor(input: TGuidelinesOnly) {
    this.generalGuidance = input.generalGuidance;
    this.valuationGuidance = input.valuationGuidance;
    this.explanatoryNotes = input.explanatoryNotes;
    this.internalNotes = input.internalNotes;
  }

  static checkFilled(guideline: GuidelinesOnly): boolean {
    if (!guideline) {
      return guideline;
    } else {
      return (
        checkNotEmpty(guideline.generalGuidance) ||
        checkNotEmpty(guideline.valuationGuidance) ||
        checkNotEmpty(guideline.explanatoryNotes) ||
        checkNotEmpty(guideline.internalNotes)
      );
    }
  }
}

export interface GuidelinesBase
  extends Omit<
    Pick<MGuideline, 'generalGuidance' | 'valuationGuidance' | 'explanatoryNotes' | 'internalNotes'>,
    'generalGuidance' | 'valuationGuidance' | 'explanatoryNotes' | 'internalNotes'
  > {
  generalGuidance: string | null;
  valuationGuidance: string | null;
  explanatoryNotes: string | null;
  internalNotes: string | null;
}

// TODO this should be moved to the constructor of GuidelineInput
export const convertToGuidelineInput = (guideline: GuidelineClone) /*: GuidelineInput*/ => {
  const result /*: GuidelineInput*/ = {
    referenceType: guideline.referenceType,
    parameterId: guideline.parameter?.id,
    parameterGroupId: guideline.parameterGroup?.id,
    provisionId: guideline.provision?.id,
    generalGuidance: guideline.generalGuidance,
    valuationGuidance: guideline.valuationGuidance,
    explanatoryNotes: guideline.explanatoryNotes,
    internalNotes: guideline.internalNotes,
  };
  return result;
};

interface GuidelinesBaseRef
  extends Omit<
    Pick<MGuideline, 'referenceType' | 'generalGuidance' | 'valuationGuidance' | 'explanatoryNotes' | 'internalNotes'>,
    'generalGuidance' | 'valuationGuidance' | 'explanatoryNotes' | 'internalNotes'
  > {
  generalGuidance: string | null;
  valuationGuidance: string | null;
  explanatoryNotes: string | null;
  internalNotes: string | null;
}

//TODO this should be moved to the constructor of class XxxInput that will use it.
export const extractGuidelineUpdateParams = (guideline: GuidelineClone): GuidelinesBaseRef => {
  const result: GuidelinesBaseRef = {
    referenceType: guideline.referenceType as MReferenceTypes, //should not be null
    generalGuidance: guideline.generalGuidance,
    valuationGuidance: guideline.valuationGuidance,
    explanatoryNotes: guideline.explanatoryNotes,
    internalNotes: guideline.internalNotes,
  };
  return result;
};
