import { GuidelineClone, ParameterChoiceBase } from 'common/_classes';
import { useCallback, useEffect, useState } from 'react';
import { Accordion, Grid, GridColumn } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { debounce, isEqual, sortBy } from 'lodash';
import { Icon } from '@iconify/react';
import Badge, { BadgeColor } from 'atoms/Badge';
import ConfirmPopup from 'atoms/ConfirmPopup';
import ParameterAnswerSwitch from 'components/ParameterAnswerAccordion/ParameterAnswerSwitch';
import { PREVIEW_INFORMATION_TABS_OFFSET } from 'components/PreviewTab/PreviewEditorSideMenu';
import { ParameterModes } from 'store/miscellaneous/miscellaneousSlice';
import AnswerTypes from 'common/model/AnswerTypes';
import ExecuteContext from 'common/model/ExecuteContext';
import Parameter from 'common/model/Parameter';
import ParameterTypes from 'common/model/ParameterTypes';
import { initAnswerValue } from 'common/api/formatters/types';
import { listContextParametersGroup } from 'common/api/parameters';
import { AnswerProps, deletePolicyParamRefAnswers } from 'common/api/policies';
import { checkIfPoliciesPage } from 'utils/tsHelper';
import { checkNotEmpty } from 'utils/tsValidator';
import {
  checkIfFromDeviation,
  checkPolicyInAnswers,
  getAnswerValue,
  getAnswers,
  getLatestAnswer,
  updateAnswer,
} from 'utils/utils-answer';
import { Icons } from 'utils/utils-icons';
import GuideLineBoxes from '../GuideLineBoxes';
import AnswerHistoryModal from '../ParameterAnswerAccordion/AnswerHistoryModal';
import './ParameterAnswerAccordion.scss';

export enum GuidelineLocationTypes {
  RIGHT = 'RIGHT',
  BOTTOM = 'BOTTOM',
}

interface ParameterAnswerAccordionProps {
  parameter: Parameter;
  index: number;
  checkIfAnswer: boolean;
  tableId: string | null;
  tabIndex: number | null;
  guidelineLocation: GuidelineLocationTypes;
}

// Receives the question and displays it inside the accordion
// changes the header color depending on the legend
const ParameterAnswerAccordion = ({
  parameter,
  index,
  checkIfAnswer,
  tableId,
  tabIndex,
  guidelineLocation,
}: ParameterAnswerAccordionProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { activePolicy, activePolicyAnswers } = useAppSelector((state: RootState) => state.policyDetail);
  const { activeTransaction, activeTransactionAnswers } = useAppSelector((state: RootState) => state.transactionDetail);
  const { conditional, parameterMode, selectedProvisionFilter } = useAppSelector(
    (state: RootState) => state.miscellaneous,
  );

  const checkIfDateOrDuration = [
    AnswerTypes.Date,
    AnswerTypes.Duration,
    AnswerTypes.NumberUnit,
    AnswerTypes.NumberPercent,
    AnswerTypes.Number,
  ].includes(parameter.answerType);

  const choices = sortBy(parameter.choices, 'index');

  const answers: AnswerProps[] = getAnswers(activePolicyAnswers, activeTransactionAnswers);
  const answerSize: number = answers.length;

  const { parametersCollection, parameterTablesCollection } = useAppSelector((state: RootState) => state.parametersTab);

  const choiceIds: string[] = choices?.map((choice: ParameterChoiceBase) => choice.id);

  let answersList: AnswerProps[] = answers.filter(
    (obj: AnswerProps) =>
      obj.answerType === parameter.answerType &&
      obj.paramRef.parameterId === parameter.id &&
      obj.paramRef.tableId === tableId &&
      obj.paramRef.index === tabIndex,
  );

  const checkIfFromPolicy: boolean = checkPolicyInAnswers(answersList);
  const checkIfTransaction: boolean = window.location.pathname.includes('transactions');
  const context: ExecuteContext = checkIfTransaction ? ExecuteContext.Transaction : ExecuteContext.Policy;
  const contextId: string | undefined = checkIfTransaction ? activeTransaction.id : activePolicy.id;

  const { activeTab: activePreviewTab } = useAppSelector((state: RootState) => state.hiddenMenu);

  const checkIfDiscussionTab: boolean = activePreviewTab === PREVIEW_INFORMATION_TABS_OFFSET.DISCUSSION;

  const emptyValue = initAnswerValue(parameter.answerType, choiceIds);
  // Stores the value of questions
  const [value, setValue] = useState<any>(emptyValue);

  const onChange = () => {
    dispatch(
      listContextParametersGroup({
        context,
        contextId,
        provisionId: checkIfDiscussionTab ? null : selectedProvisionFilter,
        conditional: checkIfDiscussionTab ? true : conditional,
        mode: checkIfDiscussionTab ? ParameterModes.Detailed : parameterMode,
        parameterGroupId: parameter.parameterGroup?.id,
      }),
    );
  };

  /**
   * Get the latest answers
   */
  const onLatestAnswerUpdate = (): void => {
    const latestAnswerValue = getLatestAnswer({
      answers,
      answerType: parameter.answerType,
      parameterId: parameter.id,
      tableId,
      tabIndex,
      // @ts-ignore
      emptyValue,
    });
    setValue(latestAnswerValue);
  };

  // Call only after 0.5s of no user input activity
  const onChangeRequest = debounce(() => {
    onChange();
  }, 500);

  const debounceOnChange = useCallback(() => onChangeRequest(), []);

  /**
   * On value change update the answer value
   * @param value
   */
  const onAnswerValueUpdate = (value: any): void => {
    updateAnswer({
      value,
      blockRef: tableId ? `${parameter.id}-${tabIndex}` : index,
      answerType: parameter.answerType,
      answerUnit: parameter.unit,
      parameterId: parameter.id,
      tableId,
      tabIndex,
      dispatch,
    });
    if (
      parameter.answerType === AnswerTypes.Duration &&
      checkNotEmpty(value.years) &&
      checkNotEmpty(value.months) &&
      checkNotEmpty(value.days)
    ) {
      debounceOnChange();
    } else if (parameter.answerType !== AnswerTypes.Duration) {
      debounceOnChange();
    }
    setValue(value);
  };

  useEffect(() => {
    onLatestAnswerUpdate();
  }, [tabIndex]);

  useEffect(() => {
    if (tabIndex === 0) {
      onLatestAnswerUpdate();
    }
  }, [answerSize]);

  useEffect(() => {
    onLatestAnswerUpdate();
  }, [parametersCollection, parameterTablesCollection]);

  const ParameterQuestionContent: JSX.Element = ParameterAnswerSwitch(
    value,
    parameter,
    index,
    tableId,
    tabIndex,
    onAnswerValueUpdate,
    onChange,
    answers,
  );

  const handleChildClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.stopPropagation();
  };

  const getShowHistoryButton = (): boolean => {
    if (answersList.length === 1) {
      return answersList[0].blockRef === undefined;
    }

    return answersList.length !== 0;
  };

  const posRight: boolean = guidelineLocation === GuidelineLocationTypes.RIGHT;

  const inventoryBtnText: string = checkNotEmpty(parameter.inventoryFunction) ? 'INVENTORY' : `INVENTORY(" ")`;

  const accordionId: string = `${parameter.id}-${tableId}-${tabIndex}`;

  const onClearHistory = (): void => {
    const clearBtn = document.getElementById(accordionId)?.querySelectorAll('.parameters-questions-clear-data')[0];

    // @ts-ignore
    clearBtn.click();

    const paramRef = { parameterId: parameter.id, tableId, index: tabIndex };
    // Delete all the answers of parameter reference
    dispatch(deletePolicyParamRefAnswers(paramRef));
  };

  const showHistoryBtn: boolean = getShowHistoryButton();

  const imageType: boolean = parameter.answerType === AnswerTypes.Image;

  const getDefaultPolicyAnswer = (): string => {
    const size: number = answersList.length;
    if (size > 0) {
      const firstAnswers = answersList[size - 1].answer;
      const valueOfAnswer = getAnswerValue(firstAnswers, parameter.answerType, choices);
      if (imageType) {
        const { title, url } = valueOfAnswer;
        return `${title}-${url}`;
      }

      return valueOfAnswer;
    }
    return '';
  };

  const checkNotesGuidance = (): boolean => {
    if (!posRight) {
      return false;
    }
    let generalGuidance;
    let valuationGuidance;
    if (parameter.guideline) {
      generalGuidance = checkNotEmpty(parameter.guideline.generalGuidance);
      valuationGuidance = checkNotEmpty(parameter.guideline.valuationGuidance);
    } else {
      return false;
    }
    return !checkNotEmpty(parameter.questionNote) && generalGuidance && valuationGuidance;
  };

  // Check if current answer is different then the old answer
  const compareCurrentAnswerWithOld = (): boolean => {
    // Only if old answer exits
    if (answersList.length > 1) {
      const newAnswer: AnswerProps = answersList[0];
      const oldAnswer: AnswerProps = answersList[1];

      // Check if new answer which yet to be saved exists
      if (newAnswer.blockRef !== undefined) {
        // Check if answers are same or not
        switch (newAnswer.answerType) {
          case AnswerTypes.Number:
          case AnswerTypes.NumberPercent:
          case AnswerTypes.NumberUnit:
            // Ignore the type
            return newAnswer.answer.value !== oldAnswer.answer.value;
          default:
            return !isEqual(newAnswer.answer, oldAnswer.answer);
        }
      }
    }
    return false;
  };

  const valuationGuidanceExists: boolean = parameter.guideline
    ? checkNotEmpty(parameter.guideline.valuationGuidance)
    : false;

  const answerExists = checkIfAnswer === false;

  const getParameterType = () => {
    if (parameter.hasInventory) {
      return { type: inventoryBtnText, color: BadgeColor.PURPLE };
    } else if (parameter.type === ParameterTypes.DetailedAmendment) {
      return { type: 'DETAILED', color: BadgeColor.GRAY };
    } else {
      return { type: parameter.type, color: BadgeColor.BLUE };
    }
  };

  const parameterType = getParameterType();

  return (
    <Accordion
      id={accordionId}
      className={`custom-accordion parameter-questions ${index === 0 ? 'first-entry' : ''}  ${answerExists && 'answer-exists'}`}
    >
      {/* The accordion title is the header of the box.
       * It's background is green when the answer to the parameter is defined and gray when undefined.
       * Alongside the parameter question, it has:
       *  - an 'history' button,
       *  - a 'clear history' button (in policy context only)
       *  - badges to indicate the type of parameter
       */}
      <Accordion.Title
        className="custom-accordion-title"
        active={true}
        index={0}
        data-test="header-title"
      >
        <Grid className="pm-none">
          <Grid.Row className="pm-none">
            <Grid.Column
              width={1}
              className="pm-none p-l-xs"
            >
              <Badge badgeColor={parameterType.color}>
                <span>{parameterType.type}</span>
              </Badge>
            </Grid.Column>
            <Grid.Column
              width={15}
              className="pm-none p-l-xl question-content"
            >
              <span className="question-title">{parameter.question}</span>
              {showHistoryBtn && (
                <span onClick={handleChildClick}>
                  <AnswerHistoryModal
                    answerType={parameter.answerType as AnswerTypes}
                    question={parameter.question}
                    questionNote={parameter.questionNote}
                    answers={answersList.reverse()}
                    choices={choices as ParameterChoiceBase[]}
                    unit={parameter.unit}
                    hasInventory={parameter.hasInventory}
                    inventoryBtnText={inventoryBtnText}
                  />
                </span>
              )}

              {checkIfPoliciesPage() && showHistoryBtn && (
                <ConfirmPopup
                  trigger={
                    <span className="trash-box bg-orange-very-light-grayish">
                      <Icon
                        icon={Icons.Trash}
                        className="clear-answer-history color-red-soft"
                      />
                    </span>
                  }
                  content="Do you really want to clear parameter history?"
                  confirmButtonText="CLEAR"
                  onConfirm={onClearHistory}
                  cancelButtonText="CANCEL"
                />
              )}

              {checkIfFromPolicy && (
                <h4 className="default-policy m-t-xxs m-l-xxs">Policy: {` ${getDefaultPolicyAnswer()}`}</h4>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion.Title>
      <Accordion.Content
        className="custom-accordion-content"
        active={true}
      >
        <Grid>
          <Grid.Row>
            <Grid.Column width={1}></Grid.Column>
            <Grid.Column
              width={7}
              className="content-box p-l-xl"
            >
              <div
                className={`
                  inside-content 
                  ${imageType && 'image-type'}
                  ${checkNotesGuidance() ? 'check-notes-guidance m-t-sm' : ''}
                `}
              >
                {ParameterQuestionContent}
              </div>
              {valuationGuidanceExists && compareCurrentAnswerWithOld() ? (
                <div className="valuation-alert">
                  <Icon
                    className="caution-icon m-r-xs"
                    icon={Icons.Caution}
                  />
                  <span>This parameter may impact rental valuation. Be sure to consider the guidelines.</span>
                </div>
              ) : (
                <></>
              )}
            </Grid.Column>
            <Grid.Column width={8}>
              <GuideLineBoxes
                guideline={parameter.guideline as GuidelineClone | null | undefined}
                posRight={posRight}
                className="m-t-ml"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion.Content>
    </Accordion>
  );
};

export default ParameterAnswerAccordion;
