import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterDetailClone, extractGuidelineUpdateParams } from 'common/_classes';
import { RootState } from 'store';
import { formatChoices } from '.';

interface UpdateParameterQueryVariables {
  parameterId: string;
  params: any;
}

const UPDATE_PARAMETER = ({
  id,
  name,
  type,
  answerType,
  question,
  questionNote,
  unit,
  index,
  internalOriginFlag,
  internalOriginId,
  choices,
  parameterGroup,
  parameterSubGroup,
  guideline,
  hasInventory,
  inventoryFunction,
  mandatoryChoice,
  provisionExistence,
}: ParameterDetailClone) => {
  const groupId = parameterGroup ? parameterGroup.id : null;
  const subGroupId = parameterSubGroup ? parameterSubGroup.id : null;
  const params = {
    name,
    type,
    answerType,
    question,
    questionNote,
    unit,
    index,
    internalOriginFlag,
    internalOriginId,
    hasInventory,
    inventoryFunction,
    mandatoryChoice,
    choices: formatChoices(choices),
    parameterGroupId: groupId,
    parameterSubGroupId: subGroupId,
    guideline: extractGuidelineUpdateParams(guideline),
    provisionExistence,
  };

  if (!id) {
    throw new Error('UPDATE_PARAMETER, id is required');
  }

  const variables: UpdateParameterQueryVariables = {
    parameterId: id,
    params: JSON.parse(JSON.stringify(params)),
  };

  return {
    mutation: gql`
      mutation ($parameterId: ID!, $params: ParameterInput!) {
        updateParameter(parameterId: $parameterId, params: $params) {
          ${ParameterDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const updateParameter = createAsyncThunk('parameters/updateParameter', async (_args, { getState }) => {
  const {
    parameterDetail: { activeParameter },
  }: any = getState() as RootState;
  const response = await adminClient.mutate(UPDATE_PARAMETER(activeParameter));
  return response;
});
