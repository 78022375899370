import { PropertyClone } from 'common/_classes';
import { useEffect, useState } from 'react';
import { DropdownProps, MODE_OPTIONS } from 'common/api/miscellaneous';
import { PremisesFormOnChangeFunction } from '..';
import { PremiseSelectedProps } from '../utils-premiseForm';
import FloorRow from './FloorRow';
import './PremisesSelect.scss';

interface PremisesSelectProps {
  mode: MODE_OPTIONS;
  premiseIndex: number; // TODO : to be removed, should be dealt at the parent level.
  premises: PremiseSelectedProps[];
  onChange: PremisesFormOnChangeFunction;
}

const extractFloorDropdownOptions = (
  { floors }: PropertyClone,
  floorIds: PremiseSelectedProps['floorIds'],
): DropdownProps[] => {
  const selectedIds = floorIds.map(({ id }) => id);
  return floors.map(({ id, name }) => ({
    key: id ? id : '',
    text: name,
    value: id ? id : '',
    selected: selectedIds.includes(id as string),
  }));
};

const extractUnitsDropdownOptions = (floorId: string, property: PropertyClone | null): DropdownProps[] => {
  if (!property) return [];

  const { floors } = property;
  let options: DropdownProps[] = [];

  for (let i = 0; i < floors.length; i++) {
    const { id, spaces } = floors[i];

    if (id === floorId) {
      options = spaces.map(({ id, name }) => ({
        key: id ? id : '',
        text: name ? name : '',
        value: id ? id : '',
      }));
      break;
    }
  }

  return options;
};

const PremisesSelect = ({ mode, premises, premiseIndex, onChange }: PremisesSelectProps): JSX.Element => {
  const [floorOptions, setFloorOptions] = useState<DropdownProps[]>([]);
  const { floorIds, property } = premises[premiseIndex];

  const { id: propertyId } = property || { id: '' };

  const emptyFloors: boolean = floorOptions.length === 0;

  useEffect(() => {
    // UPDATE FLOOR OPTIONS AVAILABLE BASED ON THE PROPERTY SELECTED
    if (property && property.floors) {
      setFloorOptions(extractFloorDropdownOptions(property, floorIds));
    }
  }, [property, floorIds]);

  return (
    <>
      {property && (
        <>
          {mode === MODE_OPTIONS.EDIT && emptyFloors && (
            <div className="col-9 d-flex-column justify-end">
              <h5>No floors available for this property</h5>
            </div>
          )}
          {!emptyFloors && (
            <div className="col-9 p-none">
              {floorIds.map(({ id }, index: number) => (
                <FloorRow
                  key={index}
                  firstRow={index === 0}
                  floorRowIndex={index}
                  mode={mode}
                  premises={premises}
                  floorOptions={floorOptions}
                  propertyId={propertyId}
                  premiseIndex={premiseIndex}
                  haveDeleteButton={floorIds.length !== 1}
                  getSpaceOptions={() => extractUnitsDropdownOptions(id, property)}
                  onChange={onChange}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PremisesSelect;
