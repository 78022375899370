import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterTableDetailClone } from 'common/_classes';
import { RootState } from 'store';

interface CreateParamTableQueryVariables {
  params: any;
}

export const CREATE_PARAMETER_TABLE = (activeTableParameter: ParameterTableDetailClone) => {
  const params = JSON.parse(JSON.stringify(activeTableParameter));
  delete params.id;
  delete params.parameterGroup;
  delete params.parameterSubGroup;
  delete params.rowNumber;
  params.parameterGroupId = activeTableParameter.parameterGroup ? activeTableParameter.parameterGroup.id : null;
  params.parameterSubGroupId = activeTableParameter.parameterSubGroup
    ? activeTableParameter.parameterSubGroup.id
    : null;
  params.rowNumber = {
    parameterId: activeTableParameter.rowNumber?.parameter?.id,
    tableId: activeTableParameter.rowNumber?.table?.id,
  };

  const variables: CreateParamTableQueryVariables = {
    params,
  };

  return {
    mutation: gql`
      mutation ($params: ParameterTableInput!) {
        createParameterTable(params: $params) {
          ${ParameterTableDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const createParameterTable = createAsyncThunk('parameters/createParameterTable', async (_args, { getState }) => {
  const {
    parameterDetail: { activeTableParameter },
  }: any = getState() as RootState;
  const response = await adminClient.mutate(CREATE_PARAMETER_TABLE(activeTableParameter));
  return response;
});
