import Contact from 'common/model/Contact';
import Document from 'common/model/Document';
import MEntity from 'common/model/Entity';
import EntityActivity from 'common/model/EntityActivity';
import EntityType from 'common/model/EntityType';
import Industry from 'common/model/Industry';
import Property from 'common/model/Property';
import { Network } from './Networks';

export interface IEntityClone
  extends Omit<
    MEntity,
    | 'id' // optional
    | 'activity' // optional
    | 'address'
    | 'addressLocal'
    | 'businessAddress'
    | 'cardId'
    | 'contacts'
    | 'details'
    | 'documents'
    | 'identifier'
    | 'industry'
    | 'insertedAt'
    | 'legalDomicile'
    | 'logo'
    | 'name'
    | 'nickname'
    | 'overseasRegistrationId'
    | 'passportId'
    | 'properties' // Remove the extra space after "properties"
    | 'registrationCountry'
    | 'registrationId'
    | 'residentialAddress'
    | 'type'
    | 'updatedAt'
    | 'landlordNetworks'
  > {
  id: string | null;
  activity: EntityActivity | null;
  address: string | null;
  addressLocal: string | null;
  businessAddress: string | null;
  cardId: string | null;
  details: string | null;
  legalDomicile: string | null;
  logo: string | null;
  name: string | null;
  nickname: string | null;
  overseasRegistrationId: string | null;
  passportId: string | null;
  registrationCountry: string | null;
  registrationId: string | null;
  residentialAddress: string | null;
  type: EntityType | null;
  industry: Industry | null;
  identifier: string | null;
  contacts: Contact[];
  documents: Document[] | null;
  properties: Property[] | null;
  insertedAt: Date | null;
  updatedAt: Date | null;
  landlordNetworks: Network[];
  sharePercentage: string | null;
  parentId: string | null;
  organisation: any; // Add proper type if available
}

export class CEntity implements IEntityClone {
  id: string | null;
  activity: EntityActivity | null;
  address: string | null;
  addressLocal: string | null;
  businessAddress: string | null;
  cardId: string | null;
  details: string | null;
  legalDomicile: string | null;
  logo: string | null;
  name: string | null;
  nickname: string | null;
  overseasRegistrationId: string | null;
  passportId: string | null;
  registrationCountry: string | null;
  registrationId: string | null;
  residentialAddress: string | null;
  type: EntityType | null;
  industry: Industry | null;
  identifier: string | null;
  contacts: Contact[];
  documents: Document[] | null;
  properties: Property[] | null;
  insertedAt: Date | null;
  updatedAt: Date | null;
  landlordNetworks: Network[];
  sharePercentage: string | null;
  parentId: string | null;
  organisation: any; // Add proper type if available

  constructor(entity: IEntityClone) {
    this.id = entity.id;
    this.activity = entity?.activity;
    this.address = entity?.address;
    this.addressLocal = entity?.addressLocal;
    this.businessAddress = entity?.businessAddress;
    this.cardId = entity?.cardId;
    this.details = entity?.details;
    this.legalDomicile = entity?.legalDomicile;
    this.logo = entity?.logo;
    this.name = entity?.name;
    this.nickname = entity?.nickname;
    this.overseasRegistrationId = entity?.overseasRegistrationId;
    this.passportId = entity?.passportId;
    this.registrationCountry = entity?.registrationCountry;
    this.registrationId = entity?.registrationId;
    this.residentialAddress = entity?.residentialAddress;
    this.type = entity?.type;
    this.industry = entity?.industry;
    this.identifier = entity?.identifier;
    this.contacts = entity?.contacts;
    this.documents = entity?.documents;
    this.properties = entity?.properties;
    this.insertedAt = entity?.insertedAt;
    this.updatedAt = entity?.updatedAt;
    this.landlordNetworks = entity?.landlordNetworks;

    if (entity?.landlordNetworks && entity.landlordNetworks.length) {
      this.parentId = entity.landlordNetworks[0].parentId;
      const description = entity.landlordNetworks[0].description;
      this.sharePercentage =
        description && description.length && description[0].sharePercentage
          ? String(description[0].sharePercentage)
          : null;
    } else {
      this.parentId = null;
      this.sharePercentage = null;
    }

    this.organisation = entity?.organisation;
  }

  static init(): IEntityClone {
    const newEntity: IEntityClone = {
      id: null,
      address: null,
      addressLocal: null,
      businessAddress: null,
      cardId: null,
      contacts: [],
      details: null,
      documents: [] as any[],
      industry: null,
      identifier: null,
      legalDomicile: null,
      logo: null,
      name: null,
      nickname: null,
      overseasRegistrationId: null,
      passportId: null,
      registrationCountry: null,
      registrationId: null,
      residentialAddress: null,
      type: null,
      activity: null,
      properties: null,
      insertedAt: null,
      updatedAt: null,
      landlordNetworks: [],
      sharePercentage: null,
      parentId: null,
      organisation: null,
    };
    return new CEntity(newEntity);
  }
}

export default CEntity;
