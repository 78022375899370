import { gql } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { adminClient } from 'apollo';
import { ParameterDetailClone } from 'common/_classes';
import { RootState } from 'store';
import { formatChoices } from '.';

interface CreateParameterQueryVariables {
  params: any;
}

const CREATE_PARAMETER = (activeParameter: ParameterDetailClone) => {
  const activeParameterCopy = JSON.parse(JSON.stringify(activeParameter));
  activeParameterCopy.choices = formatChoices(activeParameterCopy.choices, true);
  delete activeParameterCopy.id;
  delete activeParameterCopy.parameterTableColumns;
  delete activeParameterCopy.parameterGroup;
  delete activeParameterCopy.parameterSubGroup;
  activeParameterCopy.parameterGroupId = activeParameter.parameterGroup ? activeParameter.parameterGroup.id : null;
  activeParameterCopy.parameterSubGroupId = activeParameter.parameterSubGroup
    ? activeParameter.parameterSubGroup.id
    : null;

  const variables: CreateParameterQueryVariables = {
    params: activeParameterCopy,
  };

  return {
    mutation: gql`
      mutation ($params: ParameterInput!) {
        createParameter(params: $params) {
          ${ParameterDetailClone.fragments()}
        }
      }
    `,
    variables,
  };
};

export const createParameter = createAsyncThunk('parameters/createParameter', async (_args, { getState }) => {
  const {
    parameterDetail: { activeParameter },
  }: any = getState() as RootState;
  const response = await adminClient.mutate(CREATE_PARAMETER(activeParameter));
  return response;
});
