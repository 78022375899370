import { ParameterCategoryDetailClone, ParameterGroupDetailClone } from 'common/_classes';
import { ParameterSubGroupDetailClone } from 'common/_classes/ParameterSubGroupDetailClone';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { MODAL_MODE_OPTIONS, PARAMETERS_CLASSIFICATION_OPTIONS } from 'common/api/miscellaneous';
import {
  createParameterCategory,
  createParameterGroup,
  createParameterSubGroup,
  listParameterCategoriesAndGroups,
  updateParameterCategory,
  updateParameterGroup,
  updateParameterSubGroup,
} from 'common/api/parameters';
import { checkNotEmpty } from 'utils/tsValidator';

interface CreateButtonProps {
  setModalStatus: Function;
  type: PARAMETERS_CLASSIFICATION_OPTIONS;
  mode: MODAL_MODE_OPTIONS;
}

const categoryDisabled = ({ name, color, index }: ParameterCategoryDetailClone): boolean => {
  return !checkNotEmpty(name) || !checkNotEmpty(color) || !checkNotEmpty(index) || index === 0 || Number.isNaN(index);
};

const groupDisabled = ({ name, category, index }: ParameterGroupDetailClone): boolean => {
  const categoryId: string | null = category && category.id ? category.id : null;
  return (
    !checkNotEmpty(name) || !checkNotEmpty(categoryId) || !checkNotEmpty(index) || index === 0 || Number.isNaN(index)
  );
};

const subGroupDisabled = ({ name, parameterGroup, index }: ParameterSubGroupDetailClone): boolean => {
  const groupId: string | null = parameterGroup && parameterGroup.id ? parameterGroup.id : null;
  return !checkNotEmpty(name) || !checkNotEmpty(groupId) || !checkNotEmpty(index) || index === 0 || Number.isNaN(index);
};

const CreateButton = ({ setModalStatus, type, mode }: CreateButtonProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeCategory, activeGroup, activeSubGroup } = useAppSelector((state: RootState) => state.parametersListing);

  const onSave = (): void => {
    let mutationMethod;
    if (type === PARAMETERS_CLASSIFICATION_OPTIONS.CATEGORY) {
      mutationMethod = mode === MODAL_MODE_OPTIONS.CREATE ? createParameterCategory : updateParameterCategory;
    } else if (type === PARAMETERS_CLASSIFICATION_OPTIONS.GROUP) {
      mutationMethod = mode === MODAL_MODE_OPTIONS.CREATE ? createParameterGroup : updateParameterGroup;
    } else {
      mutationMethod = mode === MODAL_MODE_OPTIONS.CREATE ? createParameterSubGroup : updateParameterSubGroup;
    }

    dispatch(mutationMethod()).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        dispatch(listParameterCategoriesAndGroups());
      }
    });
    setModalStatus(false);
  };

  return (
    <Button
      className="btn grey-bg invite-user height-2_857"
      disabled={
        type === PARAMETERS_CLASSIFICATION_OPTIONS.CATEGORY
          ? categoryDisabled(activeCategory)
          : type === PARAMETERS_CLASSIFICATION_OPTIONS.GROUP
            ? groupDisabled(activeGroup)
            : subGroupDisabled(activeSubGroup)
      }
      onClick={() => onSave()}
      data-test="save-button"
    >
      {mode === MODAL_MODE_OPTIONS.CREATE ? 'CREATE' : 'UPDATE'}
    </Button>
  );
};

export default CreateButton;
