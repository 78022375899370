import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dropdown, Image, Menu } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icon } from '@iconify/react';
import { updateActiveProfileTab } from 'store/authentication/authSlice';
import { MemberClone } from 'common/api/members';
import { ENVIRONMENT_TYPES } from 'common/api/miscellaneous';
import { getCurrentLandlordEntityId } from 'utils/tsHelper';
import { checkIfAdminURL, checkIfTenantURL } from 'utils/utils-admin';
import { Icons } from 'utils/utils-icons';
import SericinNavbarIcon from 'assets/images/svg/sericin-icon-navbar.svg';
import SettingsMenu from '../SettingsMenu';
import MenuItem from './MenuItem';
import NotificationBell from './NotificationBell';
import ParentMenuItem from './ParentMenuItem';
import './Navbar.scss';

const setupPath = [
  '/properties/',
  '/landlord-entities',
  '/organisation',
  '/benchmark',
  '/users',
  '/entities',
  '/policies',
  '/portfolios',
];

const usersDataPath = ['/policies', '/transactions', '/properties/', '/users', '/entities'];

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { photoUrl, members, environment } = useAppSelector((state: RootState) => state.auth);
  const selectedEntity = getCurrentLandlordEntityId();

  const matchPath = (route: string, exactMatch = false) => {
    const pathName = location.pathname;
    const isMatch = exactMatch ? pathName === route : pathName.includes(route);

    if (isMatch) {
      return true;
    } else {
      return false;
    }
  };

  const matchMultiplePath = (routes: string[]) => {
    return routes.some(route => matchPath(route));
  };

  const moveToProfile = (tab: number) => {
    dispatch(updateActiveProfileTab({ tab }));
    navigate('/profile');
  };

  let isAdmin = false;

  if (members.length !== 0) {
    const memberList: MemberClone[] = members;
    const getIndex = memberList.findIndex((obj: MemberClone) => obj?.entity?.id === selectedEntity);
    if (getIndex !== -1) {
      let userRole = memberList[getIndex].role.name;
      if (userRole === 'Admin') {
        isAdmin = true;
      }
    }
  }

  const adminURL = checkIfAdminURL();
  const isTenant = checkIfTenantURL();
  const role = adminURL ? 'Admin' : isTenant ? 'Tenant' : 'Landlord';

  return (
    <Menu
      size="large"
      fixed="top"
      borderless={true}
      className="navbar-landlord bg-gray-blackish"
    >
      <Menu.Item
        className="navbar-icon"
        onClick={() => navigate('/home')}
      >
        <Image
          data-test="sericin-logo"
          src={SericinNavbarIcon}
        />
      </Menu.Item>
      {environment !== null && environment !== ENVIRONMENT_TYPES.PROD && (
        <Menu.Item className={`navbar-env ${adminURL ? 'admin-env' : ''}`}>
          <div className="env-content color-white bg-red-soft">{environment}</div>
          <div className="env-content color-white bg-green m-l-s">{role}</div>
        </Menu.Item>
      )}
      {selectedEntity !== null || adminURL ? (
        <Menu.Menu position="right">
          {adminURL && (
            <>
              <ParentMenuItem
                name="Parameters"
                url="/parameters/dashboard"
                activeRoutes={matchMultiplePath([
                  '/parameters/create',
                  `/parameters/table/create`,
                  '/parameters/dashboard',
                  `/parameters/${id}/details`,
                  `/parameters/table/${id}/details`,
                ])}
                moveTo={moveTo}
                dataTest="nav-parameters"
              />

              <ParentMenuItem
                name="Provisions"
                url="/provisions/dashboard"
                activeRoutes={matchMultiplePath([
                  '/provisions/create',
                  '/provisions/dashboard',
                  `/provisions/${id}/details`,
                  '/provisions/editor',
                ])}
                moveTo={moveTo}
                dataTest="nav-provisions"
              />

              <ParentMenuItem
                name="Formatters"
                url="/formatters/dashboard"
                activeRoutes={matchMultiplePath([
                  '/formatters/create',
                  '/formatters/dashboard',
                  `/formatters/${id}/details`,
                ])}
                moveTo={moveTo}
                dataTest="nav-formatters"
              />

              <ParentMenuItem
                name="Translators To Contract"
                url="/translate-to-contract/dashboard"
                activeRoutes={matchMultiplePath([
                  '/translate-to-contract/create',
                  '/translate-to-contract/dashboard',
                  `/translate-to-contract/${id}/details`,
                ])}
                moveTo={moveTo}
                dataTest="nav-translate-to-contract"
              />

              <ParentMenuItem
                name="Organisations"
                url="/organisations/dashboard"
                activeRoutes={matchMultiplePath(['/organisations/create', `/organisations/${id}/details`])}
                moveTo={moveTo}
                dataTest="nav-parameters"
              />

              <div
                className="circle-xs active-menu active-menu-position bg-green"
                active={matchMultiplePath(usersDataPath).toString()}
              />
              <Menu.Item settings="true">
                <Dropdown
                  icon={
                    <Icon
                      icon={Icons.ArrowDown}
                      className="icon-dropdown color-gray"
                    />
                  }
                  item={true}
                  text="Users Data"
                  className="setup-dropdown"
                  active={matchMultiplePath(usersDataPath).toString()}
                  data-test="nav-users-data"
                >
                  <Dropdown.Menu>
                    <>
                      <MenuItem
                        name="Policies"
                        linkTo="/policies/dashboard"
                        pathsToMatch={['/policies']}
                        exactMatch={true}
                        dataTest="nav-policies"
                      />

                      <MenuItem
                        name="Transactions"
                        linkTo="/transactions/dashboard"
                        pathsToMatch={['/transactions']}
                        exactMatch={true}
                        dataTest="nav-transactions"
                      />

                      <MenuItem
                        name="Properties"
                        linkTo="/properties/dashboard"
                        pathsToMatch={['/properties']}
                        exactMatch={true}
                        dataTest="nav-properties"
                      />

                      <MenuItem
                        name="Users"
                        linkTo="/users"
                        pathsToMatch={['/users']}
                        exactMatch={true}
                        dataTest="nav-users"
                      />

                      <MenuItem
                        name="Entities"
                        linkTo="/entities"
                        pathsToMatch={['/entities']}
                        exactMatch={true}
                        dataTest="nav-entities"
                      />

                      <MenuItem
                        name="Organisations"
                        linkTo="/organisations/dashboard"
                        pathsToMatch={['/organisations']}
                        exactMatch={true}
                        dataTest="nav-entities"
                      />
                      <MenuItem
                        name="Permissions"
                        linkTo="/permissions/dashboard"
                        pathsToMatch={['/permissions']}
                        exactMatch={true}
                        dataTest="nav-permissions"
                      />
                    </>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>

              <ParentMenuItem
                name="Know-How"
                url="/know-how"
                activeRoutes={matchPath('/know-how')}
                moveTo={moveTo}
                dataTest="nav-know-how"
              />
            </>
          )}
          {isTenant && (
            <ParentMenuItem
              name="Transactions"
              url="/transactions/dashboard"
              activeRoutes={matchMultiplePath([
                '/transactions/create',
                '/transactions/dashboard',
                `/transactions/${id}/details`,
              ])}
              moveTo={moveTo}
              dataTest="nav-transactions"
            />
          )}
          {!adminURL && !isTenant && (
            <>
              <ParentMenuItem
                name="Dashboard"
                url="/dashboard"
                activeRoutes={matchPath('/dashboard', true)}
                moveTo={moveTo}
                dataTest="nav-dashboard"
              />

              <ParentMenuItem
                name="Transactions"
                url="/transactions/dashboard"
                activeRoutes={matchMultiplePath([
                  '/transactions/create',
                  '/transactions/dashboard',
                  `/transactions/${id}/details`,
                ])}
                moveTo={moveTo}
                dataTest="nav-transactions"
              />

              {/* <ParentMenuItem
                name="Contracts"
                url="/contracts/dashboard"
                activeRoutes={matchMultiplePath([
                  '/contracts/create',
                  '/contracts/dashboard',
                  `/contracts/${id}/details`,
                ])}
                moveTo={moveTo}
                dataTest="nav-contracts"
              /> */}

              <ParentMenuItem
                name="Tenancy"
                url="/tenancy/dashboard"
                activeRoutes={matchMultiplePath(['/tenancy/create', '/tenancy/dashboard', `/tenancy/${id}/details`])}
                moveTo={moveTo}
                dataTest="nav-tenancy"
              />

              <ParentMenuItem
                name="Tenants"
                url="/tenant-entities/dashboard"
                activeRoutes={matchPath('/tenant-entities')}
                moveTo={moveTo}
                dataTest="nav-tenants"
              />

              <div
                className="circle-xs active-menu active-menu-position bg-green"
                active={matchMultiplePath(setupPath).toString()}
              />
              <Menu.Item settings="true">
                <Dropdown
                  icon={
                    <Icon
                      icon={Icons.ArrowDown}
                      className="icon-dropdown color-gray"
                    />
                  }
                  item={true}
                  text="Setup"
                  className="setup-dropdown"
                  active={matchMultiplePath(setupPath).toString()}
                  data-test="nav-setup"
                >
                  <Dropdown.Menu>
                    {!adminURL && (
                      <>
                        <MenuItem
                          name="Policies"
                          linkTo="/policies/dashboard"
                          pathsToMatch={['/policies']}
                          exactMatch={true}
                          dataTest="nav-policies"
                        />
                        <MenuItem
                          name="Properties"
                          linkTo="/properties/dashboard"
                          pathsToMatch={['/properties/']}
                          dataTest="nav-properties"
                        />
                        <MenuItem
                          name="Portfolios"
                          linkTo="/portfolios/dashboard"
                          pathsToMatch={['/portfolios']}
                          dataTest="nav-portfolio"
                        />
                        <MenuItem
                          name="Landlord Entities"
                          linkTo="/landlord-entities/dashboard"
                          pathsToMatch={['/landlord-entities']}
                          dataTest="nav-landlords"
                        />
                        <MenuItem
                          name="Organisation"
                          linkTo="/organisation"
                          pathsToMatch={['/organisation']}
                          dataTest="nav-organisation"
                        />
                        <MenuItem
                          name="Benchmarks"
                          linkTo="/benchmarks/dashboard"
                          pathsToMatch={['/benchmarks/dashboard']}
                          dataTest="nav-benchmarks"
                        />
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>

              <ParentMenuItem
                name="Know-How"
                url="/know-how"
                activeRoutes={matchPath('/know-how')}
                moveTo={moveTo}
                dataTest="nav-know-how"
              />
            </>
          )}
        </Menu.Menu>
      ) : (
        <Menu.Menu position="right">
          <MenuItem
            name="Welcome"
            linkTo="/welcome"
            dataTest="welcome"
            pathsToMatch={['/welcome']}
          />
        </Menu.Menu>
      )}

      <Menu.Menu
        position="right"
        className="user-profile"
      >
        <div className="vertical-line border-sm-gray-light-v2"></div>
        {/* Notification  */}
        <NotificationBell />
        <span data-test="user-profile">
          {photoUrl ? (
            <Image
              className="user-profile-pic border-sm-orange bg-white"
              size="medium"
              src={photoUrl}
              circular={true}
              onClick={() => moveToProfile(0)}
            />
          ) : (
            <Icon
              className="user-profile-pic border-sm-orange bg-white"
              icon={Icons.UserOutlined}
              onClick={() => moveToProfile(0)}
            />
          )}
        </span>
        <SettingsMenu
          selectedEntity={selectedEntity}
          isAdmin={isAdmin}
          data-test="nav-settings"
        />
      </Menu.Menu>
    </Menu>
  );
};

export default Navbar;
