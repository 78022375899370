import AnswerTypes from 'common/model/AnswerTypes';
import MParameter from 'common/model/Parameter';
import ParameterChoice from 'common/model/ParameterChoice';
import ParameterTypes from 'common/model/ParameterTypes';
import { GuidelinesOnly } from './Guideline';
import { ParameterGroupMainDataClone } from './ParameterGroupDetailClone';
import { ParameterSubGroupMainDataClone } from './ParameterSubGroupDetailClone';
import { ParameterTableColumnClone } from './ParameterTableDetailClone';

interface IParameterListingClone
  extends Omit<
    Omit<MParameter, 'insertedAt' | 'updatedAt' | 'unit' | 'choices' | 'questionNote'>,
    'index' | 'guideline' | 'parameterGroup' | 'parameterTableColumns' | 'parameterSubGroup'
  > {
  index: number;
  guideline: GuidelinesOnly;
  parameterGroup: ParameterGroupMainDataClone;
  parameterSubGroup: ParameterSubGroupMainDataClone;
  parameterTableColumns: ParameterTableColumnClone[];
  choices?: ParameterChoice[];
}

export class ParameterListingClone implements IParameterListingClone {
  id: string;
  name: string;
  question: string;
  index: number;
  type: ParameterTypes;
  answerType: AnswerTypes;
  hasInventory: boolean;
  inventoryFunction: string;
  provisionExistence: boolean;
  internalOriginFlag: boolean;
  internalOriginId: string;
  mandatoryChoice: boolean;
  guideline: GuidelinesOnly;
  parameterGroup: ParameterGroupMainDataClone;
  parameterSubGroup: ParameterSubGroupMainDataClone;
  parameterTableColumns: ParameterTableColumnClone[];
  choices?: ParameterChoice[];

  constructor(input: IParameterListingClone) {
    this.id = input.id;
    this.name = input.name;
    this.question = input.question;
    this.index = input.index;
    this.type = input.type;
    this.answerType = input.answerType;
    this.hasInventory = input.hasInventory;
    this.inventoryFunction = input.inventoryFunction;
    this.provisionExistence = input.provisionExistence;
    this.internalOriginFlag = input.internalOriginFlag;
    this.internalOriginId = input.internalOriginId;
    this.mandatoryChoice = input.mandatoryChoice;
    this.guideline = input.guideline;
    this.parameterGroup = input.parameterGroup;
    this.parameterSubGroup = input.parameterSubGroup;
    this.parameterTableColumns = input.parameterTableColumns;
    this.choices = input.choices;
  }

  static fragments(choices?: boolean): string {
    const choiceOptions = choices
      ? `choices {
          id
          text
          index
          default
          archive
        }`
      : '';

    return `
      id
      name
      question
      index
      type
      answerType
      hasInventory
      inventoryFunction
      provisionExistence
      internalOriginFlag
      internalOriginId
      mandatoryChoice
      guideline {
        generalGuidance
        valuationGuidance
        explanatoryNotes
        internalNotes
      }
      parameterGroup {
        id
        name
        category {
          id
          name
        }
      }
      parameterSubGroup {
        id
        name
      }
      parameterTableColumns {
        table {
          id
          name
        }
      }
      ${choiceOptions}
    `;
  }
}
