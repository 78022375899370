import { FLOOR_PORTION_OPTIONS } from 'components/PremisesForm/utils-premiseForm';
import Action from 'common/model/Action';
import ApprovalLevel from 'common/model/ApprovalLevel';
import AreaMeasurementMethod from 'common/model/AreaMeasurementMethod';
import AreaMeasurementUnit from 'common/model/AreaMeasurementUnit';
import BuildingGrade from 'common/model/BuildingGrade';
import CharacteristicsTypes from 'common/model/CharacteristicsTypes';
import ContractSecurityComponents from 'common/model/ContractSecurityComponents';
import ContractSecurityType from 'common/model/ContractSecurityType';
import ContractTerminationReason from 'common/model/ContractTerminationReason';
import EntityType from 'common/model/EntityType';
import ExistingFitOutTreatment from 'common/model/ExistingFitOutTreatment';
import ExpansionRightRentAssessmentType from 'common/model/ExpansionRightRentAssessmentType';
import HandoverCondition from 'common/model/HandoverCondition';
import LandlordApprovalType from 'common/model/LandlordApprovalType';
import LeaseType from 'common/model/LeaseType';
import ManagementChargeMonthlyAcFeesPayable from 'common/model/ManagementChargeMonthlyAcFeesPayable';
import OutOfHoursAcChargesPayable from 'common/model/OutOfHoursAcChargesPayable';
import PaidBy from 'common/model/PaidBy';
import PermittedUse from 'common/model/PermittedUse';
import PropertyType from 'common/model/PropertyType';
import RateType from 'common/model/RateType';
import RefusalRightPriority from 'common/model/RefusalRightPriority';
import RefusalRightRentAssessmentType from 'common/model/RefusalRightRentAssessmentType';
import RentFreePeriodAdditionalFreeItem from 'common/model/RentFreePeriodAdditionalFreeItem';
import RentFreePeriodPayables from 'common/model/RentFreePeriodPayables';
import RentReviewType from 'common/model/RentReviewType';
import Resources from 'common/model/Resources';
import RightRestriction from 'common/model/RightRestriction';
import ShareRightRestriction from 'common/model/ShareRightRestriction';
import SpaceType from 'common/model/SpaceType';
import SpecificUse from 'common/model/SpecificUse';
import UseType from 'common/model/UseType';
import WholeOrPartOptions from 'common/model/WholeOrPartOptions';

export enum TransactionPhaseStatus {
  Setup = 'SETUP',
  Negotiation = 'NEGOTIATION',
  PostCompletion = 'POST_COMPLETION',
  Closed = 'CLOSED',
  Aborted = 'ABORTED',
  Reinstated = 'REINSTATED',
  Null = 'NULL', //this status should never exist
}

export interface DROPDOWN_OPTION {
  key: number;
  text: string;
  value: string | boolean;
}

interface ENTITY_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: EntityType;
}

export const ENTITY_OPTIONS: ENTITY_OPTION[] = [
  { key: 1, text: 'Individual', value: EntityType.Individual },
  { key: 2, text: 'Trustee', value: EntityType.Trustee },
  { key: 3, text: 'Partnership', value: EntityType.Partnership },
  { key: 4, text: 'Company', value: EntityType.Company },
  { key: 5, text: 'Co-op Society', value: EntityType.Coop },
  { key: 6, text: 'Unincorporated Society', value: EntityType.Unincorporated },
];

interface PROPERTY_TYPE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: PropertyType;
}

export const PROPERTY_TYPE_OPTIONS: PROPERTY_TYPE_OPTION[] = [
  { key: 1, text: 'Office', value: PropertyType.Office },
  { key: 2, text: 'Industrial', value: PropertyType.Industrial },
  { key: 3, text: 'Warehouse', value: PropertyType.Warehouse },
  { key: 4, text: 'Retail', value: PropertyType.Retail },
  { key: 5, text: 'R & D', value: PropertyType.RAndD },
  { key: 6, text: 'Residential', value: PropertyType.Residential },
  { key: 7, text: 'F & B', value: PropertyType.FAndB },
  { key: 8, text: 'Parking ', value: PropertyType.Parking },
  { key: 9, text: 'Mixed Use', value: PropertyType.MixedUse },
];

interface BUILDING_GRADE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: BuildingGrade;
}

export const BUILDING_GRADE_OPTIONS: BUILDING_GRADE_OPTION[] = [
  { key: 1, text: 'Prime', value: BuildingGrade.Prime },
  { key: 2, text: 'Grade A', value: BuildingGrade.GradeA },
  { key: 3, text: 'Grade B', value: BuildingGrade.GradeB },
  { key: 4, text: 'Grade C', value: BuildingGrade.GradeC },
  { key: 5, text: 'Other', value: BuildingGrade.Other },
  { key: 6, text: 'First Class Mall', value: BuildingGrade.FirstClassMall },
  { key: 7, text: 'Local Mall', value: BuildingGrade.LocalMall },
];

interface UNIT_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: AreaMeasurementUnit;
}

export const UNIT_OPTIONS: UNIT_OPTION[] = [
  { key: 1, text: 'Square Foot', value: AreaMeasurementUnit.SquareFoot },
  { key: 2, text: 'Square Meter', value: AreaMeasurementUnit.SquareMeter },
];

interface METHOD_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: AreaMeasurementMethod;
}

export const METHOD_OPTIONS: METHOD_OPTION[] = [
  { key: 1, text: 'Gross', value: AreaMeasurementMethod.Gross },
  { key: 2, text: 'Lettable', value: AreaMeasurementMethod.Lettable },
  { key: 3, text: 'Net', value: AreaMeasurementMethod.Net },
  { key: 4, text: 'Saleable', value: AreaMeasurementMethod.Saleable },
];

interface SPACE_TYPE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: SpaceType;
}

export const SPACE_TYPE_OPTIONS: SPACE_TYPE_OPTION[] = [
  { key: 1, text: 'Office Unit', value: SpaceType.Office },
  { key: 2, text: 'Storage Unit', value: SpaceType.Storage },
  { key: 3, text: 'Retail Unit', value: SpaceType.Retail },
  { key: 4, text: 'Industrial Unit', value: SpaceType.Industrial },
  { key: 5, text: 'F&B Unit', value: SpaceType.FAndB },
  { key: 6, text: 'Hallways/Common', value: SpaceType.HallwaysCommon },
  { key: 7, text: 'Toilet', value: SpaceType.Toilet },
  { key: 8, text: 'Staircase', value: SpaceType.Staircase },
  { key: 9, text: 'Technical', value: SpaceType.Technical },
  { key: 10, text: 'Lift', value: SpaceType.Lift },
  { key: 11, text: 'Lift Lobby', value: SpaceType.LiftLobby },
  { key: 12, text: 'Parking', value: SpaceType.Parking },
  { key: 13, text: 'Restroom', value: SpaceType.Restroom },
  { key: 14, text: 'Facilities', value: SpaceType.Facilities },
];

export const _FLOOR_PORTION_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, text: 'Part Floor', value: FLOOR_PORTION_OPTIONS.PART },
  { key: 2, text: 'Whole floor', value: FLOOR_PORTION_OPTIONS.WHOLE },
];

interface CONTRACT_TERMINATION_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: ContractTerminationReason;
}

export const CONTRACT_TERMINATION_OPTIONS: CONTRACT_TERMINATION_OPTION[] = [
  { key: 1, text: 'Insolvency', value: ContractTerminationReason.Insolvency },
  { key: 2, text: 'Renovation', value: ContractTerminationReason.Renovation },
  { key: 3, text: 'Redevelopment', value: ContractTerminationReason.Redevelopment },
  { key: 4, text: 'Surrender', value: ContractTerminationReason.Surrender },
  { key: 5, text: 'Payment Breach', value: ContractTerminationReason.PaymentBreach },
  { key: 6, text: 'Other Breach', value: ContractTerminationReason.OtherBreach },
  { key: 7, text: 'Other', value: ContractTerminationReason.Other },
];

interface RENT_REVIEW_METHOD_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: RentReviewType;
}

export const RENT_REVIEW_METHOD_OPTIONS: RENT_REVIEW_METHOD_OPTION[] = [
  { key: 1, text: 'Open market (higher of OMR or passing rent)', value: RentReviewType.OpenMarketPrOrOmr },
  { key: 2, text: 'Open market (upwards or downwards)', value: RentReviewType.OpenMarketUpDown },
  { key: 3, text: 'Percentage increment', value: RentReviewType.Increment },
  { key: 4, text: 'Fixed rent', value: RentReviewType.New },
];

interface SECURITY_TYPE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: ContractSecurityType;
}
export const SECURITY_TYPE_OPTIONS: SECURITY_TYPE_OPTION[] = [
  { key: 1, text: 'Bank guarantee', value: ContractSecurityType.Bank },
  { key: 2, text: 'Cash deposit', value: ContractSecurityType.Cash },
  { key: 3, text: 'Both', value: ContractSecurityType.Both },
  { key: 4, text: 'None', value: ContractSecurityType.None },
];

interface ADDITIONAL_FEES_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: RentFreePeriodAdditionalFreeItem;
}

export const ADDITIONAL_FEES_OPTIONS: ADDITIONAL_FEES_OPTION[] = [
  { key: 1, text: 'Management fees', value: RentFreePeriodAdditionalFreeItem.ManagementFees },
  { key: 2, text: 'Government rent', value: RentFreePeriodAdditionalFreeItem.GovernmentRent },
  { key: 3, text: 'Government rates', value: RentFreePeriodAdditionalFreeItem.GovernmentRates },
  { key: 4, text: 'AC Charges', value: RentFreePeriodAdditionalFreeItem.AirConCharges },
];

interface TEAM_ROLE_APPROVAL_LEVEL_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: ApprovalLevel;
}

export const TEAM_ROLE_APPROVAL_LEVEL_OPTIONS: TEAM_ROLE_APPROVAL_LEVEL_OPTION[] = [
  { key: 1, text: 'Top', value: ApprovalLevel.Top },
  { key: 2, text: 'Middle', value: ApprovalLevel.Middle },
  { key: 3, text: 'Low', value: ApprovalLevel.Low },
  { key: 4, text: 'None', value: ApprovalLevel.None },
];

export const USER_TYPES_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, text: 'Super Admin', value: 'Super Admin' },
  { key: 2, text: 'User', value: 'User' },
];

interface CHARACTERISTIC_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: CharacteristicsTypes;
}

export const CHARACTERISTICS_OPTIONS: CHARACTERISTIC_OPTION[] = [
  { key: 1, text: 'CITY VIEW', value: CharacteristicsTypes.CityView },
  { key: 2, text: 'SEA VIEW', value: CharacteristicsTypes.SeaView },
  { key: 3, text: 'MOUNTAIN VIEW', value: CharacteristicsTypes.MountainView },
  { key: 4, text: 'PARTIAL CITY VIEW', value: CharacteristicsTypes.PartialCityView },
  { key: 5, text: 'PARTIAL SEA VIEW', value: CharacteristicsTypes.PartialSeaView },
  { key: 6, text: 'PARTIAL MOUNTAIN VIEW', value: CharacteristicsTypes.PartialMountainView },
  { key: 7, text: 'NO VIEW', value: CharacteristicsTypes.NoView },
  { key: 8, text: 'SUSPENDED CEILING', value: CharacteristicsTypes.SuspendingCeiling },
  { key: 9, text: 'RAISED FLOOR', value: CharacteristicsTypes.RaisedFloor },
];

interface USE_TYPE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: UseType;
}

export const USE_TYPE_OPTIONS: USE_TYPE_OPTION[] = [
  { key: 1, text: 'Residential', value: UseType.Residential },
  { key: 2, text: 'Office', value: UseType.Office },
  { key: 3, text: 'Retail', value: UseType.Retail },
  { key: 4, text: 'Industrial', value: UseType.Industrial },
];

interface HANDOVER_CONDITION_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: HandoverCondition;
}
export const HANDOVER_CONDITION_OPTIONS: HANDOVER_CONDITION_OPTION[] = [
  { key: 1, value: HandoverCondition.Standard, text: 'Standard' },
  { key: 2, value: HandoverCondition.FullyOrPartiallyFitted, text: 'Fully or partially fitted' },
];

export const EXISTING_FITOUT_TREATMENT_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: ExistingFitOutTreatment.Retained, text: 'Retained' },
  { key: 2, value: ExistingFitOutTreatment.Removed, text: 'Removed' },
];

export const RATE_TYPE_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: RateType.Discount, text: 'Discount' },
  { key: 2, value: RateType.Fixed, text: 'Fixed' },
  { key: 3, value: RateType.Standard, text: 'Standard' },
];

export const CONTRACT_SECURITY_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: ContractSecurityComponents.Rent, text: 'Rent' },
  { key: 2, value: ContractSecurityComponents.ManagementCharges, text: 'Management charges' },
  { key: 3, value: ContractSecurityComponents.GovernmentRates, text: 'Government rates' },
  { key: 4, value: ContractSecurityComponents.GovernmentRent, text: 'Government rent' },
];

export const PAID_BY_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: PaidBy.Landlord, text: 'Landlord' },
  { key: 2, value: PaidBy.Tenant, text: 'Tenant' },
];

interface PERMITTED_USE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: PermittedUse;
}

export const PERMITTED_USE_OPTIONS: PERMITTED_USE_OPTION[] = [
  { key: 1, value: PermittedUse.Office, text: 'Office' },
  { key: 2, value: PermittedUse.Retail, text: 'Retail' },
  { key: 3, value: PermittedUse.Industrial, text: 'Industrial' },
];

interface SPECIFIC_USE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: SpecificUse;
}

export const SPECIFIC_USE_OPTIONS: SPECIFIC_USE_OPTION[] = [
  { key: 1, value: SpecificUse.None, text: 'No specific use' },
  { key: 2, value: SpecificUse.MedicalServices, text: 'Medical services' },
  { key: 3, value: SpecificUse.LawFirm, text: 'Law firm' },
  { key: 4, value: SpecificUse.BarristerChambers, text: 'Barrister chambers' },
  { key: 5, value: SpecificUse.Consulate, text: 'Consulate' },
  { key: 6, value: SpecificUse.EstateAgency, text: 'Estate agency' },
  { key: 7, value: SpecificUse.WatchesAndJewelry, text: 'Watches and jewelry' },
  { key: 8, value: SpecificUse.Fashion, text: 'Fashion' },
  { key: 9, value: SpecificUse.Cosmetics, text: 'Cosmetics' },
  { key: 10, value: SpecificUse.Supermarket, text: 'Supermarket' },
  { key: 11, value: SpecificUse.BureauDeChange, text: 'Bureau de change' },
  { key: 12, value: SpecificUse.TravelAgency, text: 'Travel agency' },
  { key: 13, value: SpecificUse.Bank, text: 'Bank' },
];

export const RENT_FREE_PERIOD_PAYABLES_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: RentFreePeriodPayables.AirConCharges, text: 'Air con charges' },
  { key: 2, value: RentFreePeriodPayables.GovernmentRates, text: 'Government rates' },
  { key: 3, value: RentFreePeriodPayables.GovernmentRent, text: 'Government rent' },
  { key: 4, value: RentFreePeriodPayables.ManagementFees, text: 'Management fees' },
];

interface LEASE_TYPE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: LeaseType;
}

export const LEASE_TYPE_OPTIONS: LEASE_TYPE_OPTION[] = [
  { key: 1, value: LeaseType.New, text: 'New lease' },
  { key: 2, value: LeaseType.Ancillary, text: 'Ancillary lease' },
  { key: 3, value: LeaseType.Renewal, text: 'Renewal lease' },
];

export const WHOLE_OR_PART_BOOLEAN_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, text: 'Whole', value: true },
  { key: 2, text: 'Part', value: false },
];

export const YES_OR_NO_BOOLEAN_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, text: 'Yes', value: true },
  { key: 2, text: 'No', value: false },
];

export const WHOLE_OR_PART_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: WholeOrPartOptions.WholeOnly, text: 'Whole only' },
  { key: 2, value: WholeOrPartOptions.PartOnly, text: 'Part only' },
  { key: 3, value: WholeOrPartOptions.WholeOrPart, text: 'Whole or part' },
];

export const RIGHT_RESTRICTION_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: RightRestriction.AssociatedCompanies, text: 'Associated companies' },
  { key: 2, value: RightRestriction.ThirdParties, text: 'Third parties' },
];

export const SHARE_RIGHT_RESTRICTION_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: ShareRightRestriction.AssociatedCompanies, text: 'Associated companies' },
  { key: 2, value: ShareRightRestriction.TenantVendors, text: 'Tenant vendors' },
];

export const LANDLORD_APPROVAL_TYPE_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: LandlordApprovalType.Absolute, text: 'Absolute' },
  { key: 2, value: LandlordApprovalType.Reasonable, text: 'Reasonable' },
];

export const REFUSAL_RIGHT_RENT_ASSESSMENT_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: RefusalRightRentAssessmentType.OfferedRent, text: 'Offered rent' },
  { key: 2, value: RefusalRightRentAssessmentType.RentAsPerLease, text: 'Rent as per lease' },
  { key: 3, value: RefusalRightRentAssessmentType.OpenMarket, text: 'Open market rent' },
];

export const EXPANSION_RIGHT_RENT_ASSESSMENT_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: ExpansionRightRentAssessmentType.FixedRent, text: 'Fixed rent' },
  { key: 2, value: ExpansionRightRentAssessmentType.RentAsPerLease, text: 'Rent as per lease' },
  { key: 3, value: ExpansionRightRentAssessmentType.OpenMarket, text: 'Open market rent' },
];

export const REFUSAL_RIGHT_PRIORITY_OPTIONS: DROPDOWN_OPTION[] = [
  { key: 1, value: RefusalRightPriority.ExistingTenant, text: 'Exisiting tenant' },
  { key: 2, value: RefusalRightPriority.ThirdParty, text: 'other third parties' },
  { key: 3, value: RefusalRightPriority.NoOne, text: 'No one (this right has first priority)' },
];

interface MANAGEMENT_CHARGE_MONTHLY_AC_FEES_PAYABLE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: ManagementChargeMonthlyAcFeesPayable;
}

export const MANAGEMENT_CHARGE_MONTHLY_AC_FEES_PAYABLE_OPTIONS: MANAGEMENT_CHARGE_MONTHLY_AC_FEES_PAYABLE_OPTION[] = [
  {
    key: 1,
    value: ManagementChargeMonthlyAcFeesPayable.IncludedInManagementCharges,
    text: 'Included in management charges',
  },
  {
    key: 2,
    value: ManagementChargeMonthlyAcFeesPayable.NotIncludedAdditionalAcFeesPaidByTenant,
    text: 'Not included and tenant pays additional monthly AC fees',
  },
  {
    key: 3,
    value: ManagementChargeMonthlyAcFeesPayable.NotApplicableAcNotProvided,
    text: 'Not applicable, AC not provided',
  },
];

interface OUT_OF_HOURS_AC_CHARGES_PAYABLE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: OutOfHoursAcChargesPayable;
}

export const OUT_OF_HOURS_AC_CHARGES_PAYABLE_OPTIONS: OUT_OF_HOURS_AC_CHARGES_PAYABLE_OPTION[] = [
  {
    key: 1,
    value: OutOfHoursAcChargesPayable.Yes,
    text: 'Yes, out-of-hours AC charges are payable',
  },
  {
    key: 2,
    value: OutOfHoursAcChargesPayable.NoAcIs247,
    text: 'No, AC is 24-7',
  },
  {
    key: 3,
    value: OutOfHoursAcChargesPayable.NoAcNotProvidedOutsideStandardHours,
    text: 'No, AC is not provided outside of standard hours',
  },
];

interface PERMISSION_ACTION_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: Action;
}

export const PERMISSION_ACTION_OPTIONS: PERMISSION_ACTION_OPTION[] = [
  { key: 1, value: Action.Write, text: 'Write' },
  { key: 2, value: Action.Read, text: 'Read' },
];

interface RESOURCE_OPTION extends Omit<DROPDOWN_OPTION, 'value'> {
  value: Resources;
}

// Dashboard = 'DASHBOARD',
// Transactions = 'TRANSACTIONS',
// Tenancy = 'TENANCY',
// Tenants = 'TENANTS',
// Policies = 'POLICIES',
// Properties = 'PROPERTIES',
// Portfolios = 'PORTFOLIOS',
// Organisations = 'ORGANISATIONS',
// Benchmarks = 'BENCHMARKS',
// Users = 'USERS',
// Parameters = 'PARAMETERS',
// Provisions = 'PROVISIONS',
// Formatters = 'FORMATTERS',
// Translators = 'TRANSLATORS'
export const RESOURCE_OPTIONS: RESOURCE_OPTION[] = [
  { key: 1, value: Resources.Dashboard, text: 'Dashboard' },
  { key: 2, value: Resources.Transactions, text: 'Transactions' },
  { key: 3, value: Resources.Tenancy, text: 'Tenancy' },
  { key: 4, value: Resources.Tenants, text: 'Tenants' },
  { key: 5, value: Resources.Policies, text: 'Policies' },
  { key: 6, value: Resources.Properties, text: 'Properties' },
  { key: 7, value: Resources.Portfolios, text: 'Portfolios' },
  { key: 8, value: Resources.Organisations, text: 'Organisations' },
  { key: 9, value: Resources.Benchmarks, text: 'Benchmarks' },
  { key: 10, value: Resources.Users, text: 'Users' },
  { key: 11, value: Resources.Parameters, text: 'Parameters' },
  { key: 12, value: Resources.Provisions, text: 'Provisions' },
  { key: 13, value: Resources.Formatters, text: 'Formatters' },
  { key: 14, value: Resources.Translators, text: 'Translators' },
];
