import { PayloadAction } from '@reduxjs/toolkit';
import { EditorState } from '..';

export interface EditorProvisionStoreState {
  activeProvisionId: string | null;
}

export const editorProvisionInitialState: EditorProvisionStoreState = {
  activeProvisionId: null,
};

interface ReducerAction {
  setActiveProvisionId: (state: EditorState, action: PayloadAction<string | null>) => void;
}

export const provisionReducers: ReducerAction = {
  setActiveProvisionId: (state, action) => {
    if (action.payload && state.provision.activeProvisionId !== action.payload) {
      state.provision.activeProvisionId = action.payload;
    }
  },
};
