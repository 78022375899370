import { Action, ThunkAction, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import refactoredEditorSliceReducer from 'components/RefactoredEditor/store';
import adminListPermissionsReducer from 'store/adminAuth/adminListPermissions';
import adminPermissionDetailsReducer from 'store/adminAuth/permissionDetails';

/*
 * The following imports correspond to all the reducers that originally
 * belonged to the GitHub repository
 */
import adminEntitiesReducer from 'store/adminEntities/adminEntitiesListingSlice';
import adminUsersReducer from 'store/adminUsers/adminUsersListingSlice';
import authReducer from 'store/authentication/authSlice';
import benchmarkDetailReducer from 'store/benchmark/benchmarkDetailSlice';
import benchmarksListingReducer from 'store/benchmark/benchmarksListingSlice';
import chatBotReducer from 'store/chatBot/chatBotSlice';
// import contractDetailReducer from 'store/contracts/contractDetailSlice';
// import contractsListingReducer from 'store/contracts/contractsListingSlice';
import contractDetailReducerV2 from 'store/contractsV2/contractDetailSliceV2';
import contractsListingReducerV2 from 'store/contractsV2/contractsListingSliceV2';
import dashboardReducer from 'store/dashboard/dashboardSlice';
import dashboardTabReducer from 'store/dashboard/dashboardTabSlice';
import forecasts2Reducer from 'store/dashboard/forecasts2Slice';
import forecastsReducer from 'store/dashboard/forecastsSlice';
import stackingPlanReducer from 'store/dashboard/stackingPlanSlice';
import editorReducer from 'store/editor/editorSlice';
import editorWrapperReducer from 'store/editor/editorWrapperSlice';
import entitiesListingReducer from 'store/entities/entitiesListingSlice';
import entityDetailReducer from 'store/entities/entityDetailSlice';
import formatterDetailReducer from 'store/formatters/formatterDetailSlice';
import formattersListingReducer from 'store/formatters/formattersListingSlice';
import hiddenMenuReducer from 'store/hiddenMenu/hiddenMenuSlice';
import membersReducer from 'store/members/membersSlice';
import miscellaneousReducer from 'store/miscellaneous/miscellaneousSlice';
import multiLevelMenuReducer from 'store/multiLevelMenu/multiLevelMenuSlice';
import nodesReducer from 'store/nodes/nodesSlice';
import notificationsReducer from 'store/notifications/notificationsSlice';
import organisationsListingReducer from 'store/organisations/organisationsListingSlice';
import parameterDetailReducer from 'store/parameters/parameterDetailSlice';
import parametersListingReducer from 'store/parameters/parametersListingSlice';
import parametersTabReducer from 'store/parametersTab/parametersTabSlice';
import policiesListingReducer from 'store/policies/policiesListingSlice';
import policyDetailReducer from 'store/policies/policyDetailSlice';
import portfolioDetailReducer from 'store/portfolio/portfolioDetailSlice';
import portfoliosListingReducer from 'store/portfolio/portfoliosListingSlice';
import propertiesListingReducer from 'store/properties/propertiesListingSlice';
import propertyDetailReducer from 'store/properties/propertyDetailSlice';
import propertiesV2Reducer from 'store/propertiesV2/propertiesV2Slice';
import provisionDetailReducer from 'store/provisions/provisionDetailSlice';
import provisionsListingReducer from 'store/provisions/provisionsListingSlice';
import rolesReducer from 'store/roles/rolesSlice';
import transactionDetailReducer from 'store/transactions/transactionDetailSlice';
import transactionsListingReducer from 'store/transactions/transactionsListingSlice';
import translatorDetailReducer from 'store/translatorsToContract/translatorDetailSlice';
import translatorsListingReducer from 'store/translatorsToContract/translatorsListingSlice';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer: {
    content: editorWrapperReducer,
    hiddenMenu: hiddenMenuReducer,
    editor: editorReducer,
    auth: authReducer,
    adminListPermissions: adminListPermissionsReducer,
    adminPermissionDetails: adminPermissionDetailsReducer,
    entityDetail: entityDetailReducer,
    entitiesListing: entitiesListingReducer,
    propertyDetail: propertyDetailReducer,
    propertiesListing: propertiesListingReducer,
    forecasts: forecastsReducer,
    // contractDetail: contractDetailReducer,
    // contractsListing: contractsListingReducer,
    contractDetailV2: contractDetailReducerV2,
    contractsListingV2: contractsListingReducerV2,
    members: membersReducer,
    roles: rolesReducer,
    adminUsers: adminUsersReducer,
    parametersListing: parametersListingReducer,
    parameterDetail: parameterDetailReducer,
    provisionDetail: provisionDetailReducer,
    provisionsListing: provisionsListingReducer,
    adminEntities: adminEntitiesReducer,
    formatterDetail: formatterDetailReducer,
    formattersListing: formattersListingReducer,
    organisationsListing: organisationsListingReducer,
    miscellaneous: miscellaneousReducer,
    multiLevelMenu: multiLevelMenuReducer,
    nodes: nodesReducer,
    policiesListing: policiesListingReducer,
    policyDetail: policyDetailReducer,
    parametersTab: parametersTabReducer,
    transactionDetail: transactionDetailReducer,
    transactionsListing: transactionsListingReducer,
    stackingPlan: stackingPlanReducer,
    translatorDetail: translatorDetailReducer,
    translatorsListing: translatorsListingReducer,
    dashboard: dashboardReducer,
    dashboardTab: dashboardTabReducer,
    notifications: notificationsReducer,
    portfolioDetail: portfolioDetailReducer,
    portfoliosListing: portfoliosListingReducer,
    benchmarkDetail: benchmarkDetailReducer,
    benchmarksListing: benchmarksListingReducer,
    chatBot: chatBotReducer,
    forecasts2: forecasts2Reducer,
    propertiesV2: propertiesV2Reducer,
    refactoredEditor: refactoredEditorSliceReducer,
  },
  middleware: customizedMiddleware,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
