import MEntity from 'common/model/Entity';
import MPolicy from 'common/model/Policy';
import MPolicyProvision from 'common/model/PolicyProvision';
import MUseType from 'common/model/UseType';
import { ProvisionPickName } from 'common/api/policies';

export interface PolicyListingProvisionClone
  extends Omit<Pick<MPolicyProvision, 'provision' | 'mandatory' | 'selectedByDefault'>, 'provision'> {
  provision: ProvisionPickName;
}

interface IPolicyListingClone
  extends Omit<Omit<MPolicy, 'description' | 'answers' | 'sequences' | 'contents'>, 'entity' | 'provisions'> {
  entity: Pick<MEntity, 'name'>;
  provisions: PolicyListingProvisionClone[];
}

export class PolicyListingClone implements IPolicyListingClone {
  id: string;
  name: string;
  language: string;
  jurisdiction: string;
  useType: MUseType;
  entity: Pick<MEntity, 'name'>;
  documentTypeIds: string[];
  provisions: PolicyListingProvisionClone[];
  regulatories: string[];
  insertedAt: Date;
  updatedAt: Date;

  constructor(input: IPolicyListingClone) {
    this.id = input.id;
    this.name = input.name;
    this.language = input.language;
    this.jurisdiction = input.jurisdiction;
    this.useType = input.useType;
    this.entity = input.entity;
    this.documentTypeIds = input.documentTypeIds;
    this.provisions = input.provisions;
    this.regulatories = input.regulatories;
    this.insertedAt = input.insertedAt;
    this.updatedAt = input.updatedAt;
  }

  /*
    function generateFragment(instance: any): string {
      const keys = Object.keys(instance);
      let fragmentString = '';
      for (const key of keys) {
        if (typeof instance[key] === 'object' && instance[key] !== null) {
          fragmentString += `\n${key} {\n${generateFragment(instance[key])}\n}`;
        } else {
          fragmentString += `\n${key}`;
        }
      }
      return fragmentString;
    }
  */
  // TODO : create a function that automatically generate the Fragment based on the class definition, see above.
  static fragments(): string {
    return `
      id
      name
      language
      jurisdiction
      useType
      entity {
        name
      }
      documentTypeIds
      provisions {
        provision {
          id
          name
        }
        mandatory
        selectedByDefault
      }
      regulatories
      insertedAt
      updatedAt
      `;
  }
}
