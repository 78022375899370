import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { createNode, deleteNode } from 'components/RefactoredEditor/api/node';
import { NodeInputProps } from 'common/api/nodes';
import { EditorState } from '..';

export interface EditorNodeStoreState {
  // showCondition: boolean;
  // isLoading: boolean;
  // activeNode?: Node | null;
  node?: NodeInputProps;
  // eventHandler: EventHandlerNode;
  // nodeConditions: any;
  // conditionSaveStatus: boolean;
  // emptyString: string;
  activeClauseIndex: string;
  // nodeError: boolean;
  // provisionData: ProvisionWithType | null;
  // iteration: boolean;
  showContext: boolean;
  newClauseId: string | null;
}

export const editorNodeInitialState: EditorNodeStoreState = {
  showContext: false,
  newClauseId: null,
  activeClauseIndex: '',
};

interface ReducerAction {
  setClauseIndex: (state: EditorState, action: PayloadAction<string>) => void;
  updateShowContext: (state: EditorState, action: PayloadAction<boolean>) => void;
  updateNewClauseId: (state: EditorState, action: PayloadAction<{ nodeId: string | null }>) => void;
}

export const nodeReducers: ReducerAction = {
  updateShowContext: (state, action) => {
    state.node.showContext = action.payload;
  },
  updateNewClauseId: (state, action) => {
    state.node.newClauseId = action.payload.nodeId;
  },
  setClauseIndex: (state, action) => {
    state.node.newClauseId = action.payload;
  },
};

export const nodeExtraReducers = (builder: ActionReducerMapBuilder<EditorState>) => {
  // CREATE NODE
  builder.addCase(createNode.rejected, (state, action) => {
    const nodeType = action.meta.arg.type.toLowerCase();
    toast.error(`Some error occurred when try to create a ${nodeType}`);
  });

  // DELETE NODE
  builder.addCase(deleteNode.fulfilled, state => {
    toast.success('The node was deleted successfully');
  });
  builder.addCase(deleteNode.rejected, (state, action) => {
    console.error(action.error);
  });
};
