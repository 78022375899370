import { Dispatch } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { BreadCrumbItem } from 'layouts/BreadCrumb';
import { PageHeaderProps } from 'layouts/PageHeader';
import PageLayout from 'layouts/PageLayout';
import ProvisionInfoModal from 'components/ProvisionInfoModal';
import { setSelectedProvisionFilter } from 'store/miscellaneous/miscellaneousSlice';
import { updatePolicyViewDetailsTab } from 'store/policies/policyDetailSlice';
import { updateProvisionModalState } from 'store/provisions/provisionsListingSlice';
import { listDocumentTypes } from 'common/api/miscellaneous';
import { ViewProps } from 'common/api/miscellaneous';
import { getPolicy } from 'common/api/policies';
import { listProvisionsAndCategories } from 'common/api/provisions';
import PolicyTabs, { POLICY_TABS_OFFSET } from './Tabs/PolicyTabs';

// Gets the policy details, provision categories and docs data
// Resets the states of search, modal, tablIndex and Filter
export const runPolicyDetailsPageActions = (
  dispatch: Dispatch<any>,
  tabIndex: POLICY_TABS_OFFSET,
  policyId?: string,
  isCreated?: boolean,
) => {
  dispatch(updateProvisionModalState({ open: false }));

  if (isCreated) {
    // @ts-ignore
    dispatch(getPolicy({ id: policyId })).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        const policy = response.payload.data.getPolicy;
        dispatch(
          listProvisionsAndCategories({
            first: 300,
            language: policy.language,
            jurisdiction: policy.jurisdiction,
            entityId: policy?.entity?.id,
            useType: policy.useType || undefined,
          }),
        );
      }
    });

    dispatch(setSelectedProvisionFilter({ provisionId: null }));
    dispatch(updatePolicyViewDetailsTab({ tab: tabIndex }));
  }

  dispatch(listDocumentTypes({ first: 100 }));
};

const PolicyCreatorViewer = ({ isCreated }: ViewProps) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { activeModalProvision } = useAppSelector((state: RootState) => state.provisionsListing);
  const {
    activePolicy,
    isLoading: policyLoading,
    activeTabIndex,
  } = useAppSelector((state: RootState) => state.policyDetail);
  const { isLoading: miscellaneousLoading } = useAppSelector((state: RootState) => state.miscellaneous);

  useEffect(() => {
    runPolicyDetailsPageActions(dispatch, POLICY_TABS_OFFSET.GENERAL, id, isCreated);
  }, [id]);

  const isPreviewTab: boolean = activeTabIndex === POLICY_TABS_OFFSET.PREVIEW;

  const getPageHeaderProps = (): PageHeaderProps => {
    const breadCrumb: BreadCrumbItem[] = [
      {
        title: 'Policies',
        url: '/policies/dashboard',
      },
      {
        title: activePolicy.name,
      },
    ];
    return {
      showBreadCrumb: true,
      breadCrumb,
    };
  };

  return (
    <PageLayout
      pageSpinner={policyLoading || miscellaneousLoading}
      flexHeight={isPreviewTab}
      pageHeader={getPageHeaderProps()}
    >
      <PolicyTabs />
      {activeModalProvision !== null && <ProvisionInfoModal />}
    </PageLayout>
  );
};

export default PolicyCreatorViewer;
