import { GuidelineClone } from 'common/_classes';
import { ReactNode } from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { Popup } from 'semantic-ui-react';
import { checkNotEmpty } from 'utils/tsValidator';
import GuidanceIcon from 'assets/images/svg/guidance.svg';
import ValuationIcon from 'assets/images/svg/valuation.svg';
import './GuideLineBoxes.scss';

interface GuidancePopup {
  content: ReactNode;
  title: string;
  icon: ReactNode;
  onlyIcon?: boolean;
}

const GuidancePopup = ({ content, title, icon, onlyIcon }: GuidancePopup) => (
  <Popup
    className="guidance-popup"
    content={content}
    trigger={
      <div className={!onlyIcon ? 'guidance-popup-trigger' : ''}>
        <Image
          src={icon}
          className="m-l-s m-r-s"
        />
        {!onlyIcon ? title : <></>}
      </div>
    }
    on="click"
    basic={true}
    position="bottom right"
  />
);

interface GuideLineBoxesProps {
  posRight: boolean;
  guideline: GuidelineClone | null | undefined;
  className?: string;
  onlyIcon?: boolean;
}

const GuideLineBoxes = ({ posRight, guideline, className = '', onlyIcon }: GuideLineBoxesProps) => {
  let generalGuidance, valuationGuidance;
  if (guideline) {
    generalGuidance = guideline.generalGuidance;
    valuationGuidance = guideline.valuationGuidance;
  }

  const guidanceExists = checkNotEmpty(generalGuidance);
  const valuationExists = checkNotEmpty(valuationGuidance);

  const displayGuidance = (guidance: string) => (
    <div
      className="editor-to-html guidance-popup-html m-b-none"
      dangerouslySetInnerHTML={{
        __html: guidance,
      }}
    ></div>
  );

  if (!onlyIcon) {
    return (
      <Grid.Column
        className={`custom-accordion-drop-down-guidance ${!posRight ? 'preview-guidance' : ''} ${className}`}
        width={posRight ? 4 : 16}
      >
        {guidanceExists && (
          <>
            <p className="guidelines-header">
              <b>Guidelines:</b>
            </p>
            <GuidancePopup
              title="General"
              icon={GuidanceIcon}
              content={displayGuidance(generalGuidance as string)}
            />
          </>
        )}

        {valuationExists && (
          <GuidancePopup
            icon={ValuationIcon}
            title="Valuation"
            content={displayGuidance(valuationGuidance as string)}
          />
        )}
      </Grid.Column>
    );
  } else {
    return (
      <div
        className="guideline-without-title"
        onClick={e => e.stopPropagation()}
      >
        {guidanceExists && (
          <GuidancePopup
            title="General"
            icon={GuidanceIcon}
            content={displayGuidance(generalGuidance as string)}
            onlyIcon={onlyIcon}
          />
        )}

        {valuationExists && (
          <GuidancePopup
            icon={ValuationIcon}
            title="Valuation"
            content={displayGuidance(valuationGuidance as string)}
            onlyIcon={onlyIcon}
          />
        )}
      </div>
    );
  }
};

export default GuideLineBoxes;
