import { Grid } from 'semantic-ui-react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import InputField, { InputFieldType } from 'atoms/FormField/Input';
import SelectField from 'atoms/FormField/Select';
import { updateActiveParameter, updateActiveTableParameter } from 'store/parameters/parameterDetailSlice';
import { MODE_OPTIONS, PARAMETER_TABLE_IMPLEMENTATION } from 'common/api/miscellaneous';

interface GroupManagementFormProps {
  mode: MODE_OPTIONS;
  type: PARAMETER_TABLE_IMPLEMENTATION;
}

const GroupManagementForm = ({ mode, type }: GroupManagementFormProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const { activeParameter, activeTableParameter } = useAppSelector((state: RootState) => state.parameterDetail);
  const { parameterGroupsOptions, parameterSubGroupsOptions } = useAppSelector(
    (state: RootState) => state.parametersListing,
  );

  const { parameterGroup, index, parameterSubGroup } =
    type === PARAMETER_TABLE_IMPLEMENTATION.PARAMETER ? activeParameter : activeTableParameter;

  const onChange = (key: string, value: string) => {
    const update =
      type === PARAMETER_TABLE_IMPLEMENTATION.PARAMETER ? updateActiveParameter : updateActiveTableParameter;
    dispatch(update({ key, value }));
  };

  const groupId: string | null = parameterGroup ? parameterGroup.id : null;
  const subGroupId: string | null = parameterSubGroup ? parameterSubGroup.id : null;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          width={11}
          type="content"
        >
          This section is used to define in which group this {type} will be displayed for users to answer, in policy or
          in transaction.
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={11}>
          <SelectField
            label="Group"
            value={groupId}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select group..."
            options={parameterGroupsOptions}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="parameterGroup"
            dataTest="select-group"
            search={true}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={11}>
          <InputField
            label="Order in the group"
            type={InputFieldType.NUMBER}
            value={index?.toString() as string}
            disabled={mode === MODE_OPTIONS.READ}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="index"
            isDecimal={true}
            delimiter="COMMA"
            dataTest="group-order"
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={11}>
          <SelectField
            label="Sub Group"
            value={subGroupId}
            disabled={mode === MODE_OPTIONS.READ}
            placeholder="Select sub group..."
            options={parameterSubGroupsOptions}
            required={mode === MODE_OPTIONS.EDIT}
            onChange={onChange}
            fieldKey="parameterSubGroup"
            dataTest="select-sub-group"
            search={true}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default GroupManagementForm;
