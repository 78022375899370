import { Parameter } from 'common/missingModels';
import { useState } from 'react';
import { Form, Grid, Input } from 'semantic-ui-react';
import { useAppDispatch } from 'hooks';
import { resetSelectedGroup } from 'store/parameters/parametersListingSlice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import ParameterSelectorModal from './ParameterSelectorModal';

const ParameterField = ({
  parameter,
  onChangeParam,
  mode,
}: {
  parameter: Parameter;
  onChangeParam: (parameterKey: string, id: string) => void;
  mode: string;
}) => {
  const [paramKey, setParamKey] = useState(parameter.key);
  const [modalStatus, setModalStatus] = useState(false);
  const dispatch = useAppDispatch();

  const disabled = mode === MODE_OPTIONS.READ;

  const setValue = (id: string) => {
    onChangeParam(paramKey, id);
    setModalStatus(false);
    dispatch(resetSelectedGroup());
  };
  const parameterOnClick = () => {
    if (disabled) return;
    setParamKey(parameter.key);
    setModalStatus(true);
  };
  return (
    <>
      <div className="col-9">
        <Form.Field>
          <label>
            {parameter.name}
            <span className="required-indicator">*</span>
          </label>

          <div
            className="pos-rel"
            onClick={parameterOnClick}
          >
            <Input
              action={{ icon: 'search', disabled }}
              placeholder={parameter.name}
              value={parameter.value}
              disabled={disabled}
            />
          </div>
        </Form.Field>
      </div>
      <ParameterSelectorModal
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        setValue={setValue}
        parameterKey={paramKey}
      />
    </>
  );
};

export default ParameterField;
