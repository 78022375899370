import { Dispatch } from '@reduxjs/toolkit';
import { Editor } from '@tinymce/tinymce-react';
import { ProvisionContentCloneExtend } from 'common/_classes';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { RootState } from 'store';
import { Editor as CoreEditor } from 'tinymce';
import { v4, validate as validUUID } from 'uuid';
import { useAppDispatch, useAppSelector } from 'hooks';
import DropdownMenu, { DropdownItem } from 'atoms/DropdownMenu';
import CrossReferenceModal from 'components/ContextToolbar/CrossReferenceModal';
import { updatedConditionActiveNode } from 'components/Editor/Components';
import { getClauseIndexFrom } from 'components/Editor/editorGetClauseIndexFrom';
import { EditorEvents, editorPlugins, setup } from 'store/editor/setup';
import { receiveEditorTab, updateSidebarTab } from 'store/hiddenMenu/hiddenMenuSlice';
import {
  resetActiveNode,
  setClauseIndex,
  setConditionsInput,
  setIteration,
  updateForm,
  updateHandleEvent,
} from 'store/nodes/nodesSlice';
import {
  updateActiveDocTypeId,
  updateActiveDocumentContent,
  updateEditorDocValue,
  updateEditorInstance,
  updateLoader,
} from 'store/provisions/provisionDetailSlice';
import Node from 'common/model/Node';
import NodeType from 'common/model/NodeType';
import { ConditionsInputProps, ContentNodeType, createNode, getNode, updateNode } from 'common/api/nodes';
import { cleanupUnusedNodes, updateProvisionContent } from 'common/api/provisions';
import { sortContents } from 'utils/tsHelper';
import { EventHandlerNode } from 'utils/types/nodes';
import { Icons } from 'utils/utils-icons';
import { parseContent } from 'utils/utils-string';
import { preventBackspace } from '../Clause/BackspaceClause';
import { EDITOR_INFORMATION_TABS_OFFSET } from '../EditorSideMenu';
import './EditorStyles.scss';
import './PageEditor.scss';

export const removeActiveClassFromTextNode = (editor: CoreEditor) => {
  // Remove active node class from all the text nodes
  const textNodes = editor.dom.select(`[data-node-type="text"]`);
  Array.from(textNodes).map(node => {
    if (node.classList.value.includes('active-text-node')) {
      node.classList.remove('active-text-node');
    }
  });
  return editor;
};

const setConditionsInputFromGet = (node: Node, conditionsInput: ConditionsInputProps[]) => {
  conditionsInput = JSON.parse(JSON.stringify(node.conditions));

  // Need to convert to stringify in order to solve the below issue
  // Expected type \"Json\", found {answer: \"213\"}
  for (let i = 0; i < conditionsInput.length; i++) {
    for (let j = 0; j < conditionsInput[i].list.length; j++) {
      conditionsInput[i].list[j].answer = JSON.stringify(conditionsInput[i].list[j].answer);
    }
  }
  return conditionsInput;
};

/*
 * createNewNode is used to support cross-provision content copy/paste (from withing the
 * same provision or from another provision). In such case, we need to duplicate the node
 * so that it corresponds to this provision and is no longer linked with another node.
 *
 * The steps are:
 *  -> Create a new promise
 *  -> get Data from the source node
 *  -> create a new 'id',
 *  -> set the provisionId with id of the provision we are in,
 *  -> copy all other sets of data like {type of node, list of parameters, conditions, formatter}
 *     from the source of copy node,
 *  -> update the content node with thenew 'id',
 *  -> save the new node.
 */
export const createNewNode = (
  node: Element,
  type: NodeType,
  level: number | null,
  index: number | null,
  conditionNodes: HTMLElement[] | NodeListOf<Element> | [],
  dispatch: Dispatch<any>,
  editor: CoreEditor | null,
  provisionId: string,
  duplicate: boolean,
): Promise<any> => {
  // TODO should be Element and not any.
  return new Promise((resolve, reject) => {
    const id = node.getAttribute('data-node-id') as string;
    return dispatch(getNode(id)).then((response: any) => {
      if (response.meta.requestStatus === 'fulfilled') {
        const nodeData: Node = response.payload.getNode;
        let formatterId = null;
        let paramRefsInput = [];
        let conditionsInput: ConditionsInputProps[] = [];
        let provisionIdInput = null;
        if (nodeData !== null) {
          conditionsInput = setConditionsInputFromGet(nodeData, conditionsInput);
          paramRefsInput = JSON.parse(JSON.stringify(nodeData.paramRefs));
          formatterId = nodeData.formatter?.id;
          provisionIdInput = nodeData.provision.id;
        } else {
          if (type === NodeType.Clause) {
            // If clause was not present in the db remove it from the DOM
            for (let i = 0; i < conditionNodes.length; i++) {
              const parentClause = conditionNodes[i].parentElement?.parentElement;
              const clauseId = parentClause?.getAttribute('data-node-id');
              if (clauseId === id) {
                if (duplicate) {
                  conditionNodes[i].remove();
                } else {
                  editor?.dom.remove(conditionNodes[i]);
                }
              }
            }
          }
        }
        // Check if the node is copied node
        // Either from a different provision or inside the same provision
        if (provisionId !== provisionIdInput || duplicate) {
          const nodeId = v4();
          // If current name is of old id set the new id as name
          const nodeName: string =
            nodeData === null
              ? nodeId
              : validUUID(nodeData.name)
                ? nodeId
                : index !== null
                  ? `${nodeData.name}-copy-${index}`
                  : nodeData.name;

          const bodyRequest = {
            type,
            level: level as number,
            provisionId,
            name: nodeName,
            id: nodeId,
            paramRefs: paramRefsInput,
            formatterId,
            conditions: conditionsInput,
          };
          dispatch(createNode(bodyRequest)).then((response: any) => {
            if (response.meta.requestStatus === 'fulfilled') {
              const { id } = response.payload.createNode;
              // Set new node id
              node.setAttribute('data-node-id', id);
              resolve(node);
            }
          });
        } else {
          resolve('Same id');
        }
      } else {
        resolve('rejected');
      }
    });
  });
};

export const useDebounce = (func: any, delay: number) => {
  const timeoutRef = useRef<any>(null);

  function debouncedFunction(...args: any) {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      func(args);
    }, delay);
  }

  return debouncedFunction;
};

const PageEditor = ({ setActiveTabDocTypeId }: { setActiveTabDocTypeId?: (id: string | undefined) => void }) => {
  const dispatch = useAppDispatch();
  const { node, eventHandler, iteration } = useAppSelector((state: RootState) => state.nodes);
  const {
    activeProvision: { id: provisionId, contents },
  } = useAppSelector((state: RootState) => state.provisionDetail);
  const { silentLoading } = useAppSelector((state: RootState) => state.provisionsListing);
  const { documentTypesList } = useAppSelector((state: RootState) => state.miscellaneous);

  const sortedContent: ProvisionContentCloneExtend[] = sortContents(contents, documentTypesList);
  const editorRef = useRef<CoreEditor | null>(null);

  const setTabId = (id: string | undefined) => {
    setActiveDocTypeId(id);

    if (setActiveTabDocTypeId) {
      setActiveTabDocTypeId(id);
    }
  };

  const [activeDocTypeId, setActiveDocTypeId] = useState<string | undefined>(undefined);
  const [hasEditorLoaded, setHasEditorLoaded] = useState<boolean>(false);
  const [hasModified, setHasModified] = useState<boolean>(false);

  const editor = editorRef.current;

  const initCallback = () => {
    setHasEditorLoaded(true);
  };

  useEffect(() => {
    setTabId(sortedContent[0]?.documentTypeId);
  }, []);

  useEffect(() => {
    const provisionBtnBar = document.getElementById('provision-editor-btn-bar');
    const parentMatch = document.getElementsByClassName('editor-container');
    const tabs = document.getElementsByClassName('ui segment active tab');
    let outermostTab: Element | null = null;
    let parent: Element | null = null;

    if (tabs.length) {
      outermostTab = tabs[0];
    }

    if (parentMatch.length && provisionBtnBar) {
      parent = parentMatch[0];

      if (outermostTab) {
        parent.removeChild(provisionBtnBar);

        // Place the button as the child of the outermost tab.
        // This is so it can be positioned absolutely (top right of page under navbar) properly
        // like other pages
        outermostTab.appendChild(provisionBtnBar);
      }
    }

    return () => {
      if (outermostTab) {
        if (provisionBtnBar) {
          // remove the repositioned button so it doesn't appear when you go other tabs
          outermostTab.removeChild(provisionBtnBar);

          if (parent) {
            // re-attach the repositioned buttons back to it's initial container (parent)
            parent.appendChild(provisionBtnBar);
          }
        }
      }
    };
  }, []);

  useEffect(() => {
    const doc = sortedContent.at(0);
    if (!doc) return;
    dispatch(receiveEditorTab(doc.documentTypeId));
  }, []);

  useEffect(() => {
    if (eventHandler === EventHandlerNode.EDIT_PARAMETER) {
      updateActiveNodeForm();
    }
    if (eventHandler === EventHandlerNode.UPDATE_CONDITION) {
      updateCondition();
    }
  }, [eventHandler, node]);

  useEffect(() => {
    if (editor !== null) {
      removeActiveClassFromTextNode(editor);
    }
  }, [editor]);

  useEffect(() => {
    setTimeout(() => {
      const latestEditor = editorRef.current;
      if (latestEditor !== null) {
        if (latestEditor.getContent() !== '') {
          const editorUpdated = removeActiveClassFromTextNode(latestEditor);
          dispatch(updateSidebarTab(EDITOR_INFORMATION_TABS_OFFSET.NONE));
          dispatch(resetActiveNode());
          dispatch(
            updateActiveDocumentContent({
              editor: editorUpdated,
              documentTypeId: editorUpdated.id,
            }),
          );
        }
      }
    }, 100);
  }, [activeDocTypeId]);

  const updateNodeName = () => {
    const nodeId = node?.id;
    if (nodeId && editor) {
      const nodeContent = editor.dom
        .select(`[data-node-id='${nodeId}']`)[0]
        ?.innerHTML.replace(/<\/?[^>]+(>|$)/g, '')
        .replace(/&nbsp;/g, ' '); // Replace &nbsp; with a space;

      if (node.type !== NodeType.Clause && node.name !== nodeContent) {
        dispatch(updateForm({ key: 'name', value: nodeContent }));
        dispatch(setConditionsInput());
        dispatch(updateNode());
      }
    }
  };

  const onEditorChange = (content: string, editor: CoreEditor) => {
    setHasModified(true);
    // Prevent some backspacing behaviors
    preventBackspace(editor);

    // Update the active provision with the content of editor
    updateActiveProvision();

    // This method to save after any edit (delay: 3.5 seconds)
    autoSaveEditorSilent('editorChange');

    // Set as unsaved
    editor.setDirty(true);
  };

  /**
   * Saves and do not lock the screen. Recommended trigger updateActiveProvision() before
   */
  const autoSaveEditorSilent = useDebounce((type: string) => {
    const editor = editorRef.current;

    if (!hasEditorLoaded || !editor) return;
    const documentTypeId = editor.getParam('id');

    dispatch(updateProvisionContent({ documentTypeId }));

    if (type && type[0] === 'editorChange') {
      updateNodeName();
    }

    editor.setDirty(false);
  }, 3500);

  /**
   * Save the parameter content
   */
  const autoSaveNodeParameter = useDebounce(() => hasEditorLoaded && dispatch(updateNode()), 1000);

  const updateCondition = () => {
    const editor = editorRef.current;

    if (!editor || !node) return;

    updatedConditionActiveNode(editor, node, iteration);

    // Update content to Active Provision
    updateActiveProvision();

    // Silent - Saves the entire Provision
    autoSaveEditorSilent();

    // End of event
    dispatch(updateHandleEvent(EventHandlerNode.NONE));
    dispatch(setIteration(false));
  };

  /**
   * Callback for Node Parameter update
   */
  const updateActiveNodeForm = () => {
    const editor = editorRef.current;
    if (!editor || !node) return;

    // Update content to Active Provision
    updateActiveProvision();

    // Saves current Parameter Node
    autoSaveNodeParameter();

    // Silent - Saves the entire Provision
    autoSaveEditorSilent();

    // End of event
    dispatch(updateHandleEvent(EventHandlerNode.NONE));
  };

  /**
   * Update the active Provision with the content of editor
   */
  const updateActiveProvision = () => {
    const editor = editorRef.current;

    if (!editor) return;
    const documentTypeId = editor.getParam('id');
    if (!documentTypeId) {
      console.warn('Trying to save without an ID');
      return;
    }
    dispatch(updateActiveDocumentContent({ editor, documentTypeId }));
  };

  const handleChangeEditorTabs = (documentTypeId: string) => () => {
    setTabId(documentTypeId);
    dispatch(receiveEditorTab(documentTypeId));
  };

  /**
   * Catch every click on the editor and fire if the condition is satisfied
   */
  const handleEditorClickEvents = (editor: CoreEditor) => {
    editorRef.current = editor;

    editor.selection.editor.on(EditorEvents.Click, event => {
      let nodeId = null;
      let nodeType = null;

      let parent = event.target;

      for (let i = 0; i < 3 && parent; i++) {
        nodeId = nodeId ? nodeId : parent.getAttribute('data-node-id');
        nodeType = nodeType ? nodeType : parent.getAttribute('data-node-type');

        parent = parent.parentElement;
      }

      if (nodeType === ContentNodeType.PARAMETER) {
        dispatch(getNode(nodeId));
        dispatch(updateSidebarTab(EDITOR_INFORMATION_TABS_OFFSET.PARAMETER));
      }

      if (nodeType === ContentNodeType.TEXT) {
        dispatch(getNode(nodeId));

        // Remove active node class from all the text nodes
        removeActiveClassFromTextNode(editor);

        // Add active node class to text node
        const [textNode] = editor.dom.select(`[data-node-id="${nodeId}"]`);
        textNode.classList.add('active-text-node');
        dispatch(updateSidebarTab(EDITOR_INFORMATION_TABS_OFFSET.TEXT_BLOCK));
      }

      if (nodeType === ContentNodeType.CLAUSE_REFERENCE) {
        dispatch(getNode(nodeId));
      }

      if (nodeType?.startsWith(ContentNodeType.CLAUSE_INDEX) && nodeId) {
        dispatch(updateEditorInstance(editor));
        dispatch(updateActiveDocTypeId(activeDocTypeId));
        const clauseIndex = getClauseIndexFrom(editor, nodeId);
        dispatch(setClauseIndex(clauseIndex));
        dispatch(getNode(nodeId));
        dispatch(updateSidebarTab(EDITOR_INFORMATION_TABS_OFFSET.CLAUSE));
      } else {
        dispatch(updateEditorInstance(null));
        dispatch(updateActiveDocTypeId(null));
      }
    });
  };

  /**
   * This methods control the Silent Saving pop-up
   *
   * When user modifies the content, it is automatically saved.
   * To follow the "saving" status the floating label (on top of
   * of the editor) display the status : {"Not saved yet", "Saved!" }
   *
   */
  const handleMessageSaving = () => {
    const editor = editorRef.current;
    if (!editor || !hasModified) return null;

    if (editor.isDirty()) {
      return <span>Not saved yet</span>;
    } else {
      if (silentLoading) {
        return <span>Saving...</span>;
      } else {
        return <span className="message-fadeout">Saved!</span>;
      }
    }
  };

  const removeActiveNodeClass = () => {
    if (editor !== null) {
      removeActiveClassFromTextNode(editor);
      dispatch(resetActiveNode());
      dispatch(updateSidebarTab(EDITOR_INFORMATION_TABS_OFFSET.NONE));
    }
  };

  /*
   * fixNodeIntegrity is used to duplicate all the nodes of a provision content.
   * This is usefull in case of copy of cross-provision content copy/paste
   * with content that includes nodes.
   */
  const fixNodeIntegrity = (content: ProvisionContentCloneExtend, resolve: (value: string) => void): void => {
    // Convert string into a HTML object
    const contentParsed: Document = parseContent(content.content);

    // Get the node elements
    const clauseNodes: NodeListOf<Element> = contentParsed.querySelectorAll('[data-node-type="clause"]');
    const textNodes: NodeListOf<Element> = contentParsed.querySelectorAll('[data-node-type="text"]');
    const parameterNodes: NodeListOf<Element> = contentParsed.querySelectorAll('[data-node-type="parameter"]');

    const promiseList: Promise<any>[] = [];
    // Create New Clause Nodes
    if (clauseNodes) {
      const conditions: NodeListOf<Element> = contentParsed.querySelectorAll('[data-condition="true"]') || [];
      clauseNodes.forEach((clause: Element) =>
        promiseList.push(
          createNewNode(clause, NodeType.Clause, 0, null, conditions, dispatch, editor, String(provisionId), false),
        ),
      );
    }

    // Create New Text Nodes
    if (textNodes) {
      textNodes.forEach((textNode: Element) => {
        const level: number | null = textNode.getAttribute('data-node-level') as number | null;
        promiseList.push(
          createNewNode(textNode, NodeType.Text, Number(level), null, [], dispatch, editor, String(provisionId), false),
        );
      });
    }

    // Create New Parameter Nodes
    if (parameterNodes) {
      parameterNodes.forEach((parameterNode: Element) => {
        promiseList.push(
          createNewNode(parameterNode, NodeType.Parameter, 1, null, [], dispatch, editor, String(provisionId), false),
        );
      });
    }

    /* Once all the nodes have been created
     * Update the provision content with the new node id's */
    Promise.all(promiseList).then(newNodes => {
      // Go through the updated nodes
      for (let i = 0; i < newNodes.length; i++) {
        // Get the updated content of the editor from any of the node that got updated
        // Just need to get the content from any one node that was updated
        if (typeof newNodes[i] === 'object') {
          // Get the updated content with new id's from the node
          const contentToUpdate: string = newNodes[i].ownerDocument.body.innerHTML;

          // Sets the updated content inside the editor with the new node id's
          dispatch(
            updateEditorDocValue({
              documentTypeId: content.documentTypeId,
              content: contentToUpdate,
            }),
          );

          // Updates the database with the updated content
          dispatch(updateProvisionContent({ documentTypeId: content.documentTypeId }));
          break;
        }
      }

      resolve('Fixed integrity');
    });
  };

  // Fix node integrity for all the documents
  const fixNodeIntegrityForAll = (): void => {
    dispatch(updateLoader(true));

    //let docsIntegrity: Promise<any>[] = [];
    // Call fix node integrity on all the applicable documents
    const docsIntegrity: Promise<any>[] = sortedContent.map(
      (content: ProvisionContentCloneExtend) => new Promise(resolve => fixNodeIntegrity(content, resolve)),
    );

    // When all the documents integrity has been fixed
    Promise.all(docsIntegrity).then(() => {
      // Reset active nodes and tabs
      dispatch(resetActiveNode());
      dispatch(updateSidebarTab(EDITOR_INFORMATION_TABS_OFFSET.NONE));
      dispatch(updateHandleEvent(EventHandlerNode.NONE));
      dispatch(updateLoader(false));
      toast('Fixed the node integrity');
    });
  };

  const cleanupUnusedNodesDropdownItems: DropdownItem[] = [
    {
      key: '1',
      label: 'This Provision',
      onClick: () => dispatch(cleanupUnusedNodes({ provisionId: provisionId ?? null })),
    },
    {
      key: '2',
      label: 'All Provisions',
      onClick: () =>
        dispatch(
          cleanupUnusedNodes({
            provisionId: null,
          }),
        ),
    },
  ];

  return (
    <>
      {/* Note that this button has some javascript code to help with positioning. Check useEffect */}
      <div id="provision-editor-btn-bar">
        <DropdownMenu
          className="cleanup-dropdown ui button btn grey-outline m-l-s"
          dropdownText="Cleanup Unused Nodes"
          dropdownItems={cleanupUnusedNodesDropdownItems}
        />

        <Button
          className="btn grey-outline fix-node-integrity"
          onClick={fixNodeIntegrityForAll}
        >
          FIX NODE INTEGRITY
        </Button>
      </div>

      <div className="page-body-container">
        <div
          className="float-saving-status"
          onClick={() => removeActiveNodeClass()}
        >
          {handleMessageSaving()}
        </div>
        <div
          className="editor-tabs"
          data-test="editor-tabs"
          onClick={() => removeActiveNodeClass()}
        >
          {sortedContent.map(({ documentName, documentTypeId }) => {
            return (
              <div
                className={activeDocTypeId === documentTypeId ? 'is-active' : ''}
                onClick={handleChangeEditorTabs(documentTypeId)}
                key={documentTypeId}
              >
                {documentName}
              </div>
            );
          })}
        </div>
        <CrossReferenceModal editor={editor} />

        {sortedContent.map(sorted => {
          if (activeDocTypeId !== sorted.documentTypeId) return null;
          return (
            <div
              key={sorted.documentTypeId}
              className="editor-body"
            >
              {/*
               * We are using style={{visibility: "hidden"}} because not rendering the
               * provision would mean init event is never triggered, and so, nothing
               * would ever show. So we render the provision, but make it invisible.
               */}
              {!hasEditorLoaded && <h1>Loading editor...</h1>}

              <div className={hasEditorLoaded ? 'visible' : 'hidden'}>
                <Editor
                  id={sorted.documentTypeId}
                  tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                  onInit={(_e, editor) => {
                    editorRef.current = editor;
                    handleEditorClickEvents(editor);
                  }}
                  value={sorted.content}
                  onEditorChange={(content, editor) => {
                    onEditorChange(content, editor);
                  }}
                  init={{
                    id: sorted.documentTypeId,
                    height: '100%',
                    width: '100%',
                    menubar: false,
                    plugins: editorPlugins,
                    font_size_formats: '8pt 9pt 10pt 11pt 12pt 14pt 15pt 18pt 24pt 36pt',
                    font_family_formats:
                      'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Ebrima=ebrima; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Urbanist=urbanist; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
                    toolbar:
                      'fontfamily fontsize | bold italic underline forecolor backcolor | removeformat | alignleft aligncenter alignright |  bullist numlist | table | menuNodeButton',
                    content_css: '/tinymce-css/tinymce-style.css',
                    body_class: 'provisions-editor-body', // Custom class for the editor body
                    contextmenu_never_use_native: true,
                    entity_encoding: 'raw',
                    forced_root_block: 'p',
                    remove_trailing_brs: false,
                    nonbreaking_force_tab: true,
                    browser_spellcheck: true,
                    extended_valid_elements: 'span[data-iteration|*],svg[*]',
                    contextmenu: 'rightClickMenu', // Menu to be opened on right click
                    setup: editor => setup(editor, initCallback, false),
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PageEditor;
