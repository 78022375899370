import PolicyCreatorViewer from 'views/policy/CreatorViewer';
import PoliciesDashboard from 'views/policy/Dashboard';
import { checkIfAdminURL } from 'utils/utils-admin';
import { isAuthenticated } from './redirects';

const PolicyRoutes = () => {
  let children = [
    {
      path: '/policies/dashboard',
      element: isAuthenticated(<PoliciesDashboard />),
    },
  ];

  if (!checkIfAdminURL()) {
    children = [
      ...children,
      {
        path: '/policies/:id/details',
        element: isAuthenticated(<PolicyCreatorViewer isCreated={true} />),
      },
      {
        path: '/policies/create',
        element: isAuthenticated(<PolicyCreatorViewer />),
      },
    ];
  }

  return [
    {
      path: '/policies',
      children,
    },
  ];
};
export default PolicyRoutes;
