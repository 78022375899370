import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import _omit from 'lodash/omit';
import BreadCrumb, { BREAD_CRUMB_SIZE, BreadCrumbItem } from 'layouts/BreadCrumb';
import DetailsControlButtons from 'layouts/DetailsControlButtons';
import { setMode } from 'store/propertiesV2/propertiesV2Slice';
import { MODE_OPTIONS } from 'common/api/miscellaneous';
import { updateDevelopment } from 'common/api/propertiesV2';
import { createDevelopment } from 'common/api/propertiesV2/createDevelopment';

const DevelopmentCreateEditHeaderToolbar = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { currentDevelopment, mode } = useAppSelector((state: RootState) => state.propertiesV2);
  const breadcrumbs: BreadCrumbItem[] = [
    {
      title: 'Developments',
      url: '/properties-new',
    },
    {
      title: currentDevelopment?.developmentName || 'New Development',
    },
  ];

  const handleSetMode = (mode: MODE_OPTIONS) => {
    dispatch(setMode(mode));
  };
  const onSaveHandler = () => {
    if (!currentDevelopment) return;

    const developmentParams = _omit(currentDevelopment, ['id', 'insertedAt', 'updatedAt']);
    const isNewDevelopment = !currentDevelopment.id;

    if (isNewDevelopment) {
      dispatch(createDevelopment({ params: developmentParams }));
      return;
    }

    dispatch(
      updateDevelopment({
        id: currentDevelopment.id,
        params: developmentParams,
      }),
    );
  };

  return (
    <div className="d-flex justify-content-between w-100 py-2">
      <BreadCrumb
        sections={breadcrumbs}
        size={BREAD_CRUMB_SIZE.SMALL}
      />
      <DetailsControlButtons
        mode={mode}
        setMode={handleSetMode}
        onSave={onSaveHandler}
        checkDisabled={false}
      />
    </div>
  );
};

export default DevelopmentCreateEditHeaderToolbar;
