import { Form, Icon, Input } from 'semantic-ui-react';
import { InputFieldType } from 'atoms/FormField/Input';
import AnswerTypes from 'common/model/AnswerTypes';
import { AnswerJsonDuration, initAnswerValue } from 'common/api/formatters';
import { numberInputValidator } from 'utils/utils-clean-input';
import { addSpaceOrComma, removeSpaceOrComma } from 'utils/utils-number';
import QuestionNote from '../QuestionNote';
import './DurationContent.scss';

interface DurationContentProps {
  value: AnswerJsonDuration;
  onChange: (value: AnswerJsonDuration) => void;
  note: string | null;
}

const DurationContent = ({ value, onChange, note }: DurationContentProps): JSX.Element => {
  const initValue: AnswerJsonDuration = initAnswerValue(AnswerTypes.Duration);

  const onChangeHandler = (changedValue: string, key: string): void => {
    let durationCopy: AnswerJsonDuration;
    if (!changedValue) {
      durationCopy = initValue;
    } else {
      durationCopy = { ...value };
      // @ts-ignore
      durationCopy[key] = changedValue;
    }
    onChange(durationCopy);
  };

  const { years, months, days } = value;

  return (
    <Form data-test="duration-form">
      <div className="duration-content-wrapper">
        <div>
          <span className="duration-content-label color-blue-very-dark">Years</span>
          <Input
            type={InputFieldType.TEXT}
            placeholder="Years"
            data-test="years"
            onChange={e => onChangeHandler(removeSpaceOrComma(e.target.value), 'years')}
            value={addSpaceOrComma(years, false)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => numberInputValidator(e, false, false, years)}
          />
        </div>
        &nbsp;&nbsp;
        <div>
          <span className="duration-content-label color-blue-very-dark">Months</span>
          <Input
            type={InputFieldType.TEXT}
            placeholder="Months"
            data-test="months"
            onChange={e => onChangeHandler(removeSpaceOrComma(e.target.value), 'months')}
            value={addSpaceOrComma(months, false)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => numberInputValidator(e, false, false, months)}
          />
        </div>
        &nbsp;&nbsp;
        <div>
          <span className="duration-content-label color-blue-very-dark">Days</span>
          <Input
            type={InputFieldType.TEXT}
            placeholder="Days"
            data-test="days"
            onChange={e => onChangeHandler(removeSpaceOrComma(e.target.value), 'days')}
            value={addSpaceOrComma(days, false)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => numberInputValidator(e, false, false, days)}
          />
        </div>
      </div>
      <QuestionNote note={note} />
      <span
        onClick={e => onChangeHandler('', 'clear')}
        className="parameters-questions-clear-data"
        data-test="clear-data-button"
        role="button"
      >
        <Icon name="trash alternate outline" />
        Clear Data
      </span>
    </Form>
  );
};

export default DurationContent;
