import { useEffect } from 'react';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import UnderConstructionContent from 'atoms/UnderConstruction';
import { updateCurrentTab, updatePropertyFloorsTabView } from 'store/properties/propertyDetailSlice';
import { ViewProps } from 'common/api/miscellaneous';
import PropertyFloorsTab, { PROPERTY_FLOORS_TAB_VIEW } from './Floors';
import PropertyInformationTab from './PropertyInformation';

export enum PROPERTY_TABS_OFFSET {
  INFO = 0,
  FLOORS_AND_UNITS = 1,
  TRANSACTIONS = 2,
  CONTRACTS = 3,
  TENANTS = 4,
}

const getTabMenuConfig = (isCreated?: boolean): TabMenuProps[] => {
  const isCreateMode = isCreated ? false : true;

  return [
    {
      key: 'properties-tab-information',
      href: '?tab=information',
      label: 'Property Information',
      dataTest: 'property-information-tab',
      customTab: <PropertyInformationTab />,
    },
    {
      key: 'properties-tab-floors',
      isDisabled: isCreateMode,
      href: '?tab=floors',
      label: 'Floors & Units',
      dataTest: 'floors-and-units-tab',
      customTab: <PropertyFloorsTab isCreated={isCreated} />,
    },
    {
      key: 'properties-tab-transactions',
      href: '?tab=transactions',
      label: 'Transactions',
      dataTest: 'transactions-tab',
      customTab: <UnderConstructionContent showBackButton={false} />,
      isDisabled: isCreateMode,
    },
    {
      key: 'properties-tab-contracts',
      isDisabled: isCreateMode,
      href: '?tab=contracts',
      label: 'Contracts',
      dataTest: 'contracts-tab',
      customTab: <UnderConstructionContent showBackButton={false} />,
    },
    {
      key: 'properties-tab-tenants',
      isDisabled: isCreateMode,
      href: '?tab=tenants',
      label: 'Tenants',
      dataTest: 'tenants-tab',
      customTab: <UnderConstructionContent showBackButton={false} />,
    },
  ];
};

const PropertyTabs = ({ isCreated }: ViewProps) => {
  const dispatch = useAppDispatch();

  const { activeTabIndex } = useAppSelector((state: RootState) => state.propertyDetail);

  useEffect(() => {
    return () => {
      dispatch(updatePropertyFloorsTabView({ view: PROPERTY_FLOORS_TAB_VIEW.FLOORS_LISTING }));
      dispatch(updateCurrentTab({ tab: PROPERTY_TABS_OFFSET.INFO }));
    };
  }, []);

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      onTabChangeAction={updateCurrentTab}
      tabMenu={getTabMenuConfig(isCreated)}
    />
  );
};

export default PropertyTabs;
