import { useLocation } from 'react-router-dom';
import { RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { TabMenuProps, TabReact } from 'atoms/TabReact';
import CardParameters from 'components/CardParameters';
import PreviewTab from 'components/PreviewTab';
import ProvisionsTab from 'views/policy/CreatorViewer/Tabs/ProvisionsTab';
import { setSelectedProvisionFilter } from 'store/miscellaneous/miscellaneousSlice';
import { updateGroupName } from 'store/parametersTab/parametersTabSlice';
import { updatePolicyViewDetailsTab, updateShowParametersForm } from 'store/policies/policyDetailSlice';
import ExecuteContext from 'common/model/ExecuteContext';
import ApprovalsTab from './ApprovalsTab';
import GeneralTab from './GeneralTab';
import SequenceTab from './SequenceTab';

export enum POLICY_TABS_OFFSET {
  GENERAL = 0,
  PROVISIONS = 1,
  SEQUENCE = 2,
  PARAMETERS = 3,
  PREVIEW = 4,
  APPROVALS = 5,
}

const getTabMenuConfig = (isCreateMode: boolean, showParametersForm: boolean): TabMenuProps[] => {
  const dispatch = useAppDispatch();
  return [
    {
      key: 'policies-tab-general',
      href: '?tab=general',
      label: 'General',
      dataTest: 'general-tab',
      customTab: <GeneralTab />,
    },
    {
      key: 'policies-tab-provisions',
      href: '?tab=provisions',
      dataTest: 'provisions-tab',
      label: 'Provisions',
      customTab: <ProvisionsTab />,
      isDisabled: isCreateMode,
    },
    {
      key: 'policies-tab-sequence',
      href: '?tab=sequence',
      dataTest: 'sequence-tab',
      label: 'Sequence',
      customTab: <SequenceTab />,
      isDisabled: isCreateMode,
    },
    {
      key: 'policies-tab-parameters',
      isDisabled: isCreateMode,
      href: '?tab=parameters',
      dataTest: 'parameters-tab',
      label: 'Parameters',
      customTab: <CardParameters />,
      // Might be needed to be removed
      onClick: () => dispatch(updateShowParametersForm({ status: false })),
    },
    {
      key: 'policies-tab-preview',
      isDisabled: isCreateMode,
      href: '?tab=preview',
      dataTest: 'preview-tab',
      label: 'Preview',
      customTab: <PreviewTab context={ExecuteContext.Policy} />,
    },
    {
      key: 'policies-tab-approvals',
      isDisabled: isCreateMode,
      href: '?tab=approvals',
      dataTest: 'approvals-tab',
      label: 'Approvals',
      customTab: <ApprovalsTab />,
    },
  ];
};

const PolicyTabs = () => {
  const { pathname } = useLocation();
  const isCreateMode = pathname.includes('create');
  const { activeTabIndex, showParametersForm } = useAppSelector((state: RootState) => state.policyDetail);

  const dispatch = useAppDispatch();

  const onTabChange = (tab: string | number | undefined) => {
    dispatch(updatePolicyViewDetailsTab({ tab }));
    if (tab === POLICY_TABS_OFFSET.PREVIEW) {
      dispatch(updateGroupName({ groupId: null, groupName: '' }));
    }
    if (tab === POLICY_TABS_OFFSET.PARAMETERS) {
      dispatch(setSelectedProvisionFilter({ provisionId: null }));
    }
  };

  return (
    <TabReact
      activeTabIndex={activeTabIndex}
      customTabChangeAction={onTabChange}
      tabMenu={getTabMenuConfig(isCreateMode, showParametersForm)}
    />
  );
};

export default PolicyTabs;
